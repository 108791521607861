import ClientUserAdd from './components/ClientUserAdd';
import Header from './components/Header';
import PageHeader from './components/PageHeader';
export default function AddClientUser(){
    return(
        <>
         <Header/>
         <section className="disFlex flexWrap widthFull dashboardBlock centered">
            <div className="disFlex flexWrap pageHeader widthFull aic">
                <PageHeader/>
            </div>
            <div className="pageContainer scrollBarCustom location centered disBlock">
                <ClientUserAdd/>
            </div>
        </section>
        </>
    )
}