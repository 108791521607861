import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
export default function ClientAdd(){
    const tokenString = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
     const serchText = params.get("search");
    const [BlankFormMsg, setBlankFormMsg] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const[countryList, setCountryList]= useState([]);
    const[regionList, setRegionList]= useState([]);
    const[locationList, setLocationList]= useState([]);
    const[branchList, setBranchList]= useState([]);

    const [countryId, setCountryId] = useState('');
    const [clientCountry, setClientCountry] = useState('');
    const [regionId, seRegionId] = useState('');
    const [clientLocation, setClientLocation] = useState('');
    const [locationId, setLocationId] = useState('');
    const [branchId, setBranchId] = useState('');
    const [logo, setLogo] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [emailId, setEmailId] = useState('');
    const [address, setAddress] = useState('');
    const [pocName, setPocName] = useState('');
    const [pocContact, setPocContact] = useState('');
    const [clientInDate, setClientInDate] = useState('');
    

    
useEffect(() => {
    //Country List
    axios.post('country_list', {
        token: tokenString,
    }, { headers: {
           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => { 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                setCountryList(response.data.data);
            }else{ navigate('/'); } }  })
//Region List
axios.post('region_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setRegionList(response.data.data);
        }else{ navigate('/'); } }  })
//Location List
axios.post('location_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setLocationList(response.data.data);
        }else{ navigate('/'); } }  })

//Branch List
axios.post('branch_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setBranchList(response.data.data);
        }else{ navigate('/'); } }  })

}, []);

 // category Add
 const handleAdd =(e) =>{ 
    const formData = new FormData();
    formData.append("token", tokenString);
    formData.append("country_id", countryId);
    formData.append("region_id", regionId);
    formData.append("location_id", locationId);
    formData.append("client_name", name);
    formData.append("client_phone", phone);
    formData.append("client_email", emailId);
    formData.append("branch_id", branchId);
    formData.append("client_country", clientCountry);
    formData.append("client_location", clientLocation);
    formData.append("client_address", address);
    formData.append("client_in_date", clientInDate);
    formData.append("logo_img", logo);
    formData.append("point_of_contact", pocName);
    formData.append("point_of_contact_number", pocContact);
    axios.post('client_add', formData)
    .then(response => {
        setErrorMessage(response.message); 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                sessionStorage.setItem('SuccMsg', (response.data.message))
                navigate('/Client');
            }
            if(response.data.status==2){
                setErrorMessage(response.data.message);
            }
        }
    })
    .catch(error => {
        setErrorMessage(error.response.message); 
    });
};
    return(
        <>
         <div className="addLocationBlock text centered disBlock">
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Country </label>
                    <select onChange={(e)=>{setCountryId(e.target.value);}}>
                        <option> Select a Country  </option>
                        {countryList.map(data =>{ 
                            return(
                            <option value={data.id} key={data.id}> {data.country_name}  </option>
                            )
                        })}
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Region </label>
                    <select onChange={(e)=>{seRegionId(e.target.value);}}>
                        <option> Select a Region  </option>
                        {regionList.map(data =>{ 
                            if(countryId == data.region_country){
                                return(
                            <option value={data.id} key={data.id}> {data.region_name}  </option>
                                )
                            }
                        })}
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Location </label>
                    <select onChange={(e)=>{setLocationId(e.target.value);}}>
                        <option> Select a Location  </option>
                        {locationList.map(data =>{ 
                            if(regionId == data.location_region){
                                return(
                                <option value={data.id} key={data.id}> {data.location_name}  </option>
                                )
                            }
                        })}
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Branch </label>
                    <select onChange={(e)=>{setBranchId(e.target.value);}}>
                        <option> Select a Branch  </option>
                        {branchList.map(data =>{ 
                            if(locationId == data.branch_location){
                                return(
                                <option value={data.id} key={data.id}> {data.name}  </option>
                                )
                             }
                        })}
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Logo Image </label>
                    <input type="file" onChange={(e)=>{setLogo(e.target.files[0]);}} className="widthFull textForm" placeholder="Logo Image" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Client Name </label>
                    <input type="text" onChange={(e)=>{setName(e.target.value);}} className="widthFull textForm" placeholder="Enter Name" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Client Country </label>
                    <input type="text" onChange={(e)=>{setClientCountry(e.target.value);}} className="widthFull textForm" placeholder="Enter Country" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Client Location </label>
                    <input type="text" onChange={(e)=>{setClientLocation(e.target.value);}} className="widthFull textForm" placeholder="Enter Location" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Phone </label>
                    <input type="text" onChange={(e)=>{setPhone(e.target.value);}} className="widthFull textForm" placeholder="Enter Phone" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Email </label>
                    <input type="text" onChange={(e)=>{setEmailId(e.target.value);}} className="widthFull textForm" placeholder="Enter mail id" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Point Of Contact Person </label>
                    <input type="text" onChange={(e)=>{setPocName(e.target.value);}} className="widthFull textForm" placeholder="Enter Person Name" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Point Of Contact Number </label>
                    <input type="text" onChange={(e)=>{setPocContact(e.target.value);}} className="widthFull textForm" placeholder="Enter Number" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Client In Date </label>
                    <input type="Date" onChange={(e)=>{setClientInDate(e.target.value);}} className="widthFull textForm" placeholder="Enter Date" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Address </label>
                    <textarea className='widthFull textFormTa' placeholder='Enter Address' onChange={(e)=>{setAddress(e.target.value);}} ></textarea>
                </div>
                <input onClick={handleAdd} type="submit" value="Add" className="centered disBlock curpoi submitForm"/>
            </div>
        </>
    )
}