import Header from './components/Header';
import PageHeader from './components/PageHeader';
import RolesList from './components/RolesList';

export default function Roles() {  
      return (
    <>
    <Header/>
    <section className="disFlex flexWrap widthFull dashboardBlock centered">
        <div className="disFlex flexWrap pageHeader widthFull aic">
            <PageHeader/>
        </div>
        <div className="pageContainer scrollBarCustom location centered disBlock">
            <RolesList/>
        </div>
    </section>
    </>
  )
}

