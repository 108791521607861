import LogoOnlyWhite from '../images/logo-only-white.svg';
import { Link } from 'react-router-dom';
export default function logo(){
    return(
        <span  className="logo"> 
            <Link to="/dashboard"> 
                <img src={LogoOnlyWhite} className="widthFull disBlock" alt="speciFlow"/>
            </Link>
        </span>
    );
}