import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

export default function TestList(){
    const location = useLocation();
    const CurntPage = location.pathname.slice(1);
    const params = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const serchText = params.get("search");
    const tokenString = sessionStorage.getItem('token');
    const[post, setPost]= useState([]);
    const haddleDelete= (e, id)=> {
        axios.post('test_type_delete', {
            token: tokenString,
            test_master_id: id 
            }, {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then(response => { 
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                   window.location.reload(true);
                }else{ navigate('/'); } } })
    }
    useEffect(() => {

        if(serchText){
            axios.post('test_type_list', {
                token: tokenString,
                search: serchText,
            }, { headers: {
                   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => { 
                if(response.status==200 || response.status==201){
                    if(response.data.status==1){
                        setPost(response.data.data);
                    }else{ navigate('/'); } }  }) 
            }else{
                axios.post('test_type_list', {
                    token: tokenString
                }, { headers: {
                       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => { 
                    if(response.status==200 || response.status==201){
                        if(response.data.status==1){
                            setPost(response.data.data);
                        }else{ navigate('/'); } }  })   
            }
    }, []);
    if (sessionStorage.getItem("token") === null) {
        const tokenString = sessionStorage.getItem('token');
          if(!tokenString){ navigate('/');}
        }else{  
        if(post.length!=0){ 
return(
    <>
    <div className='widthFull succssMsg'> 
        {/* <span className="material-symbols-outlined vertAlMiddle disInline">thumb_up</span> {SuccMsgGet}  */}
    </div>
    {/* <div className='widthFull posFixed noPadd TtlcountDiv'> Total <b> {posts.length} </b> country added </div> */}
    <div className='Heads centered disFlex flexWrap'>
        <div className='one disFlex aic'>  # </div>
        <div className='two'> Test </div>
        <div className='three'> Category  </div>
        <div className='four'> Price </div>
        <div className='five'> Status </div>
        <div className='six'> </div>
    </div>   
    {post.map((data, index) =>{   
            return   (   
    <div className={data.test_status ? 'contents centered disFlex flexWrap' : 'contents centered disFlex flexWrap inactivated'} key={data.id}>
        <div className='one disFlex aic'> #{index+1} </div>
        <div className='two disFlex aic'> {data.test_name} </div>
        <div className='three disFlex aic categoryViewBlock'> 
            {data.category_name ? data.category_name : '' }
            {data.parent_category ? 
                <span className="material-symbols-outlined disInline vertAlMiddle arrowCatTest">chevron_left</span> : ''}
            {data.parent_category ? data.parent_category : '' }   
        </div>
        <div className='four disFlex flexWrap aic'>  
            <span className='widthFull spcl disInline'> Special: {data.special_price}  
                <span className='disInline offRate'> {(100-( data.special_price / data.mrp_price * 100)).toFixed(2)}%  off</span>
            </span>
            <span className='mrp disInline'> 
                MRP: {data.mrp_price} 
            </span>
        </div>
        <div className='five disFlex aic'> {data.test_status ? 'Active' : 'Inactive'} </div>
        <div className='six disFlex aic'> 
            <span className="material-symbols-outlined curpoi" title='Edit'>
                <Link to={'/EditTest?id='+data.id}> edit </Link>  
            </span>
            <span onClick={e=> haddleDelete(e, data.id)} className="material-symbols-outlined delete curpoi" title='Delete'>delete</span>
        </div>
    </div>  
        )
    })}
    
    {serchText? <div className='widthFull mt10 textCenter'>
                <span onClick={(e)=> {navigate('/Test');window.location.reload(true);}} className='disInline backSerachBut curpoi centered'> Back to Test </span>
             </div> : ""}
    </>
); }
else{
    return(
        <div className='disFlex notFountMsg widthFull flexWrap jcc aic'>
            <h4 className='widthFull textCenter'> Sorry! No data related to your search </h4> 
            <span onClick={(e)=> {navigate('/Test');window.location.reload(true);}} className='backtoMainButton disBlock curpoi'> Back to Test </span>
        </div>
    )
 }

}
}