
import Header from './components/Header';
import PageHeader from './components/PageHeader';
import ClientUserEdit from './components/ClientUserEdit';

export default function EditClientUser() {
  return (
    <>
    <Header/>
    <section className="disFlex flexWrap widthFull dashboardBlock centered">
       <div className="disFlex flexWrap pageHeader widthFull aic">
           <PageHeader/>
       </div>
       <div className="pageContainer scrollBarCustom location centered disBlock">
           <ClientUserEdit/>
       </div>
   </section>
   </>
  )
}
