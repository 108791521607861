import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
export default function TestEdit(){
    const tokenString = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const curntID = params.get("id");
     const serchText = params.get("search");
    const [BlankFormMsg, setBlankFormMsg] = useState(''); 
    const [errorMessage, setErrorMessage] = useState('');

    const[categoryList, setCategoryList]= useState([]);
    const[editPost, setEditPost]= useState([]);

    const [testName, setTestName] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [PrntCategoryId, setPrntCategoryId] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [mrp, setMrp] = useState('');
    const [special, setSpecial] = useState('');
    const [status, setStatus] = useState('');

    useEffect(() => {
        axios.post('test_type_view', {
            token: tokenString,
            test_master_id: curntID,
        }, { headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => { 
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                    setEditPost(response.data.data); 
                    setTestName(response.data.data[0].test_name);
                    setCategoryId(response.data.data[0].category);
                    setPrntCategoryId(response.data.data[0].parent_category_id);
                    setCategoryName(response.data.data[0].category_name);  
                    setMrp(response.data.data[0].mrp_price);
                    setSpecial(response.data.data[0].special_price);
                    setStatus(response.data.data[0].test_status);  
                }else{ navigate('/'); } }  })

        // category List
        axios.post('category_list', {
            token: tokenString,
            search: serchText,
        }, { headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => { 
            if(response.status==200){
                if(response.data.status==1){
                    setCategoryList(response.data.data);
                }else{ navigate('/'); } }  })
    }, []);//
    const handleUpdate =(e)=>{
        axios.post('test_type_update', {
            token: tokenString,
            test_master_id: curntID,
            test_name: testName,
            category_id: categoryId,
            special_price: special,
            mrp_price: mrp,
            test_status: status,
          }, {headers: {
                   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            })
        .then(response => {
            setErrorMessage(response.message); 
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                    sessionStorage.setItem('SuccMsg', (response.data.message))
                    navigate('/Test');
                }
                if(response.data.status==2){
                    setErrorMessage(response.data.message);
                }
              }
            })
    }
    return(
        <>
          {editPost.map((data) => { return   ( 
         <div className="addLocationBlock text centered disBlock" key={data.id}>
            <h4 className='textCenter textUppercase'> Edit {data.test_name} </h4>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Test </label>
                    <input type="text" className="widthFull textForm" placeholder="Enter Test" defaultValue={testName} onChange={(e)=>{setTestName(e.target.value);}}/>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Category </label>
                    <select value={PrntCategoryId}  onChange={(e)=>{setPrntCategoryId(e.target.value);}}>
                        {categoryList.map(list =>{ 
                            if(list.parent==null){
                            return(
                            <option value={list.id} key={list.id}> {list.category_name}  </option>
                            )
                            }
                        })}
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Subcategory </label>
                    <select value={categoryId}  onChange={(e)=>{setCategoryId(e.target.value);}}>
                        <option value='0'> Select Subcategory  </option>
                        {categoryList.map(listCat =>{ 
                            if(listCat.parent==PrntCategoryId){
                            return(
                            <option value={listCat.id} key={listCat.id}> {listCat.category_name}  </option>
                            )
                            }
                        })}
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Maximum Retail Prize </label>
                    <input type="text" className="widthFull textForm" placeholder="Enter MRP" defaultValue={mrp}  onChange={(e)=>{setMrp(e.target.value);}}/>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Special Price </label>
                    <input type="text" className="widthFull textForm" placeholder="Enter Price" defaultValue={special}  onChange={(e)=>{setSpecial(e.target.value);}}/>
                </div>
                <div className="block centered  posRelative">
                    <label className="widthFull disInline"> Status </label>
                    <input type="checkbox" className="disInline checkBt textForm" checked=  {status} onChange={(e)=>setStatus(e.target.checked)} />
                    Active
                </div>
                <input onClick={handleUpdate} type="Submit" defaultValue="Update" className="centered disBlock curpoi submitForm"/>
            </div>
            ) })}
        </>
    )
}