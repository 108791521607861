import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

export default function CategoryList(){
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const serchText = params.get("search");
    const tokenString = sessionStorage.getItem('token');
    const[post, setPost]= useState([]);
    const haddleDelete= (e, id)=> {
        axios.post('branch_delete', {
            token: tokenString,
            branch_id: id }, {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then(response => { 
            if(response.status==200){
                if(response.data.status==1){
                   window.location.reload(true);
                }else{ navigate('/'); } } })
    }
    useEffect(() => {
        if(serchText){
            axios.post('branch_list', {
                token: tokenString,
                search : serchText,
            }, { headers: {
                   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => { 
                if(response.status==200 || response.status==201 ){
                    if(response.data.status==1){
                        setPost(response.data.data);
                    }else{ navigate('/'); } }  }) 
            }else{
                axios.post('branch_list', {
                    token: tokenString
                }, { headers: {
                       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => { 
                    if(response.status==200 || response.status==201 ){
                        if(response.data.status==1){
                            setPost(response.data.data);
                        }else{ navigate('/'); } }  })   
            }
    }, []);
    if (sessionStorage.getItem("token") === null) {
        const tokenString = sessionStorage.getItem('token');
          if(!tokenString){ navigate('/');}
        }else{  
if(post.length!=0){ 
return(
    <>
    <div className='widthFull succssMsg'> 
        {/* <span className="material-symbols-outlined vertAlMiddle disInline">thumb_up</span> {SuccMsgGet}  */}
    </div>
    {/* <div className='widthFull posFixed noPadd TtlcountDiv'> Total <b> {posts.length} </b> country added </div> */}
    <div className='Heads centered disFlex flexWrap'>
        <div className='one disFlex aic'>  # </div>
        <div className='two'> Name </div>
        <div className='three'> Contact  </div>
        <div className='four'> Location </div>
        <div className='five'> Address </div>
        <div className='six'> </div>
    </div> 
    {post.map((data, index) =>{   
            return   (    
    <div className={data.branch_status ? 'contents centered disFlex flexWrap' : 'contents centered disFlex flexWrap inactivated'}  key={data.id}>
        <div className='one disFlex'> #{index+1} </div>
        <div className='two disFlex flexWrap'> 
            <b> {data.name} </b> 
            <span title='Short Code' className='disBlock Datas widthFull'>
            {data.branch_code}
            </span>
            <span title='Status' className='disBlock Datas widthFull'> 
            {data.branch_status ? 'Active' : 'Inactive'}
            </span>
        </div>
        <div className='three disFlex flexWrap'> 
            {data.branch_email ?
                <span title='Phome' className='disBlock Datas widthFull'>  
                    Call: <a href={'tel:'+data.branch_phone} className='disInline'> {data.branch_phone} </a>
                </span>
            : ''}
            {data.branch_email ?
                <span title='Email' className='disBlock Datas widthFull'>  
                    Mail: <a href={'mailto:'+data.branch_email} className='disInline'> {data.branch_email} </a>
                </span>
                : '' }
            {data.branch_whatsapp_phone ? 
                <span title='Whatsapp' className='disBlock Datas widthFull'>  
                Whatsapp: <a href={'https://wa.me/'+data.branch_whatsapp_phone} className='disInline'> {data.branch_whatsapp_phone} </a>
                </span>
            : '' }
            {data.goole_map_location ? 
            <a href={data.goole_map_location} className='aofBlock' title='Google Map' target='_blank' rel='noreferrer'> Location Map </a> 
            : '' }
            
        </div>
        <div className='four disFlex flexWrap'>  

            <span title='Country' className='disBlock Datas widthFull'> 
                {data.branch_country_name ? data.branch_country_name : ''}, {data.branch_region_name ? data.branch_region_name : ''}
            </span>
            <span title='Location' className='disBlock Datas widthFull'> 
                {data.branch_location_name ? data.branch_location_name : ''}, {data.place ? data.place : ''}
            </span>
        </div>
        <div className='five disFlex'> 
            {data.address ? 
                data.address
            : '--' }
         </div>
        <div className='six disFlex aic'> 
            <span className="material-symbols-outlined curpoi" title='Edit'>
                <Link to={'/EditBranch?id='+data.id}> edit </Link>  
            </span>
            <span className="material-symbols-outlined delete curpoi" title='Delete'  onClick={e=> haddleDelete(e, data.id)}>delete</span>
        </div>
    </div>  
        )
         })}
    
    {serchText? <div className='widthFull mt10 textCenter'>
                <span onClick={(e)=> {navigate('/Branches');window.location.reload(true);}} className='disInline backSerachBut curpoi centered'> Back to Branches </span>
             </div> : ""}
    </>
);
 }
else{
    return(
        <div className='disFlex notFountMsg widthFull flexWrap jcc aic'>
            <h4 className='widthFull textCenter'> Sorry! No data related to your search </h4> 
            <span onClick={(e)=> {navigate('/Branches');window.location.reload(true);}} className='backtoMainButton disBlock curpoi'> Back to Branches </span>
        </div>
    )
 }

}
}