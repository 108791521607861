import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
export default function ListPage(){
    const location = useLocation();
    const CurntPage = location.pathname.slice(1);
    const params = new URLSearchParams(location.search);
    const serchText = params.get("search");
    const [loadingBlock, setLoadingBlock] = useState(false);

    const[posts, setPost]= useState([]);
    const navigate = useNavigate();
    const SuccMsgGet = sessionStorage.getItem('SuccMsg');
    const [scsMsg, setScsMsg] = useState('');
    
    //sessionStorage.removeItem('SuccMsg');
    useEffect(() => {

        setLoadingBlock(true);
            setTimeout(() => {
                setLoadingBlock(false);
            }, 200);

        const tokenString = sessionStorage.getItem('token');
         //country
    if(CurntPage=='Country'){
        if(serchText){
            axios.post('country_list', {
                token: tokenString,
                search: serchText,
            }, { headers: {
                   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => { 
                if(response.status==200){
                    if(response.data.status==1){
                        setPost(response.data.data);
                    }else{ navigate('/'); } }  }) 
            }else{
                axios.post('country_list', {
                    token: tokenString
                }, { headers: {
                       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => { 
                    if(response.status==200){
                        if(response.data.status==1){
                            setPost(response.data.data);
                        }else{ navigate('/'); } }  })   
            }
        }//
        //Location
        if(CurntPage=='Locations'){
            if(serchText){
            axios.post('location_list', {
                token: tokenString,
                search: serchText
            }, {headers: {
                   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                } })
            .then(response => { 
                if(response.status==200){
                    if(response.data.status==1){
                        setPost(response.data.data);
                    }else{
                        navigate('/');
                    } } })
                }else{
                    axios.post('location_list', {
                        token: tokenString
                    }, {headers: {
                           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                        } })
                    .then(response => { 
                        if(response.status==200){
                            if(response.data.status==1){
                                setPost(response.data.data);
                            }else{
                                navigate('/');
                            } } })
                }
        }//
        //region
        if(CurntPage=='Region'){
            if(serchText){
                axios.post('region_list', {
                    token: tokenString,
                    search :serchText,
                }, { headers: {
                       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => { 
                    if(response.status==200){
                        if(response.data.status==1){
                            setPost(response.data.data);
                        }else{ navigate('/'); } }  }) 
                }else{
                    axios.post('region_list', {
                        token: tokenString
                    }, { headers: {
                           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                    .then(response => { 
                        if(response.status==200){
                            if(response.data.status==1){
                                setPost(response.data.data);
                            }else{ navigate('/'); } }  })   
                }
            }//

     }, []);
 //  useEffect(()=>{ }, [posts]);
const haddleDelete= (e, id)=> {
        const tokenString = sessionStorage.getItem('token');
 if(CurntPage=='Country'){
    axios.post('country_delete', {
        token: tokenString,
        category_id: id }, {
        headers: {
           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
    .then(response => { 
        if(response.status==200){
            if(response.data.status==1){
               window.location.reload(true);
            }else{ navigate('/'); } } })
}//country
if(CurntPage=='Region'){
    axios.post('region_delete', {
        token: tokenString,
        region_id: id }, {
        headers: {
           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
    .then(response => { 
        if(response.status==200){
            if(response.data.status==1){
               // navigate('/Dashboard');
               window.location.reload(true);
            }else{ navigate('/'); } } })
}//region
if(CurntPage=='Locations'){
    axios.post('location_delete', {
        token: tokenString,
        location_id: id }, {
        headers: {
           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
    .then(response => { 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                window.location.reload(true);
                setScsMsg(response.data.message)
            }else{ navigate('/'); } } })
}
        return
    }//delete
    if (sessionStorage.getItem("token") === null) {
        const tokenString = sessionStorage.getItem('token');
          if(!tokenString){ navigate('/');}
        }else{  
     // Country
    if(CurntPage=='Country'){
        if(posts.length!=0){ 
             return(
        <>
        {/* <div className='widthFull succssMsg'> 
            <span className="material-symbols-outlined vertAlMiddle disInline">thumb_up</span> {SuccMsgGet} 
        </div> */}
        <div className='widthFull posFixed noPadd TtlcountDiv'> Total <b> {posts.length} </b> country added </div>
          <div className='Heads centered disFlex flexWrap'>
                <div className='one disFlex aic'>  # </div>
                <div className='two'> Country </div>
                <div className='three'> Short Code  </div>
                <div className='four'> Telephone Code </div>
                <div className='five'> Status </div>
                <div className='six'> </div>
            </div>  
           {posts.map((data, index) =>{   
            return   (      
            <div  className={data.country_status ? 'contents centered disFlex flexWrap' : 'contents centered disFlex flexWrap inactivated'} key={data.id}>
                <div className='one disFlex aic'> #{index+1}</div>
                <div className='two disFlex aic'> {data.country_name} </div>
                <div className='three disFlex aic'>  {data.country_sort_code} </div>
                <div className='four disFlex aic'>  {data.country_telephone_code} </div>
                <div className='five disFlex aic'> {data.country_status ? 'Active' : 'Inactive'} </div>
                <div className='six disFlex aic'> 
                    <span className="material-symbols-outlined curpoi" title='Edit'>
                        <Link to={'/EditCountry?id='+data.id}> edit </Link> </span>
                    <span onClick={e=> haddleDelete(e, data.id)} className="material-symbols-outlined delete curpoi" title='Delete'>delete</span>
                </div>
            </div> 
            )
              })}

              {serchText? <div className='widthFull mt10 textCenter'>
                <span onClick={(e)=> {navigate('/Country');window.location.reload(true);}} className='disInline backSerachBut curpoi centered'> Back to Country </span>
             </div> : ""}
           
            {/* {sessionStorage.removeItem('SuccMsg')} */}
        </>
      );
         }else{
            return(
                <>
                    {loadingBlock ? <section className="posFixed disFlex jcc aic loadingBlock">
                            <span className="loadingBlockSpan posRelative">
                                <span className="image widthFull heightFull posAbsolute z1"></span>
                            </span>
                            </section> : 
                            <section className="posFixed disFlex jcc aic loadingBlock">
                            <span className="loadingBlockSpan posRelative">
                                <span className="image widthFull heightFull posAbsolute z1"></span>
                            </span>
                            </section>  }
                </>
                // <div className='disFlex notFountMsg widthFull flexWrap jcc aic'>
                //     <h4 className='widthFull textCenter'> Sorry! No data related to your search </h4> 
                //     <span onClick={(e)=> {navigate('/Country');window.location.reload(true);}} className='backtoMainButton disBlock curpoi'> Back to Country </span>
                // </div>
            )
         }
    } // 

    //  Location
    if(CurntPage=='Locations'){
        if(posts.length!=0){
        return(
            <>
            {SuccMsgGet ?  <div className='widthFull succssMsg'> 
            <span className="material-symbols-outlined vertAlMiddle disInline">thumb_up</span> {SuccMsgGet} 
            </div> : "" }
            {scsMsg ?  <div className='widthFull succssMsg'> 
            <span className="material-symbols-outlined vertAlMiddle disInline">thumb_up</span> {scsMsg} 
            </div> : "" }
        <div className='widthFull posFixed noPadd TtlcountDiv'> Total <b> {posts.length} </b> location added </div>
              <div className='Heads centered disFlex flexWrap'>
                    <div className='one disFlex aic'>  # </div>
                    <div className='two'> Location </div>
                    <div className='five'> Region </div>
                    <div className='five'> Country </div>
                    <div className='five'> Short Code </div>
                    <div className='five'> Status </div>
                    <div className='five'>  </div>
                </div>
                   
               {posts.map((data, index) =>{   
                return   (      
                <div className={data.location_status ? 'contents centered disFlex flexWrap' : 'contents centered disFlex flexWrap inactivated'} key={data.id}>
                    <div className='one disFlex aic'> #{index+1} </div>
                    <div className='two disFlex aic'> {data.location_name} </div>
                    <div className='five disFlex aic'> {data.location_region_name} </div>
                    <div className='five disFlex aic'> {data.location_country_name} </div>
                    <div className='five disFlex aic'> {data.location_sort_code} </div>
                    <div className='five disFlex aic'> {data.location_status ? 'Active' : 'Inactive'}</div>
                    <div className='five disFlex aic'> 
                        <span className="material-symbols-outlined curpoi" title='Edit'>
                            <Link to={'/EditLocation?id='+data.id}> edit </Link>
                        </span>
                        <span onClick={e=> haddleDelete(e, data.id)} className="material-symbols-outlined delete curpoi" title='Delete'>delete</span>
                    </div>
                </div> 
                )
                  })}
                {serchText? <div className='widthFull mt10 textCenter'>
                    <span onClick={(e)=> {navigate('/Locations');window.location.reload(true);}} className='disInline backSerachBut curpoi centered'> Back to locations </span>
                </div> : ""}
            </>
        );
                }else{
                    return(
                        <>
                        {loadingBlock ? <section className="posFixed disFlex jcc aic loadingBlock">
                            <span className="loadingBlockSpan posRelative">
                                <span className="image widthFull heightFull posAbsolute z1"></span>
                            </span>
                            </section> : 
                            <section className="posFixed disFlex jcc aic loadingBlock">
                            <span className="loadingBlockSpan posRelative">
                                <span className="image widthFull heightFull posAbsolute z1"></span>
                            </span>
                            </section>  }
                        {/* {loadingBlock ? <section className="posFixed disFlex jcc aic loadingBlock">
                            <span class="loadingBlockSpan posRelative">
                                <span class="image widthFull heightFull posAbsolute z1"></span>
                            </span>
                            </section> : 
                            <div className='disFlex notFountMsg widthFull flexWrap jcc aic'>
                            <h4 className='widthFull textCenter'> Sorry! No data related to your search </h4> 
                            <span onClick={(e)=> {navigate('/Locations');window.location.reload(true);}} className='backtoMainButton disBlock curpoi'> Back to Locations </span>
                        </div> } */}
                        </>
                    )
                 }
    }// 
    // Region
    if(CurntPage=='Region'){
        if(posts.length!=0){
        return(
            <>
            <div className='widthFull succssMsg'> 
                <span className="material-symbols-outlined vertAlMiddle disInline">thumb_up</span> {SuccMsgGet} 
            </div>
            <div className='widthFull posFixed noPadd TtlcountDiv'> Total <b> {posts.length} </b> region added </div>
              <div className='Heads centered disFlex flexWrap'>
                    <div className='one disFlex aic'>  # </div>
                    <div className='two'> Region </div>
                    <div className='three'> Short Code </div>
                    <div className='four'>Country </div>
                    <div className='five'> Status </div>
                    <div className='six'>  </div>
                </div>         
                {posts.map((data, index) =>{   
                    return   (              
                <div  className={data.region_status ? 'contents centered disFlex flexWrap' : 'contents centered disFlex flexWrap inactivated'} key={data.id}>
                    <div className='one disFlex aic'> #{index+1} </div>
                    <div className='two disFlex aic'> {data.region_name} </div>
                    <div className='three disFlex aic'> {data.region_sort_code} </div>
                    <div className='four disFlex aic'> {data.country_name} </div>
                    <div className='five disFlex aic'> {data.region_status ? 'Active' : 'Inactive'} </div>
                    <div className='six disFlex aic'> 
                        <span className="material-symbols-outlined curpoi" title='Edit'>
                            <Link to={'/EditRegion?id='+data.id}> edit </Link>
                        </span>
                        <span onClick={e=> haddleDelete(e, data.id)} className="material-symbols-outlined delete curpoi" title='Delete'>delete</span>
                    </div>
                </div> 
                )
                })}
                {serchText? <div className='widthFull mt10 textCenter'>
                    <span onClick={(e)=> {navigate('/Region');window.location.reload(true);}} className='disInline backSerachBut curpoi centered'> Back to Region </span>
                </div> : ""}
            </>
        );
            } else{
                return(
                    <>
                        {loadingBlock ? <section className="posFixed disFlex jcc aic loadingBlock">
                            <span className="loadingBlockSpan posRelative">
                                <span className="image widthFull heightFull posAbsolute z1"></span>
                            </span>
                            </section> : 
                            <section className="posFixed disFlex jcc aic loadingBlock">
                            <span className="loadingBlockSpan posRelative">
                                <span className="image widthFull heightFull posAbsolute z1"></span>
                            </span>
                            </section>  }
                    </>
                // <div className='disFlex notFountMsg widthFull flexWrap jcc aic'>
                //     <h4 className='widthFull textCenter'> Sorry! No data related to your search </h4> 
                //     <span onClick={(e)=> {navigate('/Region');window.location.reload(true);}}className='backtoMainButton disBlock curpoi'> Back to Region </span>
                // </div>
                )
            }
    }

}}