import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

export default function CategoryList(){
    const location = useLocation();
    const CurntPage = location.pathname.slice(1);
    const params = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const serchText = params.get("search");
    const tokenString = sessionStorage.getItem('token');
    const[post, setPost]= useState([]);
    const haddleDelete= (e, id)=> {
        axios.post('category_delete', {
            token: tokenString,
            category_id: id }, {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then(response => { 
            if(response.status==200){
                if(response.data.status==1){
                   // navigate('/Dashboard');
                   window.location.reload(true);
                }else{ navigate('/'); } } })
    }
    useEffect(() => {

        if(serchText){
            axios.post('category_list', {
                token: tokenString,
                search: serchText,
            }, { headers: {
                   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => { 
                if(response.status==200){
                    if(response.data.status==1){
                        setPost(response.data.data);
                    }else{ navigate('/'); } }  }) 
            }else{
                axios.post('category_list', {
                    token: tokenString
                }, { headers: {
                       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => { 
                    if(response.status==200){
                        if(response.data.status==1){
                            setPost(response.data.data);
                        }else{ navigate('/'); } }  })   
            }
    }, []);
    if (sessionStorage.getItem("token") === null) {
        const tokenString = sessionStorage.getItem('token');
          if(!tokenString){ navigate('/');}
        }else{  
        if(post.length!=0){ 
return(
    <>
    <div className='widthFull succssMsg'> 
        {/* <span className="material-symbols-outlined vertAlMiddle disInline">thumb_up</span> {SuccMsgGet}  */}
    </div>
    {/* <div className='widthFull posFixed noPadd TtlcountDiv'> Total <b> {posts.length} </b> country added </div> */}
    <div className='Heads centered disFlex flexWrap'>
        <div className='one disFlex aic'>  # </div>
        <div className='two'> Category </div>
        <div className='three'> Parent  </div>
        <div className='four'> Status </div>
        <div className='five'>  </div>
        <div className='six'> </div>
    </div>   
    {post.map((data, index) =>{   
            return   (   
    <div className={data.category_status ? 'contents centered disFlex flexWrap' : 'contents centered disFlex flexWrap inactivated'} key={data.id}>
        <div className='one disFlex aic'> #{index+1} </div>
        <div className='two disFlex aic'> {data.category_name} </div>
        <div className='three disFlex aic'> {data.parent_name ? data.parent_name : '--' } </div>
        <div className='four disFlex aic'> {data.category_status ? 'Active' : 'Inactive'} </div>
        <div className='five disFlex aic'>  </div>
        <div className='six disFlex aic'> 
            <span className="material-symbols-outlined curpoi" title='Edit'>
                <Link to={'/EditCategory?id='+data.id}> edit </Link>  
            </span>
            <span onClick={e=> haddleDelete(e, data.id)} className="material-symbols-outlined delete curpoi" title='Delete'>delete</span>
        </div>
    </div>  
        )
    })}
    
    {serchText? <div className='widthFull mt10 textCenter'>
                <span onClick={(e)=> {navigate('/Categories');window.location.reload(true);}} className='disInline backSerachBut curpoi centered'> Back to Categories </span>
             </div> : ""}
    </>
); }
else{
    return(
        <div className='disFlex notFountMsg widthFull flexWrap jcc aic'>
            <h4 className='widthFull textCenter'> Sorry! No data related to your search </h4> 
            <span onClick={(e)=> {navigate('/Categories');window.location.reload(true);}} className='backtoMainButton disBlock curpoi'> Back to Categories </span>
        </div>
    )
 }

}
}