import axios from '../api/axios';
import { useEffect, useState } from 'react';
import * as mainUrl from '../api/const';
import { useNavigate, useLocation} from 'react-router-dom';
import clientDefaultlogo from '../images/clientDef-logo.png';
export default function ProfileView(){
    const tokenString = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const curntID = params.get("id");
     const serchText = params.get("search");
    const [BlankFormMsg, setBlankFormMsg] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const[post, setPost]= useState([]);

    const [testName, setTestName] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [mrp, setMrp] = useState('');
    const [special, setSpecial] = useState('');
    const [status, setStatus] = useState('');

    useEffect(() => {
        axios.post('admin_profile', {
            token: tokenString,
        }, { headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => { 
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                    setPost(response.data.data); 
                }else{ navigate('/'); } }  })
    }, []);//
    const handleUpdate =(e)=>{
        // axios.post('test_type_update', {
        //     token: tokenString,
        //     test_master_id: curntID,
        //     test_name: testName,
        //     category_id: categoryId,
        //     special_price: special,
        //     mrp_price: mrp,
        //     test_status: status,
        //   }, {headers: {
        //            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        //         }
        //     })
        // .then(response => {
        //     setErrorMessage(response.message); 
        //     if(response.status==200 || response.status==201){
        //         if(response.data.status==1){
        //             sessionStorage.setItem('SuccMsg', (response.data.message))
        //             navigate('/Test');
        //         }
        //         if(response.data.status==2){
        //             setErrorMessage(response.data.message);
        //         }
        //       }
        //     })
    }
    return(
        <>
          {post.map((data) => { return   ( 
         <div className="addLocationBlock centered profileViewBlock text disBlock" key={data.id}>
                <div className="block centered posRelative">
                    <span className='profilePic disInline vertAlMiddle'>
                        <img src={data.photos_image ? mainUrl.MediaUrl+data.photos_image : clientDefaultlogo} className='widthFull coverCenterImage heightFull' alt='mane'></img>
                    </span>
                    <span className='cont disInline vertAlMiddle'>
                        <label className="widthFull disInline">  Name </label>
                        <h5> {data.first_name} {data.last_name} </h5>
                    </span>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline">  Phone </label>
                    <h5> {data.phone} </h5>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline">  EMail </label>
                    <h5> {data.email} </h5>
                </div>
                <div className="block disFlex flexWrap doubleBlock centered posRelative">
                    <div className='BlockDbl'>
                        <label className="widthFull disInline"> Gender </label> 
                        <h5> {data.gender=='0'? 'Male' : data.gender=='1' ? 'Female' : 'Others' }  </h5>
                    </div>
                    <div className='BlockDbl'>
                        <label className="widthFull disInline"> Marital </label> 
                        <h5> {data.marital=='0'? 'Single' : 'Married'}  </h5>
                    </div>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline">  DOB </label>
                    <h5> {data.dob} </h5>
                </div>
                <div className="block disFlex flexWrap doubleBlock centered posRelative">
                    <div className='BlockDbl'>
                        <label className="widthFull disInline"> Blood Group </label> 
                        <h5> {data.blood_group}  </h5>
                    </div>
                    <div className='BlockDbl'>
                        <label className="widthFull disInline"> Status </label> 
                        <h5> {data.is_active ? 'Active' : 'Inactive'}  </h5>
                    </div>
                </div>
                <div className="block disFlex flexWrap doubleBlock centered posRelative">
                    <div className='BlockDbl'>
                        <label className="widthFull disInline"> Country </label> 
                        <h5> {data.country_name}  </h5>
                    </div>
                    <div className='BlockDbl'>
                        <label className="widthFull disInline"> Region </label> 
                        <h5> {data.region_name}  </h5>
                    </div>
                    <div className='BlockDbl'>
                        <label className="widthFull disInline"> Location </label> 
                        <h5> {data.location_name}  </h5>
                    </div>
                    <div className='BlockDbl'>
                        <label className="widthFull disInline"> Branch </label> 
                        <h5> {data.branch_name}  </h5>
                    </div>
                </div>
            </div>
            ) })}
        </>
    )
}