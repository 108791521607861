import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
export default function RoleAdd(){
    const tokenString = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const [BlankFormMsg, setBlankFormMsg] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const[post, setPost]= useState([]);
    const[countryList, setCountryList]= useState([]);
    const[regionList, setRegionList]= useState([]);
    const[locationList, setLocationList]= useState([]);
    const[branchList, setBranchList]= useState([]);
    
    const [countryId, setCountryId] = useState('');
    const [regionId, setRegionId] = useState('');
    const [locationId, setLocationId] = useState('');
    const [branchId, setBranchId] = useState('');
    const [roleName, setRoleName] = useState('');
    const [status, setStatus] = useState('');
    const[roleId, setRoleId]= useState([]);
    
    // category List
useEffect(() => {
     //Country List
     axios.post('country_list', {
        token: tokenString,
    }, { headers: {
           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => { 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                setCountryList(response.data.data);
            }else{ navigate('/'); } }  })
//Region List
axios.post('region_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setRegionList(response.data.data);
        }else{ navigate('/'); } }  })
//Location List
axios.post('location_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setLocationList(response.data.data);
        }else{ navigate('/'); } }  })

//Branch List
axios.post('branch_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setBranchList(response.data.data);
        }else{ navigate('/'); } }  })
    //path
    axios.post('path_list', {
        token: tokenString,
    }, { headers: {
           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => { 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                setPost(response.data.data);
            }else{ navigate('/'); } }  })
}, []);

 // category Add
 const handleAdd =(e) =>{ 
    const formData = new FormData();
    formData.append("token", tokenString);
    formData.append("region_id", regionId);
    formData.append("country_id", countryId);
    formData.append("location_id", locationId);
    formData.append("branch_id", branchId);
    formData.append("path_array", roleId);
    formData.append("role_name", roleName);
    formData.append("status", status);
 axios.post('role_add', formData)
.then(response => {
    setErrorMessage(response.message); 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            sessionStorage.setItem('SuccMsg', (response.data.message))
            navigate('/Roles'); }
        if(response.data.status==2){
            setErrorMessage(response.data.message); }
        }
      })
      .catch(error => { setErrorMessage(error.response.message);}); 
    };
   const  haddleRoleID =(e, roleIdInsrt)=>{
        setRoleId(current => [...current, roleIdInsrt]);
    };
    return( 
        <>
         <div className="addLocationBlock jcsb RoleAdd disFlex flexWrap text centered disBlock">
                <div className='CarryBlock left'>
                    <div className='blockofform'>
                        <div className="block posRelative centered">
                            <label className="widthFull disInline"> Country </label>
                            <select onChange={(e)=>{setCountryId(e.target.value);}}>
                                <option> Select a Country </option>
                                {countryList.map(data =>{ 
                                return(
                                <option value={data.id} key={data.id}> {data.country_name} </option>
                                    )
                                })}
                            </select>
                            <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                        </div>
                        <div className="block posRelative centered">
                            <label className="widthFull disInline"> Region </label>
                            <select onChange={(e)=>{setRegionId(e.target.value);}}>
                                <option> Select a Region  </option>
                                {regionList.map(data =>{
                                    if(countryId == data.region_country){
                                    return(
                                        <option value={data.id} key={data.id}> {data.region_name}  </option>
                                    )
                                  }
                                })}
                            </select>
                            <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                        </div>
                        <div className="block posRelative centered">
                            <label className="widthFull disInline"> Location </label>
                            <select onChange={(e)=>{setLocationId(e.target.value);}}>
                                <option> Select a Location  </option>
                                {locationList.map(data=> {
                                     if(regionId == data.location_region){
                                        return(
                                            <option value={data.id} key={data.id}> {data.location_name} </option>
                                        )
                                     }
                                })}
                            </select>
                            <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                        </div>
                        <div className="block posRelative centered">
                            <label className="widthFull disInline"> Branch </label>
                            <select onChange={(e)=>{setBranchId(e.target.value);}}>
                                <option> Select a Branch  </option>
                                {branchList.map(data=>{
                                    if(locationId == data.branch_location){
                                        return(
                                            <option value={data.id} key={data.id}> {data.name}  </option>
                                        )
                                    }
                                })}
                                
                            </select>
                            <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                        </div>
                        <div className="block centered posRelative">
                            <label className="widthFull disInline"> Role Name </label>
                            <input type="text" onChange={(e)=>{setRoleName(e.target.value);}} className="widthFull textForm" placeholder="Enter Name" />
                        </div>
                        <div className="block posRelative centered">
                            <label className="widthFull disInline"> Status </label>
                            <select onChange={(e)=>{setStatus(e.target.value);}}>
                                <option> Select Status </option>
                                <option value='0'> Active  </option>
                                <option value='1'> Inactive  </option>
                                <option value='2'> Hidden  </option>
                            </select>
                            <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                        </div>
                    </div>
                </div>
                <div className='CarryBlock right'>
                    <h3> Menu to Show  </h3>
                    <div className='blockofform widthFull scrollBarCustom'>
                        {post.map((data)=> {   
                            if(data.super_parent==null)   
                                 {                                                    
                                return   ( 
                                    <> 
                                   {data.parent==null ? 
                        <div className="block disFlex jcsb widthFull flexWrap posRelative centered" key={data.id}>
                            <div className='uderNameDiv noPadd blk vertAlMiddle'>
                                <input type='checkbox' onChange={e=>haddleRoleID(e, data.id)} name='pathRoleCheckBox[]' className='disInline vertAlMiddle checkRole'></input>{data.parent} {data.path_name}
                            </div>
                            <div className='RoleSelector noPadd blk'>
                                {post.map((parentData)=> {
                                    if(data.id==parentData.parent){  
                                       return   ( 
                                <span className='disInline vertAlMiddle' key={parentData.id}> 
                                    <input type='checkbox' value={parentData.id} name='pathRoleCheckBox[]' className='disInline vertAlMiddle checkRole' onChange={(e)=>{setRoleId(e.target.value);}}></input>
                                    {parentData.path_name}
                                </span>
                                       )
                                    }
                                })}
                            </div>
                            {post.map((superParentData)=> {
                                if(data.id==superParentData.super_parent ){
                                       return   ( 
                                        <>
                                     {superParentData.parent==null ? 
                            <div className='subBlock noPadd widthFull jcsb disFlex flexWrap'>
                                <div className='left blkSub'> 
                                    <input type='checkbox' name='pathRoleCheckBox[]' className='disInline vertAlMiddle checkRole'  value={superParentData.id} onChange={(e)=>{setRoleId(e.target.value);}}></input> {superParentData.path_name }
                                </div>
                                <div className='right blkSub vertAlMiddle'>
                                {post.map((superData)=> {
                                    if(data.id==superData.super_parent){ 
                                     return   ( 
                                        <>
                                        {superData.parent && superParentData.id==superData.parent ? 
                                    <span className='disInline vertAlMiddle'> 
                                        <input type='checkbox' name='pathRoleCheckBox[]'  className='disInline vertAlMiddle checkRole' value={superData.id} onChange={(e)=>{setRoleId(e.target.value);}}></input>
                                        {superData.path_name}
                                    </span> : ''}
                                        </>
                                       )
                                     }
                                    })}
                                </div>
                                
                            </div>
                            : ''}  
                            </>
                              )
                            }
                          })}
                        </div> : ''}
                        
                        </> 

                            )
                          }
                        })} 

                        </div>
                    </div>
                    <input type='submit' className='widthFull centered disBlock curpoi submitForm' value="Add Role" onClick={handleAdd}></input>
                </div>
            </>
            )
        }