import Header from './components/Header';
import PageHeader from './components/PageHeader'
import CategoryList from './components/CategoryList';

export default function Categories() {  
      return (
    <>
    <Header/>
    <section className="disFlex flexWrap widthFull dashboardBlock centered">
        <div className="disFlex flexWrap pageHeader widthFull aic">
            <PageHeader/>
        </div>
        <div className="pageContainer scrollBarCustom location centered disBlock">
            <CategoryList/>
        </div>
        
    </section>
    </>
  )
}

