import LogoOnlyWhite from './images/logo-only-white.svg';
import LogoBlue from './images/logo.svg';
import ClientLoginform from './components/ClientLoginform';

export default function ClientLogin(){
    return(
        <>
         <section className="widthFull mainCarrier noPadd disFlex loginCarry posRelative">
        <div className="left disFlex posRelative">
            <span className="disBlock posAbsolute noPadd logoBig">
            <img src={LogoOnlyWhite} className="widthFull disBlock" alt="speciFlow"/>
            </span>
            <h1> Track <br/> Your <br/> Specimen. </h1>
        </div>
        <div className="right noPadd posRelative jcc aic disFlex">
            <div className="block textCenter">
                <div className="disInline logo">
                    <img src={LogoBlue} className="widthFull disBlock" alt="speciFlow"/>
                </div>
                <h3> Welcome Client! </h3>
                <span className="disInline loginText mt05 widthFull"> Login to SpeciFlow! </span>
                <ClientLoginform/>
            </div>
        </div>
    </section>
        </>
    );
}