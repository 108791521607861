import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

export default function CategoryEdit() {
  const tokenString = sessionStorage.getItem('token');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const curntID = params.get("id");
  const navigate = useNavigate();
  const[editPost, setEditPost]= useState([]);

  const[CategoryName, setCategoryName]= useState('');
  const[parentId, setParentId]= useState('');
  const[parentName, setParentName]= useState('');
  const[status, setSatus]= useState('');

  const[catList, SetCatList]= useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const handleUpdate = (e) =>{
    axios.post('category_update', {
      token: tokenString,
      category_id: curntID,
      category: CategoryName,
      parent_id: parentId,
      category_status: status,
    }, {headers: {
             'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
      })
  .then(response => {
      setErrorMessage(response.message); 
      if(response.status==200 || response.status==201){
          if(response.data.status==1){
              sessionStorage.setItem('SuccMsg', (response.data.message))
              navigate('/Categories');
          }
          if(response.data.status==2){
              setErrorMessage(response.data.message);
          }
        }
      })
    }// handle update
  useEffect(() => {
    // category View
      axios.post('category_view', {
      token: tokenString,
      category_id: curntID,
  }, { headers: {
         'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }})
  .then(response => { 
      if(response.status==200){
          if(response.data.status==1){
              setEditPost(response.data.data); 
              setCategoryName(response.data.data[0].category_name);
              setParentId(response.data.data[0].parent); 
              setParentName(response.data.data[0].parent_name);
              setSatus(response.data.data[0].category_status);  
          }else{ navigate('/'); } }  })

      // category List
      axios.post('category_list', {
        token: tokenString
    }, { headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => { 
        if(response.status==200){
            if(response.data.status==1){
                SetCatList(response.data.data);
            }else{ navigate('/'); } }  }) 
        
  }, []);

  return (
    <>    
    {editPost.map((data) => { return   (  
    <div className="addLocationBlock text centered disBlock" key={data.id}>
      <h4 className='textCenter textUppercase'> Edit {data.category_name} </h4>
        <div className="block centered posRelative">
            <label className="widthFull disInline"> Category </label>
            <input type="text" className="widthFull textForm" value={CategoryName} onChange={(e)=>setCategoryName(e.target.value)}  />
          </div>
          <p> </p>
          <div className="block posRelative centered">
              <label className="widthFull disInline"> Parent </label>
              <select value={parentId? parentId : '0' } onChange={(e) =>{setParentId(e.target.value);}}>  
                  {catList.map((Plist) => {
                      if(Plist.parent == null && Plist.id != data.id ){
                       return   (   
                        <option value={Plist.id} key={Plist.id}> {Plist.category_name}  </option> 
                      ) 
                       }                 
                    })}
                  <option value="0"> No parent  </option>
              </select>
              <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
          </div>
          <div className="block centered  posRelative">
                <label className="widthFull disInline"> Status </label>
                <input type="checkbox" className="disInline checkBt textForm" checked={status} onChange={(e)=>setSatus(e.target.checked)} />
                Active
            </div>
          <input type="submit" onClick={handleUpdate} className="centered disBlock curpoi submitForm" value="Update"/>
      </div>
      ) })}
      </>
  )
}
