import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import * as mainUrl from '../api/const';
export default function ClientUserEdit(){
    const tokenString = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const curntID = params.get("id");
    const serchText = params.get("search");
    const [BlankFormMsg, setBlankFormMsg] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const[post, setPost]= useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [genderID, setGenderId] = useState('');
    const [phone, setPhone] = useState('');
    const [altPhone, setAltPhone] = useState('');
    const [emailId, setEmailId] = useState('');   
    const [password, setPassword] = useState('');  
    const [ClientId, setClientId] = useState(''); 
    const [userImage, setUserImage] = useState('');
    const [clientUserId, setClientUserId] = useState(''); 
    const [fileChenge, setFileChenge] = useState('1');
    const [status, setStatus] = useState('');
    
useEffect(() => {
//Client View
axios.post('client_user_view', {
    token: tokenString,
    client_user_id: curntID,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setPost(response.data.data);
            setClientUserId(response.data.data[0].id);
            setFirstName(response.data.data[0].first_name);
            setLastName(response.data.data[0].last_name);
            setGenderId(response.data.data[0].gender);
            setPhone(response.data.data[0].phone);
            setAltPhone(response.data.data[0].alternative_phone);
            setEmailId(response.data.data[0].email);
            setPassword(response.data.data[0].password);
            setClientId(response.data.data[0].client);
            setUserImage(response.data.data[0].photos_image);
            setStatus(response.data.data[0].status);
            sessionStorage.setItem('clientIDSave', (response.data.data[0].client))
        }else{ navigate('/'); } 
    }  })
}, []);
const handdleImageFile =(e)=>{
    setFileChenge(URL.createObjectURL(e.target.files[0])); setUserImage((e.target.files[0]));
}

 // category Add
 const hadleUpdate =(e) =>{ 
    const formData = new FormData();
    formData.append("token", tokenString);
    formData.append("client_user_id", clientUserId);
    formData.append("client_id", ClientId);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("phone", phone);
    formData.append("gender", genderID);
    formData.append("alternative_phone", altPhone);
    formData.append("photos_image", userImage);
    formData.append("email", emailId);
    formData.append("status", status);
    axios.post('client_user_update', formData)
    .then(response => {
        setErrorMessage(response.message); 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                sessionStorage.setItem('SuccMsg', (response.data.message))
                navigate('/ClientUser?id='+ClientId);
            }
            if(response.data.status==2){
                setErrorMessage(response.data.message);
            }
        }
    })
    .catch(error => {
        setErrorMessage(error.response.message); 
    });
};
    return(
        <>
        {post.map(data =>{ return(
         <div className="addLocationBlock text centered disBlock" key={data.id}>
            <div className="block centered posRelative">
                 <label className="widthFull disInline"> User Photo </label>
                 <input type="file" onChange={handdleImageFile} className="widthFull textForm" placeholder="User Image" />
                 <span className='posAbsolute nowImageShow disFlex jcc aic'>
                        {userImage ? fileChenge == '1' ?  
                        <img src={mainUrl.MediaUrl+data.photos_image}  className='disBlock widthFull coverCenterImage heightFull' alt=''></img> 
                        :  <img src={fileChenge}  className='disBlock widthFull coverCenterImage heightFull' alt=''></img> : "No Image Chosen"}
                </span>
             </div>
             <div className="block centered posRelative">
                 <label className="widthFull disInline"> First Name </label>
                 <input type="text" value={firstName} onChange={(e)=>{setFirstName(e.target.value);}} className="widthFull textForm" placeholder="Enter Name" />
             </div>
             <div className="block centered posRelative">
                 <label className="widthFull disInline"> Last Name </label>
                 <input type="text" value={lastName} onChange={(e)=>{setLastName(e.target.value);}} className="widthFull textForm" placeholder="Enter Name" />
             </div>
             <div className="block posRelative centered">
                 <label className="widthFull disInline"> Gender </label>
                 <select value={genderID} onChange={(e)=>{setGenderId(e.target.value);}} key={genderID}>
                     <option value=''> Select a Gender  </option>
                     <option value='0'> Male  </option>
                     <option value='1'> Female  </option>
                     <option value='2'> Others  </option>
                 </select>
                 <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
             </div>
             <div className="block centered posRelative">
                 <label className="widthFull disInline"> Phone </label>
                 <input type="text" value={phone} onChange={(e)=>{setPhone(e.target.value);}} className="widthFull textForm" placeholder="Enter Number" />
             </div>
             <div className="block centered posRelative">
                 <label className="widthFull disInline"> Alternative Number </label>
                 <input type="text" value={altPhone ? altPhone : ''} onChange={(e)=>{setAltPhone(e.target.value);}} className="widthFull textForm" placeholder="Enter Number" />
             </div>
             <div className="block centered posRelative">
                 <label className="widthFull disInline"> Email ID(UserName) </label>
                 <input type="email" value={emailId} onChange={(e)=>{setEmailId(e.target.value);}} className="widthFull textForm" placeholder="Enter ID" />
             </div>
             <div className="block centered  posRelative">
                <label className="widthFull disInline"> Status </label>
                <input type="checkbox" className="disInline checkBt textForm" checked={status} onChange={(e)=>setStatus(e.target.checked)} />
                        Active
            </div>
             <input type="hidden" value={ClientId} className="widthFull textForm"/>
             <input onClick={hadleUpdate} type="submit" value="Update User" className="centered disBlock curpoi submitForm"/>
         </div>
            )
        })}
        </>
    )
}