import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
export default function BranchAdd(){
    const tokenString = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
     const serchText = params.get("search");
    const [BlankFormMsg, setBlankFormMsg] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const[countryList, setCountryList]= useState([]);
    const[regionList, setRegionList]= useState([]);
    const[locationList, setLocationList]= useState([]);

    const [country, setCountry] = useState('');
    const [countryId, setCountryId] = useState('');
    const [region, seRegion] = useState('');
    const [regionId, seRegionId] = useState('');
    const [locationName, setLocation] = useState('');
    const [locationId, setLocationId] = useState('');
    const [name, setName] = useState('');
    const [place, setPlace] = useState('');
    const [phone, setPhone] = useState('');
    const [emailId, setEmailId] = useState('');
    const [whatsApp, setWhatsapp] = useState('');
    const [mapUrl, setMapUrl] = useState('');
    const [address, setAddress] = useState('');
    

    
useEffect(() => {
    //Country List
    axios.post('country_list', {
        token: tokenString,
        search: serchText,
    }, { headers: {
           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => { 
        if(response.status==200){
            if(response.data.status==1){
                setCountryList(response.data.data);
            }else{ navigate('/'); } }  })
//Region List
axios.post('region_list', {
    token: tokenString,
    search: serchText,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200){
        if(response.data.status==1){
            setRegionList(response.data.data);
        }else{ navigate('/'); } }  })
//Location List
axios.post('location_list', {
    token: tokenString,
    search: serchText,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200){
        if(response.data.status==1){
            setLocationList(response.data.data);
        }else{ navigate('/'); } }  })

}, []);

 // category Add
 const handleAdd =(e) =>{ 
 axios.post('branch_add', {
    token: tokenString,
    country_id : countryId,
    region_id : regionId,
    location_id : locationId,
    place : place,
    name : name,
    branch_phone : phone,
    branch_email : emailId,
    branch_whatsapp_phone : whatsApp,
    goole_map_location : mapUrl,
    address : address,
}, { headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
    })
.then(response => {
    setErrorMessage(response.message); 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            sessionStorage.setItem('SuccMsg', (response.data.message))
            navigate('/Branches'); }
        if(response.data.status==2){
            setErrorMessage(response.data.message); }
        }
      })
     .catch(error => { setErrorMessage(error.response.message);}); 
    };
    return(
        <>
         <div className="addLocationBlock text centered disBlock">
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Country </label>
                    <select onChange={(e)=>{setCountryId(e.target.value);}}>
                        <option> Select a Country  </option>
                        {countryList.map(data =>{ 
                            return(
                            <option value={data.id} key={data.id}> {data.country_name}  </option>
                            )
                        })}
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Region </label>
                    <select onChange={(e)=>{seRegionId(e.target.value);}}>
                        <option> Select a Region  </option>
                        {regionList.map(data =>{ 
                            if(countryId == data.region_country){
                                return(
                            <option value={data.id} key={data.id}> {data.region_name}  </option>
                                )
                            }
                        })}
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Location </label>
                    <select onChange={(e)=>{setLocationId(e.target.value);}}>
                        <option> Select a Location  </option>
                        {locationList.map(data =>{ 
                            if(regionId == data.location_region){
                                return(
                                <option value={data.id} key={data.id}> {data.location_name}  </option>
                                )
                            }
                        })}
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Branch Name </label>
                    <input type="text" onChange={(e)=>{setName(e.target.value);}} className="widthFull textForm" placeholder="Enter Name" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Place </label>
                    <input type="text" onChange={(e)=>{setPlace(e.target.value);}} className="widthFull textForm" placeholder="Enter Place" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Phone </label>
                    <input type="text" onChange={(e)=>{setPhone(e.target.value);}} className="widthFull textForm" placeholder="Enter Number" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Email </label>
                    <input type="text" onChange={(e)=>{setEmailId(e.target.value);}} className="widthFull textForm" placeholder="Enter mail id" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Whatsapp </label>
                    <input type="text" onChange={(e)=>{setWhatsapp(e.target.value);}} className="widthFull textForm" placeholder="Enter Whatsapp number" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Google map URL </label>
                    <input type="text" onChange={(e)=>{setMapUrl(e.target.value);}} className="widthFull textForm" placeholder="Enter map link" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Address </label>
                    <textarea className='widthFull textFormTa' placeholder='Enter Address' onChange={(e)=>{setAddress(e.target.value);}} ></textarea>
                </div>
                <input onClick={handleAdd} type="submit" value="Add" className="centered disBlock curpoi submitForm"/>
            </div>
        </>
    )
}