import DefautImage from '../images/default-images.jpg';
import axios from '../api/axios';
import * as mainUrl from '../api/const';
import { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
export default function ProfileIcons() {
    const navigate = useNavigate();
    const location = useLocation(); 
    const CurntPage = location.pathname.slice(1);
    const tokenString = sessionStorage.getItem('token');
    const UserProfileName = sessionStorage.getItem('UserProfileName');
    const UserProfileLocation = sessionStorage.getItem('UserProfileLocation');
    const UserProfileImage = sessionStorage.getItem('UserProfileImage');
    const UserRoleName = sessionStorage.getItem('UserRoleName');

    const [showBlock, setShowBlock]= useState('false');
    if(!tokenString){
        navigate('/');
    }
    async function logoutClick () {
        axios.post('admin_logout', {
            token: tokenString
        }, {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
    })
        .then(response => {
            if(response.status==200){
                sessionStorage.clear()
                navigate('/');
            }
            
        })
        .catch(error => {
           
        });
    };
    async function ClientlogoutClick () {
        axios.post('client_user_logout', {
            token: tokenString
        }, {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
    })
        .then(response => {
            if(response.status==200){
                sessionStorage.clear()
                navigate('/ClientLogin');
            }
            
        })
        .catch(error => {});
    };
    const showBlockButn = () => {
        setShowBlock(!showBlock);
    }
    if(CurntPage==='MyDashboard' || CurntPage==='MyTest '){
    return(
        <>
            <span className="settingsIcons curpoi disInline vertAlMiddle posRelative" title="Settings" onClick={showBlockButn}>
                <span className="material-symbols-outlined headericons disInline">settings</span>
                <span className={showBlock ? "posAbsolute hoverAllBlock" : "posAbsolute hoverAllBlock show"}>
                    <span className='anchor disInline curpoi'>
                        <Link to='/Path'> Path List </Link> </span> <br></br>
                        <span className='anchor disInline curpoi'> 
                        <Link to='/Roles'> Roles </Link> </span><br></br>
                    <span className='anchor disInline curpoi'> 
                        <Link to='/Users'> Admin Users </Link> </span>
                </span>
            </span>
            <span className="curpoi notificationIcon disInline vertAlMiddle disInline posRelative">
                <span className="material-symbols-outlined headericons">notifications</span>
                <span className="notificationBadge posAbsolute">03</span>
                <span className="posAbsolute popupBlock">
                    <h3> Notifications </h3>
                    <div className="disBlock blockPop scrollBarCustom widthFull">
                    </div>
                    <span className="material-symbols-outlined posAbsolute closePopup">close</span>
                </span>
            </span>
            <span className="curpoi profileBlock disInline vertAlMiddle posRelative">
                <Link to="/UserProfile">
                <span className="namePlace disInline vertAlMiddle">
                    <h4 className="disInline Name"> {UserProfileName} </h4>
                    <p>  </p>
                </span>
                <span className="disInline profilePicture vertAlMiddle posRelative">
                    <img src={UserProfileImage=='null' ? DefautImage : mainUrl.MediaUrl+UserProfileImage} className="coverCenterImage widthFull heightFull" alt="speciflow"/>
                </span>
                </Link>
            </span>
            <span onClick={ClientlogoutClick} className="material-symbols-outlined headericons vertAlMiddle disInline curpoi" title="Logout"> exit_to_app </span>
    </>
    );
    }else{
        return(
            <>
                <span className="settingsIcons curpoi disInline vertAlMiddle posRelative" title="Settings" onClick={showBlockButn}>
                    <span className="material-symbols-outlined headericons disInline">settings</span>
                    <span className={showBlock ? "posAbsolute hoverAllBlock" : "posAbsolute hoverAllBlock show"}>
                        <span className='anchor disInline curpoi'>
                            <Link to='/Path'> Path List </Link> </span> <br></br>
                            <span className='anchor disInline curpoi'> 
                            <Link to='/Roles'> Roles </Link> </span><br></br>
                        <span className='anchor disInline curpoi'> 
                            <Link to='/Users'> Admin Users </Link> </span>
                    </span>
                </span>
                <span className="curpoi notificationIcon disInline vertAlMiddle disInline posRelative">
                    <span className="material-symbols-outlined headericons">notifications</span>
                    <span className="notificationBadge posAbsolute">03</span>
                    <span className="posAbsolute popupBlock">
                        <h3> Notifications </h3>
                        <div className="disBlock blockPop scrollBarCustom widthFull">
                        </div>
                        <span className="material-symbols-outlined posAbsolute closePopup">close</span>
                    </span>
                </span>
                <span className="curpoi profileBlock disInline vertAlMiddle posRelative">
                    <Link to="/UserProfile">
                    <span className="namePlace disInline vertAlMiddle">
                        <h4 className="disInline Name"> {UserProfileName} </h4>
                        <p> {UserRoleName}, {UserProfileLocation} </p>
                    </span>
                    <span className="disInline profilePicture vertAlMiddle posRelative">
                        <img src={UserProfileImage=='null' ? DefautImage : mainUrl.MediaUrl+UserProfileImage} className="coverCenterImage widthFull heightFull" alt="speciflow"/>
                    </span>
                    </Link>
                </span>
                <span onClick={logoutClick} className="material-symbols-outlined headericons vertAlMiddle disInline curpoi" title="Logout"> exit_to_app </span>
        </>
        );
    }
 }