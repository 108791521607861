import axios from '../api/axios';
import * as mainUrl from '../api/const';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import clientDefaultlogo from '../images/clientDef-logo.png';
export default function ClientUserList(){
    const location = useLocation();
    const CurntPage = location.pathname.slice(1);
    const params = new URLSearchParams(location.search);
    const curntID = params.get("id");
    const curntUserName = params.get("cname");
    const navigate = useNavigate();
    const serchText = params.get("search");
    const tokenString = sessionStorage.getItem('token');
    const[post, setPost]= useState([]);
    const[deletePop, setDeletePop]= useState(false);
    const [deleteId, setDeleteId]= useState();
    const [deleteFirstName, setDeleteFirstName]= useState();
    const showDeletePop= (id, firstName)=> {
      setDeletePop(true);
      setDeleteId(id);
      setDeleteFirstName(firstName);
        }
    //const[popShow, setPopShow]=useState(false);
    const haddleDelete= (e, id)=> {
        axios.post('client_user_delete', {
            token: tokenString,
            client_user_id: deleteId}, {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then(response => { 
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                   // navigate('/Dashboard');
                   window.location.reload(true);
                }else{ navigate('/'); } } })
            }
    useEffect(() => {
        if(serchText){
            axios.post('client_user_list', {
                token: tokenString,
                search: serchText,
                client_id: curntID,
            }, { headers: {
                   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => { 
                if(response.status==200 || response.status==201){
                    if(response.data.status==1){
                        setPost(response.data.data);
                    }else{ navigate('/'); } }  }) 
            }else{
                axios.post('client_user_list', {
                    token: tokenString,
                    client_id: curntID,
                }, { headers: {
                       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => { 
                    if(response.status==200 || response.status==201){
                        if(response.data.status==1){
                            setPost(response.data.data);
                        }else{ navigate('/'); } }  })   
            }
        }, []);
    if (sessionStorage.getItem("token") === null) {
            const tokenString = sessionStorage.getItem('token');
          if(!tokenString){ navigate('/');}
        }else{  
            if(post.length!=0){ 
return(
    <>
    {/* <div className='widthFull succssMsg'>  */}
        {/* <span className="material-symbols-outlined vertAlMiddle disInline">thumb_up</span> {SuccMsgGet}  */}
    {/* </div> */}
    {/* <div className='widthFull posFixed noPadd TtlcountDiv'> Total <b> {posts.length} </b> country added </div> */}
    <div className='Heads centered disFlex flexWrap'>
        <div className='one disFlex aic'>  # </div>
        <div className='two'> Name </div>
        <div className='three'> Contact  </div>
        <div className='four'> Credential </div>
        <div className='five'> Status </div>
        <div className='six'> </div>
    </div>   
    {post.map((data, index) =>{   
            return   (   
               
    <div className={data.status ? 'contents centered disFlex flexWrap' : 'contents centered disFlex flexWrap inactivated'}  key={data.id}>
        <div className='one disFlex aic'> #{index+1} </div>
        <div className='two disFlex aic'> 
            <span className='disInline vertAlMiddle ClinetlogoListImage'>
                <img src={data.photos_image ? mainUrl.MediaUrl+data.photos_image : clientDefaultlogo} className='widthFull coverCenterImage heightFull' alt='mane'></img> 
            </span>
            <span className='disinline'>
                <b> {data.first_name} {data.last_name=="null" ? '' : data.last_name } </b> <br></br>
                {data.gender==0 ? 'Male' : data.gender==1 ? 'Female' : 'Others'}
            </span>
        </div>
        <div className='three disFlex flexWrap aic'> 
            <a href={'tel:'+data.phone} className='disBlock widthFull'> 
                {data.phone}
            </a>
            <a href={'tel:'+data.alternative_phone} className='disBlock widthFull mt05'> 
                {data.alternative_phone=='null'? '' : data.alternative_phone}
            </a>
        </div>
        <div className='four disFlex flexWrap aic'> 
            <a href={'mailto:'+data.email} className='disBlock widthFull'> 
               {data.email}
            </a>
        </div>
        <div className='five disFlex aic'> {data.status ? 'Active' : 'Inactive'} </div>
        <div className='six disFlex aic'> 
            <span className="material-symbols-outlined curpoi" title='Edit'>
                <Link to={'/EditClientUser?id='+data.id}> edit </Link>  
            </span>
            <span onClick={()=>showDeletePop(data.id, data.first_name)} className="material-symbols-outlined delete curpoi" title='Delete'>delete</span>
        </div>
    </div>  
    
        )
    })}
    <div className={deletePop ? 'conformationPopUp disFlex posFixed jcc aic flexWrap show' : 'conformationPopUp disFlex posFixed jcc aic flexWrap' }>
        <div className='contBlock textCenter'>
            <h2> 
                Are you sure  to delete the user <br></br>
                  <span className='disInline vertAlMiddle'> {deleteFirstName}</span>
            </h2>
            <span className='disInline delete curpoi' onClick={haddleDelete}>
                <span className="material-symbols-outlined disInline vertAlMiddle">task_alt</span>
                 Confirm Delete
            </span>
            <span className='disInline vertAlMiddle cancel curpoi' 
            onClick={e=> setDeletePop(false)}> Cancel </span>
        </div>
    </div>
    {serchText? 
        <div className='widthFull mt10 textCenter'>
            <span onClick={(e)=> {navigate('/ClientUser?id='+curntID+'&&cname='+curntUserName);window.location.reload(true);}} className='disInline backSerachBut curpoi centered'> Back to {curntUserName} </span>
        </div> : ""
        }
    </>
); }
else{
    return(
        <div className='disFlex notFountMsg widthFull flexWrap jcc aic'>
            <h4 className='widthFull textCenter'> Sorry! No data Found </h4> 
            <span onClick={(e)=> {navigate('/ClientUser?id='+curntID+'&&cname='+curntUserName);window.location.reload(true);}} className='backtoMainButton disBlock curpoi'> Back to {curntUserName} </span>
        </div>
    )
 }
}
}