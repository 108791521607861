import axios from '../api/axios';
import { useEffect, useState } from 'react';
import * as mainUrl from '../api/const';
import { useNavigate, Link, useLocation } from 'react-router-dom';

export default function RolesList(){
    const location = useLocation();
    const CurntPage = location.pathname.slice(1);
    const params = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const serchText = params.get("search");
    const tokenString = sessionStorage.getItem('token');
    const[post, setPost]= useState([]);
    const[deletePop, setDeletePop]= useState(false);
    const [deleteId, setDeleteId]= useState();
    const [deleteName, setDeleteName]= useState();
    const showDeletePop= (id, clientName)=> {
        setDeletePop(true);
       setDeleteId(id);
       setDeleteName(clientName);
      }
    const haddleDelete= (e, id)=> {
        axios.post('role_delete', {
            token: tokenString,
            role_id: deleteId }, {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then(response => { 
            if(response.status==200){
                if(response.data.status==1){
                   // navigate('/Dashboard');
                   window.location.reload(true);
                }else{ navigate('/'); } } })
    }
    useEffect(() => {
        if(serchText){
            axios.post('role_list', {
                token: tokenString,
                search: serchText,
            }, { headers: {
                   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => { 
                if(response.status==200 || response.status==201){
                    if(response.data.status==1){
                        setPost(response.data.data);
                    }else{ navigate('/'); } }  }) 
            }else{
                axios.post('role_list', {
                    token: tokenString
                }, { headers: {
                       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => { 
                    if(response.status==200 || response.status==201){
                        if(response.data.status==1){
                            setPost(response.data.data);
                        }else{ navigate('/'); } }  })   
            }
    }, []);
    if (sessionStorage.getItem("token") === null) {
        const tokenString = sessionStorage.getItem('token');
          if(!tokenString){ navigate('/');}
        }else{  
        if(post.length!=0){ 
return(
    <>
    <div className='widthFull succssMsg'> 
        {/* <span className="material-symbols-outlined vertAlMiddle disInline">thumb_up</span> {SuccMsgGet}  */}
    </div>
    {/* <div className='widthFull posFixed noPadd TtlcountDiv'> Total <b> {posts.length} </b> country added </div> */}
    <div className='Heads centered disFlex flexWrap'>
        <div className='one disFlex aic'>  # </div>
        <div className='two'> Role Name </div>
        <div className='three'> Branch and Location  </div>
        <div className='four'> Path </div>
        <div className='five'> Status </div>
        <div className='six'> </div>
    </div>  
    {post.map((data, index) =>{   
        return(   
    <div className="contents centered disFlex flexWrap" key={data.id}>
        <div className='one disFlex aic'> #{index+1} </div>
        <div className='two disFlex flexWrap aic'> 
            <span className='widthFull'> <b>  {data.role_name} </b>  </span>
        </div>
        <div className='three disFlex aic flexWrap'><b className='disBlock widthFull'> {data.branch_name}  </b> 
            {data.branch_location_name}, {data.branch_region_name}, {data.branch_country_name}
        </div>
        <div className='four pathViewSpan disFlex aic'>  
            <span className='disInline widthFull'>
            {data.role_connection.map((pathName) =>{ 
                return(
                    <span key={pathName.id}>
                {pathName.path_name}, 
                </span>
             )
            })}
            </span>
        </div>
        <div className='five disFlex aic'> {data.status=='0'? 'Active' : data.status=='1' ? 'Inactive' : 'Hidden'}  </div>
        <div className='six disFlex aic'> 
            <span className="material-symbols-outlined curpoi" title='Edit'>
            <Link to={'/EditRole?id='+data.id}> edit </Link>  
            </span>
            <span className="material-symbols-outlined delete curpoi" title='Delete'  onClick={e=> showDeletePop(data.id, data.role_name)} >delete</span>
        </div>
    </div>  
     )
    })}
    <div className={deletePop ? 'conformationPopUp disFlex posFixed jcc aic flexWrap show' : 'conformationPopUp disFlex posFixed jcc aic flexWrap' }>
        <div className='contBlock textCenter'>
            <h2> 
                Are you sure  to delete the Role <br></br>
                  <span className='disInline vertAlMiddle'> {deleteName} </span>
            </h2>
            <span className='disInline delete curpoi' onClick={haddleDelete}>
                <span className="material-symbols-outlined disInline vertAlMiddle">task_alt</span>
                 Confirm Delete
            </span>
            <span className='disInline vertAlMiddle cancel curpoi' 
            onClick={e=> setDeletePop(false)}> Cancel </span>
        </div>
    </div>
    
    {serchText? <div className='widthFull mt10 textCenter'>
                <span onClick={(e)=> {navigate('/Users');window.location.reload(true);}} className='disInline backSerachBut curpoi centered'> Back to User </span>
             </div> : ""}
    </>
); 
}
else{
    return(
        <div className='disFlex notFountMsg widthFull flexWrap jcc aic'>
            <h4 className='widthFull textCenter'> Sorry! No data related to your search </h4> 
            <span onClick={(e)=> {navigate('/Roles');window.location.reload(true);}} className='backtoMainButton disBlock curpoi'> Back to Users </span>
        </div>
    )
 }
}
}