import './theme/theme.css';
import { Route, Routes } from 'react-router-dom';
import NoInterNet from './NoInternet';
import Dashboard from './Dashboard';
import MyDashboard from './MyDashboard';
import Locations from './Locations';
import AddLocation from './AddLocation';
import Login from './Login';
import Country from './Country';
import AddCountry from './AddCountry';
import EditCountry from './EditCountry';
import Region from './Region';
import AddRegion from './AddRegion';
import EditRegion from './EditRegion';
import EditLocation from './EditLocation';
import Categories from './Categories';
import AddCategory from './AddCategory';
import EditCategory from './EditCategory';
import Branches from './Branches';
import AddBranch from './AddBranch';
import EditBranch from './EditBranch';
import Test from './Test';
import MyTest from './MyTest';
import AddTest from './AddTest';
import EditTest from './EditTest';
import Client from './Client';
import AddClient from './AddClient';
import EditClient from './EditClient';
import ClientUser from './ClientUser';
import AddClientUser from './AddClientUser';
import EditClientUser from './EditClientUser';
import Driver from './Driver';
import AddDriver from './AddDriver';
import EditDriver from './EditDriver';
import Path from './Path';
import AddPath from './AddPath';
import EditPath from './EditPath';
import Roles from './Roles';
import AddRole from './AddRole';
import EditRole from './EditRole';
import Users from './Users';
import AddUser from './AddUser';
import EditUser from './EditUser';
import UserProfile from './UserProfile';
import ClientLogin from './ClientLogin';
import AddMyTest from './AddMyTest';
import EditMyTest from './EditMyTest';
import Requests from './Requests';

export default function App() {
  return (
    <NoInterNet>
      <Routes>
          <Route exact path='/' element={<Login/>} />
          <Route exact path='/Dashboard' element={<Dashboard/>} />
          <Route exact path='/MyDashboard' element={<MyDashboard/>} />
          <Route exact path='/Locations' element={<Locations/>} />
          <Route exact path='/AddLocation' element={<AddLocation/>} />
          <Route exact path='/Country' element={<Country/>} />
          <Route exact path='/AddCountry' element={<AddCountry/>} />
          <Route exact path='/EditCountry' element={<EditCountry/>} />
          <Route exact path='/Region' element={<Region/>} />
          <Route exact path='/AddRegion' element={<AddRegion/>} />
          <Route exact path="/EditRegion" element={<EditRegion/>} />
          <Route exact path='/EditLocation' element={<EditLocation/>}/>
          <Route exact path='/Categories' element={<Categories/>}/>
          <Route exact path='/AddCategory' element={<AddCategory/>}/>
          <Route exact path='/EditCategory' element={<EditCategory/>}/>
          <Route exact path='/Branches' element={<Branches/>}/>
          <Route exact path='/AddBranch' element={<AddBranch/>}/>
          <Route exact path='/EditBranch' element={<EditBranch/>}/>
          <Route exact path='/Test' element={<Test/>}/>
          <Route exact path='/MyTest' element={<MyTest/>}/>
          <Route exact path='/AddTest' element={<AddTest/>}/>
          <Route exact path='/EditTest' element={<EditTest/>}/>
          <Route exact path='/Client' element={<Client/>}/>
          <Route exact path='/AddClient' element={<AddClient/>}/>
          <Route exact path='/EditClient' element={<EditClient/>}/>
          <Route exact path='/ClientUser' element={<ClientUser/>}/>
          <Route exact path='/AddClientUser' element={<AddClientUser/>}/>
          <Route exact path='/EditClientUser' element={<EditClientUser/>}/>
          <Route exact path='/Driver' element={<Driver/>}/>
          <Route exact path='/AddDriver' element={<AddDriver/>}/>
          <Route exact path='/EditDriver' element={<EditDriver/>}/>
          <Route exact path='/Path' element={<Path/>}/>
          <Route exact path='/AddPath' element={<AddPath/>}/>
          <Route exact path='/EditPath' element={<EditPath/>}/>
          <Route exact path='/Roles' element={<Roles/>}/>
          <Route exact path='/AddRole' element={<AddRole/>}/>
          <Route exact path='/EditRole' element={<EditRole/>}/>
          <Route exact path='/Users' element={<Users/>}/>
          <Route exact path='/AddUser' element={<AddUser/>}/>
          <Route exact path='/EditUser' element={<EditUser/>}/> 
          <Route exact path='/UserProfile' element={<UserProfile/>}/> 
          <Route exact path='/ClientLogin' element={<ClientLogin/>}/> 
          <Route exact path='/AddMyTest' element={<AddMyTest/>}/> 
          <Route exact path='/EditMyTest' element={<EditMyTest/>}/>
          <Route exact path='/Requests' element={<Requests/>}/>
          
      </Routes>
     </NoInterNet>
  );
}
