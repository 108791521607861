import LogoBlock from './Logo';
import Navigation from './Navigations';
import ProfileIcons from './Profileicon';
import { useEffect, useState } from 'react';
export default function Header() {
  const [loadingBlock, setLoadingBlock] = useState(false);
  useEffect(() => {
    setLoadingBlock(true);
    setTimeout(() => {
      setLoadingBlock(false);
    }, 100);
  }, []);

  return (
    <>
    {loadingBlock ? <section className="posFixed disFlex jcc aic loadingBlock">
        <span className="loadingBlockSpan posRelative">
          <span className="image widthFull heightFull posAbsolute z1"></span>
      </span>
      </section> : '' }
    <section className="widthFull disFlex flexWrap mainHeader"> 
        <LogoBlock/>
        <div className="mainnavigations">
            <Navigation/>
        </div>
        <div className="profileicons">
            <ProfileIcons/>
        </div>
    </section>
    </>
  )
}

