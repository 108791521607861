
// import axios from './api/axios';
import { useEffect, useState } from 'react';
export default function DashBoardTest () {
    const [CurHour, setCurHour] = useState();
    const [CurMnt, setCurMnt] = useState();
    const [CurSec, setCurSec] = useState();
    const [CurTFTime, setCurTFTime] = useState();
    const UserProfileName = sessionStorage.getItem('UserProfileName');
    const date = new Date();
    const MonthName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',  'September', 'October', 'November', 'December'];
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const day = weekday[date.getDay()];
    const fullDate = date.getDate() + ' ' + MonthName[date.getMonth()] + ' ' + date.getFullYear();
    setTimeout(() => {
        setCurTFTime(date.getHours());
        setCurHour(date.getHours()% '12');
        setCurMnt(date.getMinutes());
        setCurSec(date.getSeconds());
    }, 600);
    return (
        <section className="disFlex flexWrap widthFull dashboardBlock centered">
            <div className="disFlex flexWrap widgetBlock posRelative testCarry">
                <h2 className="disInline widthFull"> Welcome {UserProfileName}!  </h2> 
                <span className="disInline mainTimeSpan widthFull"> 
                    <span className='disInline a'> 
                        {CurHour < 9 ? '0'+CurHour : CurHour}:
                        {CurMnt < 9 ? '0'+CurMnt : CurMnt}:
                        {CurSec < 9 ? '0'+CurSec : CurSec} 
                    </span>
                    <span className='disInline b'> {CurTFTime < 12 ? ' AM' : ' PM'} </span>
                </span>
                <span className='widthFull dateSpan disBlock'> {day}, {fullDate} </span>
            </div>
        </section>
    );
  }

