import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

export default function RequestsList(){
    const location = useLocation();
    const CurntPage = location.pathname.slice(1);
    const params = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const serchText = params.get("search");
    const tokenString = sessionStorage.getItem('token');
    const[post, setPost]= useState([]);
    const[createdDate, setCreatedDate]= useState([]);
    const[viewPopup, setViewPopup]= useState([]);
    const[createdDateShow, setCreatedDateShow]= useState('');
    const[deletePop, setDeletePop]= useState(false);
    const[drivername, setDriverName]= useState('');
    const[driverPhone, setDriverPhone]= useState('');
    const [deleteId, setDeleteId]= useState();
    const[popShow, setPopShow]=useState(false);
    const[popStatusShow, setPopStatusShow]=useState(false);
    const[popStatusNow, setPopStatusNow]=useState('');

    // const [deleteName, setDeleteName]= useState();
    // const showDeletePop= (id, perName)=> {
    //     setDeletePop(true);
    //     setDeleteId(id);
    //     setDeleteName(perName);
    //   }
    const viewPopClick =(e, id) => {
        setPopShow(true);
        axios.post('test_view', {
            token: tokenString,
            request_id: id }, {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then(response => { 
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                    setViewPopup(response.data.data);
                    response.data.data.forEach(setTestCon=>{
                        setTestCon.request_test_item.forEach(setTestConB=>{
                            setCreatedDateShow(setTestConB.created_on);
                            })
                    })
                }else{ navigate('/'); } } })
    }

    const viewStatusPopClick = (e, id) =>{
        setPopStatusShow(true);
        axios.post('test_view', {
            token: tokenString,
            request_id: id }, {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then(response => { 
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                    setViewPopup(response.data.data);
                    setPopStatusNow(response.data.data[0].test_status)
                    //test_status_value
                    response.data.data.forEach(setTestCon=>{
                        setTestCon.request_test_item.forEach(setTestConB=>{
                            setCreatedDateShow(setTestConB.created_on);
                            })
                    })
                }else{ navigate('/'); } } })
    }
     // Update
 const handleStatusUpdate =(e, id) =>{
    const formData = new FormData();
    formData.append("token", tokenString);
    formData.append("request_id", id);
    formData.append("status", popStatusNow);
    axios.post('request_change_status', formData)
    .then(response => {
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                sessionStorage.setItem('SuccMsg', (response.data.message))
                window.location.reload(true);
            }
            if(response.data.status==2){
               
            }
        }
    })
    .catch(error => { 
    });
    }
    const handleIndvStatusUpdate =(e, id, indvStatus) =>{
        const formData = new FormData();
        formData.append("token", tokenString);
        formData.append("request_id", id);
        formData.append("status", indvStatus);
        axios.post('test_change_status', formData)
        .then(response => {
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                    sessionStorage.setItem('SuccMsg', (response.data.message))
                    alert('Successfully Updated');
                    window.location.reload(true);
                }
                if(response.data.status==2){
                   
                }
            }
        })
        .catch(error => { 
        });
        }
    // const haddleDelete= (e, id)=> {
    //     axios.post('test_delete', {
    //         token: tokenString,
    //         request_id: deleteId, 
    //         }, {
    //         headers: {
    //            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
    //     .then(response => { 
    //         if(response.status==200 || response.status==201){
    //             if(response.data.status==1){
    //                window.location.reload(true);
    //             }else{ navigate('/'); }}})
    // }
    useEffect(() => {

        if(serchText){
            axios.post('test_list', {
                token: tokenString,
                search: serchText,
            }, { headers: {
                   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => { 
                if(response.status==200 || response.status==201){
                    if(response.data.status==1){
                        setPost(response.data.data);                        
                    }else{ navigate('/'); } }}) 
            }else{
                axios.post('test_list', {
                    token: tokenString
                }, { headers: {
                       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => { 
                    if(response.status==200 || response.status==201){
                        if(response.data.status==1){
                            setPost(response.data.data);
                            setDriverName(response.data.data[0].driver_name);
                            setDriverPhone(response.data.data[0].driver_phone);
                        }else{ navigate('/'); } }  })   
            }
    }, []);
    if (sessionStorage.getItem("token") === null) {
        const tokenString = sessionStorage.getItem('token');
          if(!tokenString){ navigate('/');}
        }else{  
        if(post.length!=0){ 
return(
    <>
    <div className='widthFull succssMsg'> 
        {/* <span className="material-symbols-outlined vertAlMiddle disInline">thumb_up</span> {SuccMsgGet}  */}
    </div>
    {/* <div className='widthFull posFixed noPadd TtlcountDiv'> Total <b> {posts.length} </b> country added </div> */}
    <div className='Heads centered disFlex flexWrap'>
        <div className='one disFlex aic'>  # </div>
        <div className='two'> Patients </div>
        <div className='three'> Client </div>
        <div className='four'> Driver </div>
        <div className='five'> Status </div>
        <div className='six'> </div>
    </div>   
    {post.map((data, index) =>{   
            return   (   
    <div className='contents centered posRelative disFlex flexWrap' key={data.id}>
        <div className='one disFlex aic'> #{index+1} </div>
        <div className='two disFlex flexWrap aic'> 
            <b className='disBlock widthFull'> {data.patients_name} </b> 
            {data.dob}, {data.gender == '0' ? 'Male' : data.gender == '1' ? 'Female' : 'Others'}
        </div>
        <div className='three disFlex flexWrap aic'> 
            <span className='disBlock widthFull'>  {data.clients_name} </span>
            <span className='viewTestButton disInline curpoi' onClick={e=> viewPopClick(e, data.id)}> View Test </span>
        </div>
        <div className='four disFlex flexWrap aic'>  
            {data.driver_name} <br></br>
            {data.driver_phone}
        </div>
        <div className='five disFlex flexWrap aic'> 
            <div className='disBlock noPadd mb05 widthFull'>
                <span className='disInline driverStatus'> {data.test_status_value} </span>
            </div>
            <span className='viewTestButton disInline curpoi' onClick={e=> viewStatusPopClick(e, data.id)}> Change Status </span> 
        </div>
        <div className='six disFlex aic'> 
            {/* <span className="material-symbols-outlined curpoi" title='Edit'>
                <Link to={'/EditMyTest?id='+data.id}> edit </Link>  
            </span>
            <span  onClick={e=> showDeletePop(data.id, data.patients_name)} className="material-symbols-outlined delete curpoi" title='Delete'>delete</span> */}
        </div>
    </div>  
        )
    })}
    {viewPopup.map((popdata, index) =>{   
            return   (   
    <div className={popShow ? 'testViewBlock comnBlockPop jcc aic posFixed show': 'testViewBlock jcc aic posFixed' } key={popdata.id}>
        <div className='content disFlex jcsb flexWrap posRelative show'>
            <span onClick={e=> setPopShow(false)} className='posAbsolute material-symbols-outlined curpoi closeTestView' title='Close'>close</span>
            <div className='block'>
                <span className='disBlock mb05'>
                    <p> Patients </p>
                    <h5> {popdata.patients_name} </h5>
                </span>
                <span className='disBlock mb05'>
                    <p>  Date of Birth & Gender </p>
                    <h5> {popdata.dob}, {popdata.gender == '0' ? 'Male' : popdata.gender == '1' ? 'Female' : 'Others'} </h5>
                </span>
                <span className='disBlock mb05'>
                    <p> Driver Name </p>
                    <h5> {drivername} </h5>
                </span>
                <span className='disBlock mb05'>
                    <p> Driver Phone </p>
                    <h5> {driverPhone} </h5>
                </span>
            </div>   
            <div className='block'>
                {popdata.request_test_item.map((tstdata, index) =>{   
                    return   (
                <div className='widthFull noPadd disFlex jcsb flexWrap posRelative blockOftest' key={tstdata.id}>
                    <span className='posAbsolute CountTesti'>
                        #{index+1}
                    </span>
                    <span className='disBlock mb05'>
                        <p>  Test Item </p>
                        <h5> {tstdata.test_item_name} </h5>
                    </span>
                    <span className='disBlock mb05'>
                        <p>Barcode</p>
                        <h5> {tstdata.barcode} </h5>
                    </span>
                    <div className="block posRelative  changeStatusSelectBox">
                        <select value={tstdata.test_item_status} onChange={(e) => {if(window.confirm('Confirm to change this status?')){handleIndvStatusUpdate(e, tstdata.id, e.target.value)};}}>
                            <option value='0'> Driver Waiting </option>
                            <option value='1'> Driver Accepted </option>
                            <option value='2'> Driver Picked </option>
                            <option value='3'> Received at Lab </option>
                            <option value='4'> Processing </option>
                            <option value='5'> Hold </option>
                            <option value='6'> Pending </option>
                            <option value='7'> Return </option>
                            <option value='8'> Cancelled </option>
                        </select>
                        <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                    </div>
                </div>
                )
                })}
            </div>
            {/* <span className='disBlock widthFull createdDate textCenter'> 
            Posted on: {createdDateShow}, {agoDateTime} </span> */}
        </div>
    </div>
     )
    })}
    
    {viewPopup.map((popSatusData, index) =>{   
            return   (  
    <div className={popStatusShow ? 'statusViewBlock comnBlockPop jcc aic posFixed show' : 'statusViewBlock comnBlockPop jcc aic posFixed' } key={popSatusData.id}>
        <div className='content disFlex jcsb flexWrap posRelative'>
            <span onClick={e=> setPopStatusShow(false)} className='posAbsolute material-symbols-outlined curpoi closeTestView' title='Close'>close</span>
            <div className='block'>
                <span className='disBlock mb05'>
                    <p> Patients </p>
                    <h5>  {popSatusData.patients_name} </h5>
                </span>
                <span className='disBlock mb05'>
                    <p>  Date of Birth & Gender </p>
                    <h5> {popSatusData.dob}, {popSatusData.gender == '0' ? 'Male' : popSatusData.gender == '1' ? 'Female' : 'Others'} </h5>
                </span>
                <span className='disBlock mb05'>
                    <p> Driver Name </p>
                    <h5> {drivername} </h5>
                </span>
                <span className='disBlock mb05'>
                    <p> Driver Phone </p>
                    <h5> {driverPhone} </h5>
                </span>
            </div>   
            <div className='block'>
                <div className='widthFull noPadd disFlex jcsb flexWrap posRelative blockOftest'>
                    <h4 className='widthFull'> Change Status </h4>
                    <div className="block posRelative centered changeStatusSelectBox">
                        <select value={popStatusNow} onChange={(e)=>{setPopStatusNow(e.target.value);}}>
                            <option key='0' value='0'> Driver Waiting </option>
                            <option key='1' value='1'> Driver Accepted </option>
                            <option key='2' value='2'> Driver Picked </option>
                            <option key='3' value='3'> Received at Lab </option>
                            <option key='4' value='4'> Processing </option>
                            <option key='5' value='5'> Hold </option>
                            <option key='6' value='6'> Pending </option>
                            <option key='7' value='7'> Return </option>
                            <option key='8' value='8'> Cancelled </option>
                        </select>
                        <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                    </div>
                    <input type='submit' onClick={e=> handleStatusUpdate(e, popSatusData.id)} value='Update' className='disInline changeStatusBut'></input>
                </div>
            </div>
            {/* <span className='disBlock widthFull createdDate textCenter'> 
            Posted on: {createdDateShow}, {agoDateTime} </span> */}
        </div>
    </div>
        )
    })}

{/* <div className={deletePop ? 'conformationPopUp disFlex posFixed jcc aic flexWrap show' : 'conformationPopUp disFlex posFixed jcc aic flexWrap' }>
        <div className='contBlock textCenter'>
            <h2> 
                Are you sure  to delete the patient <br></br>
                  <span className='disInline vertAlMiddle'> {deleteName} </span>
            </h2>
            <span className='disInline delete curpoi' onClick={haddleDelete}>
                <span className="material-symbols-outlined disInline vertAlMiddle">task_alt</span>
                 Confirm Delete
            </span>
            <span className='disInline vertAlMiddle cancel curpoi' 
            onClick={e=> setDeletePop(false)}> Cancel </span>
        </div>
    </div> */}
        {serchText? <div className='widthFull mt10 textCenter'>
                <span onClick={(e)=> {navigate('/Requests');window.location.reload(true);}} className='disInline backSerachBut curpoi centered'> Back to Requests </span>
             </div> : ""}
    </>
); }
else{
    return(
        <div className='disFlex notFountMsg widthFull flexWrap jcc aic'>
            <h4 className='widthFull textCenter'> Sorry! No data related to your search </h4> 
            <span onClick={(e)=> {navigate('/Requests');window.location.reload(true);}} className='backtoMainButton disBlock curpoi'> Back to Requests </span>
        </div>
    )
 }

}
}