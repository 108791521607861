import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
export default function TestAdd(){
    const tokenString = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const curntID = params.get("id");
     const serchText = params.get("search");
    const [BlankFormMsg, setBlankFormMsg] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [patientName, setPatientName ] = useState('');
    const [gender, setGender ] = useState('');
    const [dob, setDob] = useState('');
    const [demoTest, setDemoTest] = useState('');

    const [chkSelCategory, setChkSelCategory] = useState([]);
    const [chkSelSubCategory, setChkSelSubCategory] = useState([]);
    const [post, setPost] = useState([]);
    const [testListData, setTestListData] = useState([]);
    const [testDataGet, settestDataGet] = useState([]);
    const [testCategory, setTestCategory] = useState([]);
    const [testSubcategory, setTestSubcategory] = useState([]);
    const [tests, setTests] = useState([]);
    const [ChkTestCategory, setChkTestCategory] = useState([]);
    const [ChkTestSubcategory, setChkTestSubcategory] = useState([]);
    const [ChkTests, setChkTests] = useState([]);

    useEffect(() => {
        axios.post('test_type_list', {
            token: tokenString,
        }, { headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => { 
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                    setTestListData(response.data.data);
                    response.data.data.forEach((testDataView)=>{
                        if(testDataView.parent_category==""){
                            //setChkSelCategory(testDataView.category);
                        if (!ChkTestCategory.includes(testDataView.category)) {
                            ChkTestCategory.push(testDataView.category);
                            testCategory.push({prntCatName: testDataView.category_name, prntCatId: testDataView.category});
                          }
                        }
                        if(testDataView.parent_category){
                            if (!ChkTestCategory.includes(testDataView.parent_category_id)) {
                                ChkTestCategory.push(testDataView.parent_category_id);
                                testCategory.push({prntCatName: testDataView.parent_category, prntCatId: testDataView.parent_category_id});
                              }

                            if (!ChkTestSubcategory.includes(testDataView.category_name)) {
                                ChkTestSubcategory.push(testDataView.category_name);
                                testSubcategory.push({catName: testDataView.category_name, catId:testDataView.category, parnCatId:testDataView.parent_category_id, prntCatName:testDataView.parent_category});
                              }
                            }
                        if (!ChkTests.includes(testDataView.test_name)) {
                            ChkTests.push(testDataView.test_name);
                            tests.push({name: testDataView.test_name, id:testDataView.id,spp:testDataView.special_price, mrp:testDataView.mrp_price, catId:testDataView.category, parnCatId:testDataView.parent_category_id, categName:testDataView.category_name, prntCategName: testDataView.parent_category });
                            }
                               
                    })  
                }else{ navigate('/ClientLogin'); } }  })

                    //Test View
                axios.post('test_view', {
                    token: tokenString,
                    request_id: curntID,
                }, { headers: {
                       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => { 
                    if(response.status==200 || response.status==201){
                        if(response.data.status==1){
                            setPost(response.data.data);
                            setPatientName (response.data.data[0].patients_name);
                            setGender(response.data.data[0].gender);
                            setDob(response.data.data[0].dob);
                            response.data.data[0].request_test_item.forEach((tedView)=>{
                                if(tedView.parent_category==""){
                                    if (!chkSelCategory.includes(tedView.category_id)) {
                                        chkSelCategory.push(tedView.category_id);
                                      }
                                }
                                else{
                                    if (!chkSelSubCategory.includes(tedView.category_id)) {
                                        chkSelSubCategory.push(tedView.category_id);
                                      }
                                      if (!chkSelCategory.includes(tedView.category_id)) {
                                        chkSelCategory.push(tedView.category_id);
                                      }
                                      if (!chkSelCategory.includes(tedView.parent_category_id)) {
                                        chkSelCategory.push(tedView.parent_category_id);
                                      }


                                }

                                if (!testDataGet.includes(tedView.test_item)) {
                                    testDataGet.push(tedView.test_item);
                                  }

                            })
                        }else{ navigate('/ClientLogin'); } }  })
    }, []);//
    
    const haddleShowSub =(e, id)=>{
        if (!chkSelCategory.includes(id)) {
            chkSelCategory.push(id);
            setDemoTest([]);
          }else{
            var index = chkSelCategory.indexOf(id);
            chkSelCategory.splice(index, 1);
            setDemoTest(id);
            testSubcategory.forEach((sabCatDataloop)=>{
                if(sabCatDataloop.parnCatId==id){                
                if (chkSelCategory.includes(sabCatDataloop.catId)) {
                     var index = chkSelCategory.indexOf(sabCatDataloop.catId);
                    chkSelCategory.splice(index, 1);
                }
                if (chkSelSubCategory.includes(sabCatDataloop.catId)) {
                    var indexsub = chkSelSubCategory.indexOf(sabCatDataloop.catId);
                    chkSelSubCategory.splice(indexsub, 1);
               }
            }

            })
            tests.forEach((testLoopRemove)=>{
                if(testLoopRemove.catId==id){
                    if(testDataGet.includes(testLoopRemove.id)){
                        var indexsuC = testDataGet.indexOf(testLoopRemove.id);
                        testDataGet.splice(indexsuC, 1);
                    }
                }
            })
          }
       // this.state(e.target.checked)
    } 

    const haddleShowSubCat =(e, id)=>{
        if (!chkSelCategory.includes(id)) {
            chkSelCategory.push(id);
            chkSelSubCategory.push(id);
            setDemoTest([]);
          }else{
            var index = chkSelCategory.indexOf(id);
            chkSelCategory.splice(index, 1);
            setDemoTest(id);
            var indexSub = chkSelSubCategory.indexOf(id);
            chkSelSubCategory.splice(indexSub, 1);
            testSubcategory.forEach((sabCatDataloop)=>{
                if(sabCatDataloop.parnCatId==id){                
                if (chkSelCategory.includes(sabCatDataloop.catId)) {
                     var index = chkSelCategory.indexOf(sabCatDataloop.catId);
                    chkSelCategory.splice(index, 1);
                }
            }

            })
            tests.forEach((testLoopRemove)=>{
                if(testLoopRemove.catId==id){
                    if(testDataGet.includes(testLoopRemove.id)){
                        var indexsuC = testDataGet.indexOf(testLoopRemove.id);
                        testDataGet.splice(indexsuC, 1);
                    }
                }
            })
          }
        } 
    const haddleShowTest =(e, id)=>{
        if (!testDataGet.includes(id)) {
            testDataGet.push(id);
            setDemoTest([]);
          }else{
            var index = testDataGet.indexOf(id);
            testDataGet.splice(index, 1);
            setDemoTest(id);
          }
        }
    
    const handleUpdate =(e)=>{
        const formData = new FormData();
        formData.append("token", tokenString);
        formData.append("patients_name", patientName);
        formData.append("gender", gender);
        formData.append("dob", dob);
        formData.append("request_id", curntID);
        formData.append("test_id", testDataGet);
        axios.post('test_update', formData)
        .then(response => {
            setErrorMessage(response.message); 
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                    sessionStorage.setItem('SuccMsg', (response.data.message))
                    navigate('/MyTest'); }
                if(response.data.status==2){
                    setErrorMessage(response.data.message); }
                }
              })
             .catch(error => { setErrorMessage(error.response.message);}); 
    }
    return(
        <>
        <div className='widthFull disFlex flexWrap jcsb addMyTestBlock'>
            <div className="addLocationBlock text centered disBlock">
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Patient Name </label>
                    <input type="text" value={patientName} className="widthFull textForm" placeholder="Enter Name"  onChange={(e)=>{setPatientName(e.target.value);}}/>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Gender b </label>
                    <select value={gender} onChange={(e)=>{setGender(e.target.value);}}>
                        <option value="150"> Select a Gender  </option>
                         <option value="0"> Male  </option> 
                         <option value="1"> Female  </option>
                         <option value="2"> Others  </option>
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Date of birth </label>
                    <input type="date" value={dob} className="widthFull textForm" placeholder="Enter Date"  onChange={(e)=>{setDob(e.target.value);}}/>
                </div>
            </div>
            <div className="addLocationBlock scrollBarCustom noPadd testSelectBlock jcsb text centered disFlex flexWrap">
              <div className='widthFull blockSeen disFlex flexWrap jcsb centered'>
                <div className='one blockView'>
                    <ul>
                    {testCategory.map(testList =>{ 
                        if(chkSelCategory.includes(testList.prntCatId)){
                                return(
                            <li className='vertAlMiddle' onClick={(e)=>haddleShowSub(e, testList.prntCatId)} key={testList.prntCatId}> 
                            <span className='activeSpanTestAdd'></span>
                            {/* <input type='checkbox' value={testList.prntCatId} className='disInline checkBoxMyTest vertAlMiddle' checked></input> */}
                            {testList.prntCatName} </li>
                                )
                                }else{
                                    return(
                                        <li className='vertAlMiddle' onClick={(e)=>haddleShowSub(e, testList.prntCatId)} key={testList.prntCatId}> 
                                        {/* <input type='checkbox' className='disInline checkBoxMyTest vertAlMiddle'></input> */}
                                        {testList.prntCatName} </li>
                                        )
                                    }
                            })}
                    </ul>  
                </div>
                <div className='two blockView'>
                    <ul>
                    {testSubcategory.map(testSubList =>{ 
                        if(chkSelCategory.includes(testSubList.parnCatId)){
                            if(chkSelSubCategory.includes(testSubList.catId)){
                                return(
                        <li onClick={(e)=>haddleShowSubCat(e, testSubList.catId)} key={testSubList.catId}> 
                            <span className='activeSpanTestAdd'></span>
                            {testSubList.catName} 
                                <span className='disInline widthFull categorySowAddTest'>
                                    {testSubList.prntCatName}
                                </span>
                            </li>
                             )
                            }else{
                                return(
                                    <li key={testSubList.catId} onClick={(e)=>haddleShowSubCat(e, testSubList.catId)}> 
                                        {/* <input type='checkbox' className='disInline checkBoxMyTest vertAlMiddle'></input> */}
                                        {testSubList.catName} 
                                            <span className='disInline widthFull categorySowAddTest'>
                                            {testSubList.prntCatName}
                                            </span>
                                        </li>
                                         )
                                    }
                                }
                            })}
                       
                    </ul>
                </div>
                <div className='three blockView'>
                    <ul>
                    {tests.map(testallList =>{
                            if(chkSelCategory.includes(testallList.catId)){
                                if(testDataGet.includes(testallList.id)){
                                return(
                            <li onClick={(e)=>haddleShowTest(e, testallList.id)} key={testallList.id}> 
                            <span className='activeSpanTestAdd'></span>
                            {testallList.name}
                                <span className='disInline widthFull categorySowAddTest'>
                                    {testallList.prntCategName ? testallList.prntCategName+' -> ' : ' ' }
                                        {testallList.categName}  
                                </span>
                            </li>
                             )
                            }else{
                                return(
                                    <li onClick={(e)=>haddleShowTest(e, testallList.id)}  key={testallList.id}> 
                                    {/* <input type='checkbox' className='disInline checkBoxMyTest vertAlMiddle'></input> */}
                                    {testallList.name}
                                        <span className='disInline widthFull categorySowAddTest'>
                                        {testallList.prntCategName ? testallList.prntCategName+' -> ' : ' ' }
                                        {testallList.categName}
                                        </span>
                                    </li>
                                     )
                                    }
                                }
                            })}
                    </ul>
                </div>
              </div>
            </div>
            <input type="Submit" defaultValue ="Update"  onClick={handleUpdate}  className="centered disBlock curpoi submitForm"/>
        </div>
        </>
    )
}