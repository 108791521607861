import axios from '../api/axios';
import * as mainUrl from '../api/const';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import clientDefaultlogo from '../images/clientDef-logo.png';

export default function ClientList(){
    const location = useLocation();
    const CurntPage = location.pathname.slice(1);
    const params = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const serchText = params.get("search");
    const tokenString = sessionStorage.getItem('token');
    const[post, setPost]= useState([]);
    const[viewPopup, setViewPopup]= useState([]);
    const[deletePop, setDeletePop]= useState(false);
    const [deleteId, setDeleteId]= useState();
   const [deleteclientName, setDeleteclientName]= useState();
    const showDeletePop= (id, clientName)=> {
        setDeletePop(true);
       setDeleteId(id);
       setDeleteclientName(clientName);
      }

    const[popShow, setPopShow]=useState(false);
    //alert(popShow)

    const viewPopClick =(e, id) => {
        setPopShow(true);
        axios.post('client_view', {
            token: tokenString,
            client_id: id }, {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then(response => { 
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                    setViewPopup(response.data.data);
                   //window.location.reload(true);
                }else{ navigate('/'); } } })
    }

    const haddleDelete= (e, id)=> {
        axios.post('client_delete', {
            token: tokenString,
            client_id: deleteId }, {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then(response => { 
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                   // navigate('/Dashboard');
                   window.location.reload(true);
                }else{ navigate('/'); } } })
    }
    useEffect(() => {
        if(serchText){
            axios.post('client_list', {
                token: tokenString,
                search: serchText,
            }, { headers: {
                   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => { 
                if(response.status==200 || response.status==201){
                    if(response.data.status==1){
                        setPost(response.data.data);
                    }else{ navigate('/'); } }  }) 
            }else{
                axios.post('client_list', {
                    token: tokenString,
                }, { headers: {
                       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => { 
                    if(response.status==200 || response.status==201){
                        if(response.data.status==1){
                            setPost(response.data.data);
                        }else{ navigate('/'); } }  })   
            }
    }, []);
    if (sessionStorage.getItem("token") === null) {
        const tokenString = sessionStorage.getItem('token');
          if(!tokenString){ navigate('/');}
        }else{  
        if(post.length!=0){ 
return(
    <>
    <div className='widthFull succssMsg'> 
        {/* <span className="material-symbols-outlined vertAlMiddle disInline">thumb_up</span> {SuccMsgGet}  */}
    </div>
    {/* <div className='widthFull posFixed noPadd TtlcountDiv'> Total <b> {posts.length} </b> country added </div> */}
    <div className='Heads centered disFlex flexWrap'>
        <div className='one disFlex aic'>  # </div>
        <div className='two'> Name </div>
        <div className='three'> Contact  </div>
        <div className='four'> Point of Contact </div>
        <div className='five'> Status </div>
        <div className='six'> </div>
    </div>   
    {post.map((data, index) =>{   
            return   (   
    <div className={data.status ? 'contents centered disFlex flexWrap' : 'contents centered disFlex flexWrap inactivated'} key={data.id}>
        <div className='one disFlex aic'> #{index+1} </div>
        <div className='two disFlex aic'> 
            <span className='disInline vertAlMiddle ClinetlogoListImage'>
                <img src={data.logo ? mainUrl.MediaUrl+data.logo : clientDefaultlogo} className='widthFull coverCenterImage heightFull' alt='mane'></img> 
            </span>
            {data.client_name} </div>
        <div className='three disFlex flexWrap aic'> 
            <a href={'tel:'+data.client_phone} className='disBlock widthFull'> {data.client_phone}  </a> <br></br>
            <a href={'mailto:'+data.client_email} className='disBlock widthFull'> {data.client_email} </a>
            
        </div>
        <div className='four disFlex flexWrap aic'> 
            {data.point_of_contact} <br></br>
            <a href={'tel:'+data.point_of_contact_number} className='disBlock widthFull'> {data.point_of_contact_number}  </a>
        </div>
        <div className='five disFlex aic'> {data.status ? 'Active' : 'Inactive'} </div>
        <div className='six disFlex aic'> 
            <span className="material-symbols-outlined disInline vertAlMiddle posRelative" title={'Users '+data.user_count}>
                <span className='userCount posAbsolute'>{data.user_count < 9 ? '0'+data.user_count : data.user_count}</span>
                <Link to={"/ClientUser?id="+data.id+'&&cname='+data.client_name}> group </Link> </span> 
            <span onClick={e=> viewPopClick(e, data.id)} className="material-symbols-outlined curpoi" title='View'>
                 visibility  
            </span>
            <span className="material-symbols-outlined curpoi" title='Edit'>
                <Link to={'/EditClient?id='+data.id}> edit </Link>  
            </span>
            <span onClick={e=> showDeletePop(data.id, data.client_name)} className="material-symbols-outlined delete curpoi" title='Delete'>delete</span>
        </div>
    </div>  
        )
    })}
    <div className={deletePop ? 'conformationPopUp disFlex posFixed jcc aic flexWrap show' : 'conformationPopUp disFlex posFixed jcc aic flexWrap' }>
        <div className='contBlock textCenter'>
            <h2> 
                Are you sure  to delete the Client <br></br>
                  <span className='disInline vertAlMiddle'> {deleteclientName} </span>
            </h2>
            <span className='disInline delete curpoi' onClick={haddleDelete}>
                <span className="material-symbols-outlined disInline vertAlMiddle">task_alt</span>
                 Confirm Delete
            </span>
            <span className='disInline vertAlMiddle cancel curpoi' 
            onClick={e=> setDeletePop(false)}> Cancel </span>
        </div>
    </div>
    {serchText? 
        <div className='widthFull mt10 textCenter'>
            <span onClick={(e)=> {navigate('/Client');window.location.reload(true);}} className='disInline backSerachBut curpoi centered'> Back to Client </span>
        </div> : ""
        }
     
    <div className={popShow ? 'popUpView disFlex flexWrap show' : "popUpView disFlex flexWrap"}>
        {viewPopup.map((data) =>{ return   (  
        <div className='contentDiv posRelative disFlex flexWrap scrollBarCustom' key={data.id}>
            <div className='left block'>
                <div className='carry'>
                    {data.logo ? 
                        <div className='blks widthFull'>
                            <img src={mainUrl.MediaUrl+data.logo} className='ClinetlogoImage' alt={data.client_name} ></img>                  
                        </div>
                        : ''} 
                    <div className='blks widthFull'>
                        <span className='disInline widthFull label'>
                            Client
                        </span>
                        <h4> {data.client_name} </h4>
                    </div>
                    <div className='blks widthFull'>
                        <span className='disInline widthFull label'>
                            Country
                        </span>
                        <h4> {data.client_country} </h4>
                    </div>
                    <div className='blks widthFull'>
                        <span className='disInline widthFull label'>
                            Location
                        </span>
                        <h4> {data.client_location} </h4>
                    </div>
                    <div className='blks widthFull'>
                        <span className='disInline widthFull label'>
                            Phone
                        </span>
                        <h4> {data.client_phone} </h4>
                    </div>
                    <div className='blks widthFull'>
                        <span className='disInline widthFull label'>
                           Email
                        </span>
                        <h4> {data.client_email} </h4>
                    </div>
                    <div className='blks widthFull'>
                        <span className='disInline widthFull label'>
                            Contact Person
                        </span>
                        <h4> {data.point_of_contact} </h4>
                    </div>
                    <div className='blks widthFull'>
                        <span className='disInline widthFull label'>
                            Contact Person Phone
                        </span>
                        <h4> {data.point_of_contact_number} </h4>
                    </div>
                </div>
            </div>
            <div className='right block'>
                <div className='blks widthFull'>
                        <span className='disInline widthFull label'>
                            Address
                        </span>
                        <h4> {data.client_address} </h4>
                    </div>
                <div className='blks widthFull'>
                    <span className='disInline widthFull label'>
                        In Date
                    </span>
                    <h4> {data.client_in_date} </h4>
                </div>
                <div className='blks widthFull'>
                    <span className='disInline widthFull label'>
                        Branch 
                    </span>
                    <h4> {data.branch_name} </h4>
                </div>
                <div className='blks widthFull'>
                    <span className='disInline widthFull label'>
                        Branch Country
                    </span>
                    <h4> {data.branch_country_name} </h4>
                </div>
                <div className='blks widthFull'>
                    <span className='disInline widthFull label'>
                        Branch Region
                    </span>
                    <h4> {data.branch_region_name} </h4>
                </div>
                <div className='blks widthFull'>
                    <span className='disInline widthFull label'>
                        Branch Location
                    </span>
                    <h4> {data.branch_location_name} </h4>
                </div>
                <div className='blks widthFull'>
                    <span className='disInline widthFull label'>
                        Status
                    </span>
                    <h4> {data.status ? 'Active' : 'Inactive'} </h4>
                </div>
                <div className='blks widthFull'>
                   <Link to={'/EditClient?id='+data.id} className='editViewButn vertAlMiddle disInline'> 
                        <span className="material-symbols-outlined disInline vertAlMiddle curpoi" title='Edit'>  edit </span>
                    Edit </Link>  
                    <span onClick={e=> showDeletePop(data.id, data.client_name)} className="material-symbols-outlined vertAlMiddle disInline deletePop curpoi" title='Delete'>delete</span>
                </div>
            </div>
            <span title='Close' onClick={()=>setPopShow(false)} className="material-symbols-outlined closePopup posAbsolute ">close</span>
        </div>
         )
        })}
    </div>
    </>
); }
else{
    return(
        <div className='disFlex notFountMsg widthFull flexWrap jcc aic'>
            <h4 className='widthFull textCenter'> Sorry! No data Found </h4> 
            <span onClick={(e)=> {navigate('/Client');window.location.reload(true);}} className='backtoMainButton disBlock curpoi'> Back to Client </span>
        </div>
    )
 }

}
}