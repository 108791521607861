import {Link, useLocation}from 'react-router-dom';
export default function Navigation(){
    const location = useLocation(); 
    const CurntPage = location.pathname.slice(1);
    if(CurntPage=='MyDashboard' || CurntPage=='MyTest' || CurntPage=='AddMyTest' || CurntPage=='EditMyTest' ){ 
    return(
        <>
        <span className="disInline posRelative"> 
            <Link to="/MyDashboard"> Dashboard </Link> </span> <br/>
        <span className="disInline posRelative"> 
            <Link to="/MyTest"> Tests </Link> </span> <br/>
        </>
    );
    }else{
        return(
            <>
            <span className="disInline posRelative"> 
                <Link to="/Dashboard"> Dashboard </Link> </span> <br/>
            <span className="disInline posRelative"> 
                <Link to="/Locations">  Locations </Link> </span> <br/>
            <span className="disInline posRelative"> 
                <Link to="/Categories"> Categories </Link> </span> <br/>
            <span className="disInline posRelative"> 
                <Link to="/Branches"> Branches </Link> </span> <br/>
            <span className="disInline posRelative"> 
                <Link to="/Test"> Tests </Link> </span> <br/>
            <span className="disInline posRelative"> 
                <Link to="/Client"> Clients </Link> </span> <br/><br/>
            <span className="disInline posRelative"> 
                <Link to="/Driver"> Driver </Link> </span> <br/><br/>
            <span className="disInline posRelative"> 
                <Link to="/Requests"> Requests </Link> </span> <br/><br/>
            </>
        );
    }
}