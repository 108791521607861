import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
export default function AddCountryPage(){
    const location = useLocation();
    const CurntPage = location.pathname.slice(1);
    const[country, setcountry]= useState('');
    const[region, setRegion]= useState('');
    const[shortCode, SetShortCode]= useState('');
    const[teleCode, SetTeleCode]= useState('');
    const[countryCode, SetCountryCode]= useState('');
    const[locationName, setLocation]= useState('');
    const[regionCode, SetRegionCode]= useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [BlankFormMsg, setBlankFormMsg] = useState('');
    const[posts, setPost]= useState([]);
    const[postsRgn, setPostRgn]= useState([]);
    const tokenString = sessionStorage.getItem('token');
    const handleAdd =(e) =>{
        setErrorMessage();
if(CurntPage=='AddCountry'){
        if (country.length == 0 || shortCode.length == 0 || teleCode.length == 0 ) {
            setBlankFormMsg('Input file missing');
            return }
        axios.post('country_add', {
            token: tokenString,
            country_name:country, 
            country_sort_code:shortCode, 
            country_telephone_code:teleCode,
        }, { headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
            })
        .then(response => {
            setErrorMessage(response.message); 
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                    sessionStorage.setItem('SuccMsg', (response.data.message))
                    navigate('/Country');
                }
                if(response.data.status==2){
                    setErrorMessage(response.data.message);
                }
            }
        })
        .catch(error => {
            setErrorMessage(error.response.message); 
        });
 }//end of add country
 if(CurntPage=='AddRegion'){
    if (countryCode == "" || region.length == 0 || shortCode.length == 0 ) {
        setBlankFormMsg('Input file missing');
        return }
    axios.post('region_add', {
        token: tokenString,
        country_id:countryCode,
        region_name:region,
        region_sort_code:shortCode, 
    }, { headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    .then(response => {
        setErrorMessage(response.message); 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                sessionStorage.setItem('SuccMsg', (response.data.message))
                navigate('/Region');
            }
            if(response.data.status==2){
                setErrorMessage(response.data.message);
            }
        }
    })
    .catch(error => {
        setErrorMessage(error.response.message); 
    });
}//end of add Region

if(CurntPage=='AddLocation'){
    if (countryCode == "" || regionCode =="" || locationName.length == 0 || shortCode.length == 0 ) {
        setBlankFormMsg('Input file missing');
        return }
    axios.post('location_add', {
        token: tokenString,
        location_name:locationName,
        location_sort_code:shortCode, 
        country_id:countryCode,
        region_id:regionCode,
    }, { headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    .then(response => {
        setErrorMessage(response.message); 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                sessionStorage.setItem('SuccMsg', (response.data.message))
                navigate('/Locations');
            }
            if(response.data.status==2){
                setErrorMessage(response.data.message);
            }
        }
    })
    .catch(error => {
        setErrorMessage(error.response.message); 
    });
}//end of add Region
      };//

    useEffect(() => {
      if(CurntPage=='AddRegion' || CurntPage=='AddLocation'){
        axios.post('country_list', {
            token: tokenString
        }, { headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => { 
            if(response.status==200){
                if(response.data.status==1){
                    setPost(response.data.data);
                }else{ navigate('/'); } }  }) 
     }//end of add coutry list
     if(CurntPage=='AddLocation'){
        axios.post('region_list', {
            token: tokenString
        }, { headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => { 
            if(response.status==200){
                if(response.data.status==1){
                    setPostRgn(response.data.data);
                }else{ navigate('/'); } }  }) 
     }//end of add Region
    }, []);

      function setToken(userToken) {
        sessionStorage.setItem('token', (userToken))
      } const navigate = useNavigate();
      if (sessionStorage.getItem("token") === null) {
      const tokenString = sessionStorage.getItem('token');
        if(!tokenString){ navigate('/');}
      }else{ const tokenString = sessionStorage.getItem('token');

if(CurntPage=='AddCountry'){ 
    return(
        <>
        {BlankFormMsg &&  <div className="errorBlock"> {BlankFormMsg} </div>}
        {errorMessage &&  <div className="error errorBlock"> {errorMessage} </div>}
        <div className="addLocationBlock text centered disBlock">
            <div className="block centered posRelative">
                <label className="widthFull disInline"> Country </label>
                <input type="text" onChange={(e) =>{ setcountry(e.target.value);}} className="widthFull textForm" placeholder="Enter Country"/>
            </div>
            <div className="block centered posRelative">
                <label className="widthFull disInline"> Short Code </label>
                <input type="text" onChange={(e) =>{ SetShortCode(e.target.value); }} className="widthFull textForm" placeholder="Enter Code"/>
            </div>
            <div className="block centered posRelative">
                <label className="widthFull disInline"> Telephone Code </label>
                <input type="num" onChange={(e) =>{ SetTeleCode(e.target.value);}} className="widthFull textForm" placeholder="Enter Code"/>
            </div>
            <input onClick={handleAdd} type="submit" className="centered disBlock curpoi submitForm" placeholder="Enter Location"/>
        </div>
        </>
        )
    }//End of AddCountry

    if(CurntPage=='AddRegion'){ 
        return(
            <>
            {BlankFormMsg &&  <div className="errorBlock"> {BlankFormMsg} </div>}
            {errorMessage &&  <div className="error errorBlock"> {errorMessage} </div>}
            <div className="addLocationBlock text centered disBlock">
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Country </label>
                    <select onChange={(e) =>{ SetCountryCode(e.target.value); }}>
                    <option> Select a Country </option>
                    {posts.map(data =>{ 
                        return(
                        <option value={data.id} key={data.id}>  {data.country_name} </option>
                        )
                    })}
                    </select>
                    <span className="material-symbols-outlined posAbsolute arrowSelect">keyboard_arrow_down</span>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Region </label>
                    <input type="text" onChange={(e)=>{setRegion(e.target.value);}}  className="widthFull textForm" placeholder="Enter Region"/>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Short Code </label>
                    <input type="text" onChange={(e)=>{SetShortCode(e.target.value)}} className="widthFull textForm" placeholder="Enter Code"/>
                </div>
                <input onClick={handleAdd} type="submit" className="centered disBlock curpoi submitForm" placeholder="Enter Location"/>
            </div>
            </>
            )
        }//End of add region

        if(CurntPage=='AddLocation'){ 
            return(
                <>
                {BlankFormMsg &&  <div className="errorBlock"> {BlankFormMsg} </div>}
                {errorMessage &&  <div className="error errorBlock"> {errorMessage} </div>}
        <div className="addLocationBlock disBlock centered vertAltop">
            <div className="block posRelative">
                <label className="widthFull disInline"> Country </label>
                <select onChange={(e) =>{ SetCountryCode(e.target.value); }}>
                    <option> Select a Country </option>
                    {posts.map(data =>{ 
                        return(
                        <option value={data.id} key={data.id}>  {data.country_name} </option>
                        )
                    })}
                 </select>
                <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
            </div>
            <div className="block posRelative">
                <label className="widthFull disInline"> Region </label>
                <select onChange={(e) =>{ SetRegionCode(e.target.value); }}>
                    <option> Select a Region </option>
                    {postsRgn.map(dataRgn =>{ 
                        if(countryCode == dataRgn.region_country){
                            return(
                                <option key={dataRgn.id} value={dataRgn.id}>  {dataRgn.region_name} </option>
                            )
                        }
                    })}
                 </select>
                <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
            </div>
            <div className="block posRelative">
                <label className="widthFull disInline"> Location </label>
                <input type="text" className="widthFull textForm" onChange={(e)=>{setLocation(e.target.value)}} placeholder="Enter Location"/>
            </div>
            <div className="block posRelative">
                <label className="widthFull disInline"> Short Code </label>
                <input type="text" className="widthFull textForm" onChange={(e)=>{SetShortCode(e.target.value)}} placeholder="Enter Shoer Code"/>
            </div>
            <input onClick={handleAdd} type="submit" className="widthFull curpoi submitForm" placeholder="Enter Location"/>

        </div>
                </>
                )
            }//End of add region

    }}