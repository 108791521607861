import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function EditLocation(){
    const location = useLocation();
    const params = new URLSearchParams(location.search)
    const curntID = params.get("id");

    const navigate = useNavigate();
    const SuccMsgGet = sessionStorage.getItem('SuccMsg');
    const tokenString = sessionStorage.getItem('token');

    const[post, setPost]= useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [name, setName] = useState('');
    const [region, setRegion] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [regionCode, setRegionCode] = useState('');
    const [country, setCountry] = useState('');
    const [shortCode, setShortCode] = useState('');
    const [status, setStatus] = useState('');

    const [regionList, SetRegionList] = useState([]);
    const [countrylist, SetCountryList] = useState([]);


    const handleUpdate =(e) =>{
        axios.post('location_update', {
            token: tokenString,
            location_id: curntID,
            location_name: name,
            location_sort_code: shortCode,
            country_id: countryCode,
            region_id: regionCode ,
            location_status: status,
        }, {
                headers: {
                   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
        })
        .then(response => {
            setErrorMessage(response.message); 
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                    sessionStorage.setItem('SuccMsg', (response.data.message))
                    navigate('/Locations');
                }
                if(response.data.status==2){
                    setErrorMessage(response.data.message);
                }
            }
        })//
    }


    useEffect(() => {
        //Location View
        axios.post('location_view', {
            token: tokenString,
            location_id : curntID,
        }, { headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => { 
            if(response.status==200){
                if(response.data.status==1){
                    setPost(response.data.data);  
                    setName(response.data.data[0].location_name);
                    setRegion(response.data.data[0].location_region_name);  
                    setRegionCode(response.data.data[0].location_region);
                    setCountry(response.data.data[0].location_country_name); 
                    setCountryCode(response.data.data[0].location_country); 
                    setShortCode(response.data.data[0].location_sort_code); 
                    setStatus(response.data.data[0].location_status);  
                }else{ navigate('/'); } 
                    }  }) 

        // Region
        axios.post('region_list', {
            token: tokenString
        }, { headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => { 
            if(response.status==200){
                if(response.data.status==1){
                    SetRegionList(response.data.data);
            }else{ navigate('/'); } }  })
            
            
        //Country
        axios.post('country_list', {
            token: tokenString
        }, { headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => { 
            if(response.status==200){
                if(response.data.status==1){
                    SetCountryList(response.data.data);
                }else{ navigate('/'); } }  }) 

    }, []);
    return(
        <>
        <p> {errorMessage}  </p>
          {post.map((data) => { 
            return   (    
          <div className="addLocationBlock disBlock centered vertAltop" key={data.id}>
              <h4 className='textCenter textUppercase'> Edit {data.location_name} </h4> 
              <div className="block centered posRelative">
                  <label className="widthFull disInline"> Country </label>
                  <select value={countryCode} onChange={(e) =>{setCountryCode(e.target.value);}}>
                    {countrylist.map((listCountry) => { 
                        return(
                        <option key={listCountry.id} value={listCountry.id}> {listCountry.country_name} </option>
                        )
                    })}
                </select>
              </div>
              <div className="block centered posRelative">
                  <label className="widthFull disInline"> Region </label>
                    <select value={regionCode} onChange={(e) =>{setRegionCode(e.target.value);}}>
                    {regionList.map((rerions)=>{
                        if(countryCode == rerions.region_country){
                        return(
                        <option key={rerions.id} value={rerions.id}> {rerions.region_name}  </option>     
                        )
                        }
                    })}
                    </select>
              </div>
              <div className="block centered posRelative">
                  <label className="widthFull disInline"> Location </label>
                  <input type="text" value={name} onChange={(e)=>setName(e.target.value)}  className="widthFull textForm"/>
              </div>
              <div className="block centered posRelative">
                  <label className="widthFull disInline"> Short Code </label>
                  <input type="text" value={shortCode} onChange={(e)=>setShortCode(e.target.value)}  className="widthFull textForm"/>
              </div>
              <div className="block centered  posRelative">
                  <label className="widthFull disInline"> Status {status} </label>
                  <input type="checkbox" checked={status} onChange={(e)=>setStatus(e.target.checked)} className="disInline checkBt textForm" />
                  Active
              </div>
              <input onClick={handleUpdate} type="submit" value="Update" className="centered disBlock curpoi submitForm"/> <br></br>
              <div className='widthFull textCenter'>
              <span onClick={(e) =>{navigate('/Locations');}} className='disInline cancelEditBut curpoi textCenter'> 
                  <span className="material-symbols-outlined disInline vertAlMiddle">close</span>
                  Cancel Edit </span>
              </div>
          </div>
             ) 
             })}
          </>
    )
}