//import EditPage from './components/EditPage';
import LocationEdit from './components/LocationEdit';
import Header from './components/Header';
import PageHeader from './components/PageHeader';
export default function EditLocation(){
    return(
        <>
         <Header/>
         <section className="disFlex flexWrap widthFull dashboardBlock centered">
            <div className="disFlex flexWrap pageHeader widthFull aic">
                <PageHeader/>
            </div>
            <div className="pageContainer scrollBarCustom location centered disBlock">
                <LocationEdit/>
            </div>
        </section>
        </>
    )
}