import Header from './components/Header';
import DashBoardTest from './components/DashBoardTest'

import { useNavigate } from 'react-router-dom';
export default function MyDashboard(){
    //const UserProfileName = sessionStorage.getItem('UserProfileName');
    const navigate = useNavigate();
    if (sessionStorage.getItem("token") === null) {
      const tokenString = sessionStorage.getItem('token');
        if(!tokenString){
            navigate('/ClientLogin');
         }
      }
    else{   
        
    return(
      <> <Header/>
        <DashBoardTest/>
      </>
    );
}
}
