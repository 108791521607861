import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
export default function PathEdit(){
    const tokenString = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const[post, setPost]= useState([]);
    const[pathList, setPathList]= useState([]);
    const params = new URLSearchParams(location.search);
    const curntID = params.get("id");
     const serchText = params.get("search");
    const [BlankFormMsg, setBlankFormMsg] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [pathName, setPathName] = useState('');
    const [parentId, setParentId] = useState();
    const [superParentId, setSuperParentId] = useState('');
    const [parentName, setParentName] = useState('');
    const [superParentName, setSuperParentName] = useState('');
    const [pathLink, setPathLink] = useState('');
    const [status, setStatus] = useState('');
    
    // category List
useEffect(() => {
    //path list
    axios.post('path_list', {
        token: tokenString,
    }, { headers: {
           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => { 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                setPathList(response.data.data);
            }else{ navigate('/'); } }  })
            // path view
    axios.post('path_view', {
        token: tokenString,
        path_id: curntID,
    }, { headers: {
           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => { 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                setPost(response.data.data);
                setPathName(response.data.data[0].path_name);
                if((response.data.data[0].parent)==null){setParentId('0');}
                else{setParentId(response.data.data[0].parent);}
                if((response.data.data[0].super_parent)==null){setSuperParentId('0');}
                else{setSuperParentId(response.data.data[0].super_parent);}
                setPathLink(response.data.data[0].path_link);
                setSuperParentName(response.data.data[0].super_parent_name);
                setParentName(response.data.data[0].parent_name);
                setStatus(response.data.data[0].status);
            }else{ navigate('/'); } }  })
}, []);

 // Update
 const handleUpdate =(e) =>{
    const formData = new FormData();
    formData.append("token", tokenString);
    formData.append("path_id", curntID);
    formData.append("path_name", pathName);
    formData.append("parent", parentId);
    formData.append("super_parent", superParentId);
    formData.append("path_link", pathLink);
    formData.append("status", status);
    axios.post('path_edit', formData)
    .then(response => {
        setErrorMessage(response.message); 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                sessionStorage.setItem('SuccMsg', (response.data.message))
                navigate('/Path');
            }
            if(response.data.status==2){
                setErrorMessage(response.data.message);
            }
        }
    })
    .catch(error => {
        setErrorMessage(error.response.message); 
    });
    }
    return(
        <>
         <div className="addLocationBlock text centered disBlock">
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Path Name </label>
                    <input type="text" value={pathName} onChange={(e)=>{setPathName(e.target.value);}} className="widthFull textForm" placeholder="Enter Path Name" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Path Link </label>
                    <input type="text" value={pathLink} onChange={(e)=>{setPathLink(e.target.value);}} className="widthFull textForm" placeholder="Enter Path Link" />
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Parent </label>
                    <select  value={parentId} onChange={(e)=>{setParentId(e.target.value);}}>
                        <option value='0'> No parent  </option>
                        {pathList.map(data =>{ 
                            if(data.parent == null){
                            return(
                                <option value={data.id} key={data.id}> {data.path_name}  </option>
                                )
                                }
                            })
                        }
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Super Parent </label>
                    <select value={superParentId} onChange={(e)=>{setSuperParentId(e.target.value);}}>
                        <option value="0"> No Super parent  </option>
                        {pathList.map(data =>{ 
                            if(data.parent == null){
                            return(
                                <option value={data.id} key={data.id+2}> {data.path_name}  </option>
                                )
                                }
                            })
                        }
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Status </label>
                    <select value={status} onChange={(e)=>{setStatus(e.target.value);}} key={status}>
                        <option value=''> Select a status </option>
                        <option value='0'> Active </option>
                        <option value='1'> Inactive </option>
                        <option value='2'> Hidden </option>
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <input onClick={handleUpdate} type="submit" className="centered disBlock curpoi submitForm" placeholder="Enter Location" value='Update'/>
            </div>
        </>
    )
}