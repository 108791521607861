import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import * as mainUrl from '../api/const';
export default function DriverEdit(){
    const tokenString = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const curntID = params.get("id");
     const serchText = params.get("search");
    const [BlankFormMsg, setBlankFormMsg] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const[countryList, setCountryList]= useState([]);
    const[regionList, setRegionList]= useState([]);
    const[locationList, setLocationList]= useState([]);
    const[branchList, setBranchList]= useState([]);
    const[driverView, setDriverView]= useState([]);

    const [countryId, setCountryId] = useState('');
    const [regionId, setRegionId] = useState('');
    const [locationId, setLocationId] = useState('');
    const [branchId, setBranchId] = useState('');
    const [photo, setPhoto] = useState('');
    const [Firstname, setFirstName] = useState('');
    const [phone, setPhone] = useState('');
    const [emailId, setEmailId] = useState('');
    const [altPhone, setAltPhone] = useState('');
    const [lastName, setLastName] = useState('');
    const [dobDate, setDobDate] = useState('');
    const [genderId, setGenderId] = useState('');
    const [maritalId, setMaritalId] = useState('');
    const [vehicleNumber, setVehicleNumber] = useState('');
    const [status, setStatus] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);
    const [passwordShowIcon, setPasswordShowIcon] = useState(false);
    const [fileChenge, setFileChenge] = useState('1');

    
useEffect(() => {
    //Country List
    axios.post('country_list', {
        token: tokenString,
    }, { headers: {
           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => { 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                setCountryList(response.data.data);
            }else{ navigate('/'); } }  })
//Region List
axios.post('region_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setRegionList(response.data.data);
        }else{ navigate('/'); } }  })
//Location List
axios.post('location_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setLocationList(response.data.data);
        }else{ navigate('/'); } }  })

//Branch List
axios.post('branch_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setBranchList(response.data.data);
        }else{ navigate('/'); } }  })

//Driver View
axios.post('driver_view', {
    token: tokenString,
    driver_id: curntID,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setDriverView(response.data.data);
            setCountryId(response.data.data[0].branch_country);
            setRegionId(response.data.data[0].branch_region);
            setLocationId(response.data.data[0].branch_location);
            setBranchId(response.data.data[0].branch);
            setFirstName(response.data.data[0].first_name);
            setPhoto(response.data.data[0].photos_image);
            setLastName(response.data.data[0].last_name);
            setGenderId(response.data.data[0].gender);
            setMaritalId(response.data.data[0].marital);
            setPhone(response.data.data[0].phone);
            setAltPhone(response.data.data[0].alternative_phone);
            setEmailId(response.data.data[0].email);
            setVehicleNumber(response.data.data[0].vehicle);
            setDobDate(response.data.data[0].dob);
            setStatus(response.data.data[0].status);
        }else{ navigate('/'); } }  })

}, []);
const handdleFile =(e)=>{
    setFileChenge(URL.createObjectURL(e.target.files[0])); setPhoto((e.target.files[0]));
}
 // category Add
 const handleUpdate =(e) =>{ 
    const formData = new FormData();
    formData.append("token", tokenString);
    formData.append("branch_id", branchId);
    formData.append("driver_id", curntID);
    formData.append("country_id", countryId);
    formData.append("region_id", regionId);
    formData.append("location_id", locationId);
    formData.append("email", emailId);
    formData.append("first_name", Firstname);
    formData.append("last_name", lastName);
    formData.append("phone", phone);
    formData.append("gender", genderId);
    formData.append("alternative_phone", altPhone);
    formData.append("photos_image", photo);
    formData.append("marital", maritalId);
    formData.append("dob", dobDate);
    formData.append("vehicle", vehicleNumber);
    formData.append("status", status);
    axios.post('driver_update', formData)
    .then(response => {
        setErrorMessage(response.message); 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                sessionStorage.setItem('SuccMsg', (response.data.message))
                navigate('/Driver');
            }
            if(response.data.status==2){
                setErrorMessage(response.data.message);
            }
        }
    })
    .catch(error => {
        setErrorMessage(error.response.message); 
    });
};

const togglePasswordShow = () => {
    setPasswordShow(!passwordShow);
    setPasswordShowIcon(!passwordShowIcon)
  };
    return(
        <>
         <div className="addLocationBlock text centered disBlock">
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Country </label>
                    <select value={countryId} onChange={(e)=>{setCountryId(e.target.value);}}>
                        <option> Select a Country  </option>
                        {countryList.map(data =>{ 
                            return(
                            <option value={data.id} key={data.id}> {data.country_name}  </option>
                            )
                        })}
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Region </label>
                    <select value={regionId} onChange={(e)=>{setRegionId(e.target.value);}}>
                        <option> Select a Region  </option>
                        {regionList.map(data =>{ 
                            if(countryId == data.region_country){
                                return(
                            <option value={data.id} key={data.id}> {data.region_name}  </option>
                                )
                            }
                        })}
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Location </label>
                    <select value={locationId} onChange={(e)=>{setLocationId(e.target.value);}}>
                        <option> Select a Location  </option>
                        {locationList.map(data =>{ 
                            if(regionId == data.location_region){
                                return(
                                <option value={data.id} key={data.id}> {data.location_name}  </option>
                                )
                            }
                        })}
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Branch </label>
                    <select value={branchId} onChange={(e)=>{setBranchId(e.target.value);}}>
                        <option> Select a Branch  </option>
                        {branchList.map(data =>{ 
                            if(locationId == data.branch_location){
                                return(
                                <option value={data.id} key={data.id}> {data.name}  </option>
                                )
                             }
                        })}
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Photo </label>
                    <input type="file" onChange={handdleFile} className="widthFull textForm" placeholder="Photo" />
                    <span className='posAbsolute nowImageShow disFlex jcc aic'>
                        {photo ? fileChenge == '1' ?  
                        <img src={mainUrl.MediaUrl+photo}  className='disBlock widthFull coverCenterImage heightFull' alt=''></img> 
                        :  <img src={fileChenge}  className='disBlock widthFull coverCenterImage heightFull' alt=''></img> : "No Image Chosen"}
                    </span>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> First Name </label>
                    <input type="text" defaultValue={Firstname} onChange={(e)=>{setFirstName(e.target.value);}} className="widthFull textForm" placeholder="Enter Name" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Last Name </label>
                    <input type="text" defaultValue={lastName} onChange={(e)=>{setLastName(e.target.value);}} className="widthFull textForm" placeholder="Enter Name" />
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Gender </label>
                    <select defaultValue={genderId} onChange={(e)=>{setGenderId(e.target.value);}}>
                        <option> Select a Gender  </option>
                        <option value='0'> Male  </option>
                        <option value='1'> Female  </option>
                        <option value='2'> Others  </option>
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Marital status </label>
                    <select defaultValue={maritalId} onChange={(e)=>{setMaritalId(e.target.value);}}>
                        <option> Select a Status  </option>
                        <option value='0'>  Single </option>
                        <option value='1'> Married  </option>
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Phone </label>
                    <input defaultValue={phone} type="text" onChange={(e)=>{setPhone(e.target.value);}} className="widthFull textForm" placeholder="Enter Phone" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Alternative Phone </label>
                    <input defaultValue={altPhone} type="text" onChange={(e)=>{setAltPhone(e.target.value);}} className="widthFull textForm" placeholder="Enter Phone" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Email </label>
                    <input defaultValue={emailId} type="Email" onChange={(e)=>{setEmailId(e.target.value);}} className="widthFull textForm" placeholder="Enter mail id" />
                </div>
                {/* <div className="block centered posRelative">
                    <label className="widthFull disInline"> Password </label>
                    <input type={passwordShow ? "text" : 'password'} onChange={(e)=>{setPassword(e.target.value);}} className="widthFull textForm" placeholder="Enter password" />
                    <span className="material-symbols-outlined posAbsolute showPasswordBut" onClick={togglePasswordShow}>{passwordShowIcon ? 'visibility' : 'visibility_off'}</span>
                </div> */}
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Vehicle Number </label>
                    <input defaultValue={vehicleNumber} type="text" onChange={(e)=>{setVehicleNumber(e.target.value);}} className="widthFull textForm" placeholder="Enter Number (Ex: KL10XY3345)" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Date of Birth </label>
                    <input defaultValue={dobDate} type="Date" onChange={(e)=>{setDobDate(e.target.value);}} className="widthFull textForm" placeholder="Enter Date" />
                </div>
                <div className="block centered  posRelative">
                    <label className="widthFull disInline"> Status </label>
                    <input type="checkbox" className="disInline checkBt textForm" checked={status} onChange={(e)=>setStatus(e.target.checked)} />
                        Active
                </div>
                <input onClick={handleUpdate} type="submit" defaultValue="Update" className="centered disBlock curpoi submitForm"/>
            </div>
        </>
    )
}