import AddPage from './components/AddPage';
import Header from './components/Header';
import PageHeader from './components/PageHeader';
export default function AddLocation(){
    return(
        <>
         <Header/>
         <section className="disFlex flexWrap widthFull dashboardBlock centered">
            <div className="disFlex flexWrap pageHeader widthFull aic">
                <PageHeader/>
            </div>
            <div className="pageContainer scrollBarCustom location centered disBlock">
                <AddPage/>
            </div>
        </section>
        </>
    )
}