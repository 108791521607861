import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import * as mainUrl from '../api/const';
export default function UserEdit(){
    const tokenString = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const curntID = params.get("id");
     const serchText = params.get("search");
    const [BlankFormMsg, setBlankFormMsg] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);
    const [passwordShowIcon, setPasswordShowIcon] = useState(false);

    const[countryList, setCountryList]= useState([]);
    const[regionList, setRegionList]= useState([]);
    const[locationList, setLocationList]= useState([]);
    const[branchList, setBranchList]= useState([]);
    const[roleList, setRoleList]= useState([]);

    const [countryId, setCountryId] = useState('');
    const [regionId, setRegionId] = useState('');
    const [locationId, setLocationId] = useState('');
    const [branchId, setBranchId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [genderID, setGenderId] = useState('');
    const [maritalId, setMaritalId] = useState('');
    const [phone, setPhone] = useState('');
    const [dob, setDob] = useState('');
    const [emailId, setEmailId] = useState('');   
    const [password, setPassword] = useState('');  
    const [ClientId, setClientId] = useState(''); 
    const [userImage, setUserImage] = useState(''); 
    const [bloodGroup, setBloodGroup] = useState(''); 
    const [roleId, setRoleId] = useState('');
    const [fileChenge, setFileChenge] = useState('1');
    const [status, setStatus] = useState('');
    
    
    
useEffect(() => {
    //setClientId(curntID)
     //Country List
axios.post('country_list', {
    token: tokenString,
}, { headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setCountryList(response.data.data);
        }else{ navigate('/'); } }  })

//Region List
axios.post('region_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setRegionList(response.data.data);
        }else{ navigate('/'); } }  })

//Location List
axios.post('location_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setLocationList(response.data.data);
        }else{ navigate('/'); } }  })

//Branch List
axios.post('branch_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setBranchList(response.data.data);
        }else{ navigate('/'); } }  })

//Role List
axios.post('role_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setRoleList(response.data.data);
        }else{ navigate('/'); } }  })

//Admin View
axios.post('admin_view', {
    token: tokenString,
    user_id: curntID,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setCountryId(response.data.data[0].branch_country);
            setRegionId(response.data.data[0].branch_region);
            setLocationId(response.data.data[0].branch_location);
            setBranchId(response.data.data[0].branch);
            setUserImage(response.data.data[0].photos_image);
            setGenderId(response.data.data[0].gender);
            setMaritalId(response.data.data[0].marital);
            setFirstName(response.data.data[0].first_name);
            setLastName(response.data.data[0].last_name);
            setPhone(response.data.data[0].phone);
            setDob(response.data.data[0].dob);
            setBloodGroup(response.data.data[0].blood_group);
            setEmailId(response.data.data[0].email);
            if(setRoleId(response.data.data[0].user_role)==null){setRoleId('0')}
            else{setRoleId(response.data.data[0].user_role);}
            setStatus(response.data.data[0].is_active);
        }else{ navigate('/'); } }  })

}, []);
const handdleImageFile =(e)=>{
    setFileChenge(URL.createObjectURL(e.target.files[0])); setUserImage((e.target.files[0]));
}

 // category Add
 const handleUpdate =(e) =>{ 
    const formData = new FormData();
    formData.append("token", tokenString);
    formData.append("user_id", curntID);
    formData.append("email", emailId);
    formData.append("password", password);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("phone", phone);
    formData.append("token", tokenString);
    formData.append("branch_country", countryId);
    formData.append("branch_region", regionId);
    formData.append("branch_location", locationId);
    formData.append("branch", branchId);
    formData.append("blood_group", bloodGroup);
    formData.append("dob", dob);
    formData.append("marital", maritalId);
    formData.append("gender", genderID);
    formData.append("photos_image", userImage);
    formData.append("user_role", roleId);
    formData.append("is_active", status);
 axios.post('admin_update', formData)
.then(response => {
    setErrorMessage(response.message); 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            sessionStorage.setItem('SuccMsg', (response.data.message))
            navigate('/Users'); }
        if(response.data.status==2){
            setErrorMessage(response.data.message); }
        }
      })
     .catch(error => { setErrorMessage(error.response.message);}); 

    };
    const togglePasswordShow = () => {
        setPasswordShow(!passwordShow);
        setPasswordShowIcon(!passwordShowIcon)
      };
    return(
        <>
         <div className="addLocationBlock text RoleAdd jcsb centered disFlex flexWrap">
         <div className='CarryBlock left'>
            <div className="block posRelative centered">
                <label className="widthFull disInline"> Country </label>
                <select value={countryId} onChange={(e)=>{setCountryId(e.target.value);}}>
                    {countryList.map(data =>{ 
                        return(
                            <option value={data.id} key={data.id}> {data.country_name}  </option>
                        )
                    })}
                </select>
                <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
            </div>
            <div className="block posRelative centered">
                <label className="widthFull disInline"> Region </label>
                <select value={regionId} onChange={(e)=>{setRegionId(e.target.value);}}>
                    {regionList.map(data =>{ 
                        if(countryId == data.region_country){
                            return(
                        <option value={data.id} key={data.id}> {data.region_name}  </option>
                            )
                        }
                    })}
                </select>
                <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
            </div>
            <div className="block posRelative centered">
                <label className="widthFull disInline"> Location </label>
                <select value={locationId} onChange={(e)=>{setLocationId(e.target.value);}}>
                    <option> Select a Location  </option>
                    {locationList.map(data =>{ 
                        if(regionId == data.location_region){
                            return(
                                <option value={data.id} key={data.id}> {data.location_name}  </option>
                            )
                        }
                    })}
                </select>
                <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
            </div>
            <div className="block posRelative centered">
                <label className="widthFull disInline"> Branch </label>
                <select value={branchId} onChange={(e)=>{setBranchId(e.target.value);}}>
                    <option> Select a Branch  </option>
                    {branchList.map(data =>{ 
                        if(locationId == data.branch_location){
                            return(
                            <option value={data.id} key={data.id}> {data.name}  </option>
                            )
                            }
                    })}
                </select>
                <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
            </div>
            <div className="block centered posRelative">
                    <label className="widthFull disInline"> User Photo </label>
                    <input type="file" onChange={handdleImageFile} className="widthFull textForm" placeholder="User Image" />
                    <span className='posAbsolute nowImageShow disFlex jcc aic'>
                        {userImage ? fileChenge == '1' ?  
                        <img src={mainUrl.MediaUrl+userImage}  className='disBlock widthFull coverCenterImage heightFull' alt=''></img> 
                        :  <img src={fileChenge}  className='disBlock widthFull coverCenterImage heightFull' alt=''></img> : "No Image Chosen"}
                    </span>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Gender </label>
                    <select value={genderID} onChange={(e)=>{setGenderId(e.target.value);}}>
                        <option> Select a Gender  </option>
                        <option value='0'> Male  </option>
                        <option value='1'> Female  </option>
                        <option value='2'> Others  </option>
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Marital Status </label>
                    <select value={maritalId} onChange={(e)=>{setMaritalId(e.target.value);}}>
                        <option> Select a Status  </option>
                        <option value='0'> Single  </option>
                        <option value='1'> Married  </option>
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
            </div>
            <div className='CarryBlock left'>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> First Name </label>
                    <input value={firstName} type="text" onChange={(e)=>{setFirstName(e.target.value);}} className="widthFull textForm" placeholder="Enter Name" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Last Name </label>
                    <input value={lastName} type="text" onChange={(e)=>{setLastName(e.target.value);}} className="widthFull textForm" placeholder="Enter Name" />
                </div>
                
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Phone </label>
                    <input value={phone} type="text" onChange={(e)=>{setPhone(e.target.value);}} className="widthFull textForm" placeholder="Enter Number" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Date of Birth </label>
                    <input value={dob} type="date" onChange={(e)=>{setDob(e.target.value);}} className="widthFull textForm"/>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Blood Group </label>
                    <input value={bloodGroup=='null' ? '' : bloodGroup} type="text" onChange={(e)=>{setBloodGroup(e.target.value);}} className="widthFull textForm" placeholder="Enter Group(ex: B+ve)" />
                </div>  
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Email ID(UserName) </label>
                    <input value={emailId} type="email" onChange={(e)=>{setEmailId(e.target.value);}} className="widthFull textForm" placeholder="Enter ID" />
                </div>
                {/* <div className="block centered posRelative">
                    <label className="widthFull disInline"> Password </label>
                    <input type={passwordShow ? "text" : 'password'} onChange={(e)=>{setPassword(e.target.value);}} className="widthFull textForm" placeholder="Enter Password" />
                    <span className="material-symbols-outlined posAbsolute showPasswordBut" onClick={togglePasswordShow}>{passwordShowIcon ? 'visibility' : 'visibility_off'}</span>
                </div> */}
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Assign a Role </label>
                    <select value={roleId} onChange={(e)=>{setRoleId(e.target.value);}}>
                        <option value='0'> Select a Role  </option>
                        {roleList.map(data =>{ 
                            return(
                            <option value={data.id} key={data.id}> {data.role_name}  </option>
                             )
                        })}
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block centered  posRelative">
                    <label className="widthFull disInline"> Status </label>
                    <input type="checkbox" className="disInline checkBt textForm" checked={status} onChange={(e)=>setStatus(e.target.checked)} />
                        Active
                </div>
            </div>
            <input onClick={handleUpdate} type="submit" value="Update User" className="centered disBlock curpoi submitForm"/>
            </div>
        </>
    )
}