import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
export default function TestAdd(){
    const tokenString = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
     const serchText = params.get("search");
    const [BlankFormMsg, setBlankFormMsg] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const[categoryList, setCategoryList]= useState([]);

    const [testName, setTestName] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [subCategoryId, setSubCategoryId] = useState('');
    const [mrp, setMrp] = useState('');
    const [special, setSpecial] = useState('');

    useEffect(() => {
        axios.post('category_list', {
            token: tokenString,
            search: serchText,
        }, { headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => { 
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                    setCategoryList(response.data.data);
                }else{ navigate('/'); } }  })
    }, []);//
    const handleAdd =(e)=>{
        axios.post('test_type_add', {
            token: tokenString,
            test_name: testName,
            category_id: subCategoryId,
            special_price: special,
            mrp_price: mrp
        }, { headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
            })
        .then(response => {
            setErrorMessage(response.message); 
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                    sessionStorage.setItem('SuccMsg', (response.data.message))
                    navigate('/Test'); }
                if(response.data.status==2){
                    setErrorMessage(response.data.message); }
                }
              })
             .catch(error => { setErrorMessage(error.response.message);}); 
    }
    return(
        <>
         <div className="addLocationBlock text centered disBlock">
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Category </label>
                    <select onChange={(e)=>{setCategoryId(e.target.value);}}>
                        <option value=""> Select a category  </option>
                        {categoryList.map(data =>{ 
                            if(data.parent==null){
                            return(
                            <option value={data.id} key={data.id}> {data.category_name}  </option>
                            )
                            }
                        })}
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Subcategory </label>
                    <select onChange={(e)=>{setSubCategoryId(e.target.value);}}>
                        <option value=""> Select a subcategory  </option>
                        {categoryList.map(dataSub =>{ 
                            if(dataSub.parent==categoryId){
                            return(
                            <option value={dataSub.id} key={dataSub.id}> {dataSub.category_name}  </option>
                            )
                            }
                        })}
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Test </label>
                    <input type="text"  className="widthFull textForm" placeholder="Enter Test"  onChange={(e)=>{setTestName(e.target.value);}}/>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Maximum Retail Prize </label>
                    <input type="text" className="widthFull textForm" placeholder="Enter price"  onChange={(e)=>{setMrp(e.target.value);}}/>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Special Price </label>
                    <input type="text" className="widthFull textForm" placeholder="Enter Price"  onChange={(e)=>{setSpecial(e.target.value);}}/>
                </div>
                <input type="Submit" defaultValue ="Add"  onClick={handleAdd}  className="centered disBlock curpoi submitForm"/>
            </div>
        </>
    )
}