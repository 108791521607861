import { Link, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from 'react';
export default function PageHeader(){
    const inputRef = useRef(null);
        const location = useLocation();
        const CurntPage = location.pathname.slice(1);
        const params = new URLSearchParams(location.search);
        const curntID = params.get("id");
        const clientName = params.get("cname");
        const [searchValue, setSearchValue] = useState("");
        const clientIdSet= sessionStorage.getItem('clientIDSave');
//Location
if(CurntPage==='Locations'){
    const checkSearch=(e)=> {
        if(searchValue.length == 0 ){
            e.preventDefault();
            inputRef.current.focus();
        }else{
        }
    }
    return(
        <>
    <div className="left">
        <h2> 
            Locations
        </h2>  
    </div>
    <div className="search posRelative">
        <form name="searchForm" method="get" onSubmit={checkSearch}>
            <input type="search" ref={inputRef} className="inputFld searchFld vertAlMiddle disInline" placeholder="Search" name="search" onChange={(e)=>setSearchValue(e.target.value)}/>
            <button className="posAbsolute" type="submit">
                <span className="material-symbols-outlined">search</span>
            </button>
        </form>
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddLocation'> Add Locations 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Country'> Country
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
    </div>
        </>
    );
} 
//Add Location
if(CurntPage==='AddLocation'){
    return(
        <>
    <div className="left">
        <h2> 
            Add Location
        </h2>  
    </div>
    <div className="search posRelative">
       
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add back">
            <Link to='/Locations'> 
                <span className="material-symbols-outlined vertAlMiddle disInline ">arrow_back</span>
                Location
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Country'> Country
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
    </div>
        </>
    );
}
//  country

if(CurntPage==='Country'){
    const checkSearch=(e)=> {
        if(searchValue.length == 0 ){
            e.preventDefault();
            inputRef.current.focus();
        }else{
        }
    }
    return(
        <>
    <div className="left">
        <h2> 
            Country
        </h2>  
    </div>
    <div className="search posRelative">
        <form name="searchForm" method="get" onSubmit={checkSearch}>
            <input type="search" ref={inputRef} className="inputFld searchFld vertAlMiddle disInline" placeholder="Search" name="search" onChange={(e)=>setSearchValue(e.target.value)}/>
            <button className="posAbsolute" type="submit">
                <span className="material-symbols-outlined">search</span>
            </button>
        </form>
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddCountry'> Add Country 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Locations'> Locations
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
    </div>
        </>
    );
}
// Add Country
if(CurntPage=='AddCountry'){
    return(
        <>
    <div className="left">
        <h2> 
            Add Country
        </h2>  
    </div>
    <div className="search posRelative"></div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add back">
            <Link to='/Country'> 
                <span className="material-symbols-outlined vertAlMiddle disInline ">arrow_back</span>
                Country
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Locations'> Locations
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
    </div>
        </>
    );
}
// Edit Country
if(CurntPage=='EditCountry'){
    return(
        <>
    <div className="left">
        <h2> 
            Edit Country
        </h2>  
    </div>
    <div className="search posRelative">
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add back">
            <Link to='/Country'> 
                <span className="material-symbols-outlined vertAlMiddle disInline ">arrow_back</span>
                Country
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Locations'> Locations
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
    </div>
        </>
    );
}

// Edit Country
if(CurntPage=='EditLocation'){
    return(
        <>
    <div className="left">
        <h2> 
            Edit Location
        </h2>  
    </div>
    <div className="search posRelative">
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add back">
            <Link to='/Locations'> 
                <span className="material-symbols-outlined vertAlMiddle disInline ">arrow_back</span>
                Location
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Country'> Country
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
    </div>
        </>
    );
}

// Region
if(CurntPage=='Region' || CurntPage=='AddRegion' ){
    const checkSearch=(e)=> {
        if(searchValue.length == 0 ){
            e.preventDefault();
            inputRef.current.focus();
        }else{
        }
    }
    return(
        <>
    <div className="left">
        {(CurntPage=='Region') ? <h2> Region </h2>  : <h2> Add Region </h2>}  
    </div>
    <div className="search posRelative">
    {(CurntPage=='Region') ?
        <form name="searchForm" method="get" onSubmit={checkSearch}>
            <input type="search" ref={inputRef} className="inputFld searchFld vertAlMiddle disInline" placeholder="Search" name="search" onChange={(e)=>setSearchValue(e.target.value)}/>
            <button className="posAbsolute" type="submit">
                <span className="material-symbols-outlined">search</span>
            </button>
        </form>
        : "" }
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            {(CurntPage=='Region') ? 
            <Link to='/AddRegion'> Add Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link> :
             <Link to='/Region'>  
                <span className="material-symbols-outlined vertAlMiddle disInline ">arrow_back</span> Region 
            </Link>} 
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Country'> Country 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Locations'> Locations
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
    </div>
        </>
    );
}

// Edit Country
if(CurntPage=='EditRegion'){
    return(
        <>
    <div className="left">
        <h2> 
            Edit Region
        </h2>  
    </div>
    <div className="search posRelative">
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add back">
            <Link to='/Region'> 
                <span className="material-symbols-outlined vertAlMiddle disInline ">arrow_back</span>
                Region
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Country'> Country 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Locations'> Locations
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
    </div>
        </>
    );
}

if(CurntPage==='Categories'){
    const checkSearch=(e)=> {
        if(searchValue.length == 0 ){
            e.preventDefault();
            inputRef.current.focus();
        }else{
        }
    }
    return(
        <>
    <div className="left">
        <h2> 
            Categories
        </h2>  
    </div>
    <div className="search posRelative">
        <form name="searchForm" method="get" onSubmit={checkSearch}>
            <input type="search" ref={inputRef} className="inputFld searchFld vertAlMiddle disInline" placeholder="Search" name="search" onChange={(e)=>setSearchValue(e.target.value)}/>
            <button className="posAbsolute" type="submit">
                <span className="material-symbols-outlined">search</span>
            </button>
        </form>
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddCategory'> Add Category 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>
        {/* <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Locations'> Locations
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span> */}
    </div>
        </>
    );
}
//  Categories

if(CurntPage==='AddCategory'){

    return(
        <>
    <div className="left">
        <h2> 
            Add Categories
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
        {/* <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddCategory'> Add Category 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span> */}
        {/* <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>*/}
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Categories'> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span> Categories
            </Link>
        </span> 
    </div>
        </>
    );
}
// EditCategory
if(CurntPage==='EditCategory'){
    return(
        <>
    <div className="left">
        <h2> 
            Edit Category
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddCategory'> Add Category 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Categories'> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span> Categories
            </Link>
        </span> 
    </div>
        </>
    );
}
// Edit Category

if(CurntPage==='Branches'){
    const checkSearch=(e)=> {
        if(searchValue.length == 0 ){
            e.preventDefault();
            inputRef.current.focus();
        }else{
        }
    }
    return(
        <>
    <div className="left">
        <h2> 
            Branches
        </h2>  
    </div>
    <div className="search posRelative">
        <form name="searchForm" method="get" onSubmit={checkSearch}>
            <input type="search" ref={inputRef} className="inputFld searchFld vertAlMiddle disInline" placeholder="Search" name="search" onChange={(e)=>setSearchValue(e.target.value)}/>
            <button className="posAbsolute" type="submit">
                <span className="material-symbols-outlined">search</span>
            </button>
        </form>
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddBranch'> Add Branch 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>
        {/* <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Locations'> Locations
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span> */}
    </div>
        </>
    );
}
// Branches

if(CurntPage==='AddBranch'){
    return(
        <>
    <div className="left">
        <h2> 
            Add Branch
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Branches'> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span> Branches
            </Link>
        </span> 
    </div>
        </>
    );
}
//Edit Branch
if(CurntPage==='EditBranch'){
    return(
        <>
    <div className="left">
        <h2> 
            Edit Branch
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddBranch'> Add Branch 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Branches'> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span> Branches
            </Link>
        </span> 
    </div>
        </>
    );
}//

if(CurntPage==='Test'){
    const checkSearch=(e)=> {
        if(searchValue.length == 0 ){
            e.preventDefault();
            inputRef.current.focus();
        }else{
        }
    }
    return(
        <>
    <div className="left">
        <h2> 
            Test
        </h2>  
    </div>
    <div className="search posRelative">
        <form name="searchForm" method="get" onSubmit={checkSearch}>
            <input type="search" ref={inputRef} className="inputFld searchFld vertAlMiddle disInline" placeholder="Search" name="search" onChange={(e)=>setSearchValue(e.target.value)}/>
            <button className="posAbsolute" type="submit">
                <span className="material-symbols-outlined">search</span>
            </button>
        </form>
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddTest'> Add Test 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>
        {/* <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Locations'> Locations
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span> */}
    </div>
        </>
    );
}//
// add Test
if(CurntPage==='AddTest'){
    return(
        <>
    <div className="left">
        <h2> 
            Add Test
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Test'> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span> Tests
            </Link>
        </span> 
    </div>
        </>
    );
}
// Edit Test
if(CurntPage==='EditTest'){
    return(
        <>
    <div className="left">
        <h2> 
            Edit Test
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddTest'> Add Test 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Test'> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span> Tests
            </Link>
        </span> 
    </div>
        </>
    );
}
if(CurntPage==='Client'){
    const checkSearch=(e)=> {
        if(searchValue.length == 0 ){
            e.preventDefault();
            inputRef.current.focus();
        }else{
        }
    }
    return(
        <>
    <div className="left">
        <h2> 
            Clients
        </h2>  
    </div>
    <div className="search posRelative">
        <form name="searchForm" method="get" onSubmit={checkSearch}>
            <input type="search" ref={inputRef} className="inputFld searchFld vertAlMiddle disInline" placeholder="Search" name="search" onChange={(e)=>setSearchValue(e.target.value)}/>
            <button className="posAbsolute" type="submit">
                <span className="material-symbols-outlined">search</span>
            </button>
        </form>
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddClient'> Add a Client 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>
        {/* <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Locations'> Locations
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span> */}
    </div>
        </>
    );
}
if(CurntPage==='AddClient'){
    const checkSearch=(e)=> {
        if(searchValue.length == 0 ){
            e.preventDefault();
            inputRef.current.focus();
        }else{
        }
    }
    return(
        <>
    <div className="left">
        <h2> 
            Add Client
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
        {/* <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddClient'> Add a Client 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span> */}
        {/* <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>*/}
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Client'> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Client
                
            </Link>
        </span> 
    </div>
        </>
    );
}
if(CurntPage==='EditClient'){
    const checkSearch=(e)=> {
        if(searchValue.length == 0 ){
            e.preventDefault();
            inputRef.current.focus();
        }else{
        }
    }
    return(
        <>
    <div className="left">
        <h2> 
            Edit Client
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
         <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddClient'> Add a Client 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span> 
        {/* <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>*/}
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Client'> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Client
                
            </Link>
        </span> 
    </div>
        </>
    );
}
if(CurntPage==='ClientUser'){
    const checkSearch=(e)=> {
        if(searchValue.length == 0 ){
            e.preventDefault();
            inputRef.current.focus();
        }else{
        }
    }
    return(
        <>
    <div className="left">
        <h2> 
            {clientName} Users
        </h2>  
    </div>
    <div className="search posRelative">
        <form name="searchForm" method="get" onSubmit={checkSearch}>
            <input type="search" ref={inputRef} className="inputFld searchFld vertAlMiddle disInline" placeholder="Search" name="search" onChange={(e)=>setSearchValue(e.target.value)}/>
            <input type="hidden" name="id" value={curntID}></input>
            <input type="hidden" name="cname" value={clientName}></input>
            <button className="posAbsolute" type="submit">
                <span className="material-symbols-outlined">search</span>
            </button>
        </form>
    </div>
    <div className="butons textRight">
         <span className="butBlock disInline vertAlMiddle add">
            <Link to={'/AddClientUser?id='+curntID}> Add a User
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span> 
         <span className="butBlock disInline vertAlMiddle">
            <Link to='/Client'>  
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Clients
            </Link>
        </span>
        {/* <span className="butBlock disInline vertAlMiddle">
            <Link to='/Client'> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Client
                
            </Link>
        </span>  */}
    </div>
        </>
    );
}
if(CurntPage==='AddClientUser'){
    return(
        <>
    <div className="left">
        <h2> 
            Add a Client User
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
         {/* <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddClient'> Add a User
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>  */}
         <span className="butBlock disInline vertAlMiddle">
            <Link to='/Client'>  
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Clients
            </Link>
        </span>
         <span className="butBlock disInline vertAlMiddle">
            <Link to={'/ClientUser?id='+curntID}> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Client User
            </Link>
        </span>  
    </div>
        </>
    );
}
if(CurntPage==='EditClientUser'){
    return(
        <>
    <div className="left">
        <h2> 
            Edit User
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to={'/AddClientUser?id='+clientIdSet}> Add a User 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span> 
         <span className="butBlock disInline vertAlMiddle">
            <Link to='/Client'>  
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Clients
            </Link>
        </span>
         <span className="butBlock disInline vertAlMiddle">
            <Link to={'/ClientUser?id='+clientIdSet}> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Client User
            </Link>
        </span>  
    </div>
        </>
    );
}
if(CurntPage==='Driver'){
    const checkSearch=(e)=> {
        if(searchValue.length == 0 ){
            e.preventDefault();
            inputRef.current.focus();
        }else{
        }
    }
    return(
        <>
    <div className="left">
        <h2> 
            Driver
        </h2>  
    </div>
    <div className="search posRelative">
        <form name="searchForm" method="get" onSubmit={checkSearch}>
            <input type="search" ref={inputRef} className="inputFld searchFld vertAlMiddle disInline" placeholder="Search" name="search" onChange={(e)=>setSearchValue(e.target.value)}/>
            <button className="posAbsolute" type="submit">
                <span className="material-symbols-outlined">search</span>
            </button>
        </form>
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddDriver'> Add a Driver 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>
        {/* <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Locations'> Locations
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span> */}
    </div>
        </>
    );
}
if(CurntPage==='AddDriver'){
    return(
        <>
    <div className="left">
        <h2> 
            Add a Driver
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
         {/* <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddClient'> Add a User
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>  */}
         <span className="butBlock disInline vertAlMiddle">
            <Link to='/Driver'>  
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Driver
            </Link>
        </span>
         {/* <span className="butBlock disInline vertAlMiddle">
            <Link to={'/ClientUser?id='+curntID}> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Client User
            </Link>
        </span>   */}
    </div>
        </>
    );
}
if(CurntPage==='EditDriver'){
    return(
        <>
    <div className="left">
        <h2> 
            Edit Driver
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to={'/AddDriver'}> Add a Driver 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span> 
         <span className="butBlock disInline vertAlMiddle">
            <Link to='/Driver'>  
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Driver
            </Link>
        </span>
         {/* <span className="butBlock disInline vertAlMiddle">
            <Link to={'/ClientUser?id='+clientIdSet}> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Client User
            </Link>
        </span>   */}
    </div>
        </>
    );
}
if(CurntPage==='Path'){
    const checkSearch=(e)=> {
        if(searchValue.length == 0 ){
            e.preventDefault();
            inputRef.current.focus();
        }else{
        }
    }
    return(
        <>
    <div className="left">
        <h2> 
            Path List
        </h2>  
    </div>
    <div className="search posRelative">
        <form name="searchForm" method="get" onSubmit={checkSearch}>
            <input type="search" ref={inputRef} className="inputFld searchFld vertAlMiddle disInline" placeholder="Search" name="search" onChange={(e)=>setSearchValue(e.target.value)}/>
            <button className="posAbsolute" type="submit">
                <span className="material-symbols-outlined">search</span>
            </button>
        </form>
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddPath'> Add a Path 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>
        {/* <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Locations'> Locations
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span> */}
    </div>
        </>
    );
}
if(CurntPage==='AddPath'){
    return(
        <>
    <div className="left">
        <h2> 
            Add a Path
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
         {/* <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddClient'> Add a User
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>  */}
         <span className="butBlock disInline vertAlMiddle">
            <Link to='/Path'>  
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Path List
            </Link>
        </span>
         {/* <span className="butBlock disInline vertAlMiddle">
            <Link to={'/ClientUser?id='+curntID}> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Client User
            </Link>
        </span>   */}
    </div>
        </>
    );
}
if(CurntPage==='EditPath'){
    return(
        <>
    <div className="left">
        <h2> 
            Edit Path
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to={'/AddPath'}> Add a Path 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span> 
         <span className="butBlock disInline vertAlMiddle">
            <Link to='/Path'>  
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Path List
            </Link>
        </span>
         {/* <span className="butBlock disInline vertAlMiddle">
            <Link to={'/ClientUser?id='+clientIdSet}> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Client User
            </Link>
        </span>   */}
    </div>
        </>
    );
}
if(CurntPage==='Roles'){
    const checkSearch=(e)=> {
        if(searchValue.length == 0 ){
            e.preventDefault();
            inputRef.current.focus();
        }else{
        }
    }
    return(
        <>
    <div className="left">
        <h2> 
            User Roles
        </h2>  
    </div>
    <div className="search posRelative">
        <form name="searchForm" method="get" onSubmit={checkSearch}>
            <input type="search" ref={inputRef} className="inputFld searchFld vertAlMiddle disInline" placeholder="Search" name="search" onChange={(e)=>setSearchValue(e.target.value)}/>
            <button className="posAbsolute" type="submit">
                <span className="material-symbols-outlined">search</span>
            </button>
        </form>
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddRole'> Add a Role 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>
        {/* <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Locations'> Locations
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span> */}
    </div>
        </>
    );
}
if(CurntPage==='AddRole'){
    return(
        <>
    <div className="left">
        <h2> 
            Add a Role
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
         {/* <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddClient'> Add a User
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>  */}
         <span className="butBlock disInline vertAlMiddle">
            <Link to='/Roles'>  
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Roles
            </Link>
        </span>
         {/* <span className="butBlock disInline vertAlMiddle">
            <Link to={'/ClientUser?id='+curntID}> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Client User
            </Link>
        </span>   */}
    </div>
        </>
    );
}
if(CurntPage==='EditRole'){
    return(
        <>
    <div className="left">
        <h2> 
            Edit Role
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to={'/AddRole'}> Add a Role 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span> 
         <span className="butBlock disInline vertAlMiddle">
            <Link to='/Roles'>  
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Role
            </Link>
        </span>
         {/* <span className="butBlock disInline vertAlMiddle">
            <Link to={'/ClientUser?id='+clientIdSet}> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Client User
            </Link>
        </span>   */}
    </div>
        </>
    );
}
if(CurntPage==='Users'){
    const checkSearch=(e)=> {
        if(searchValue.length == 0 ){
            e.preventDefault();
            inputRef.current.focus();
        }else{
        }
    }
    return(
        <>
    <div className="left">
        <h2> 
            Admin Users
        </h2>  
    </div>
    <div className="search posRelative">
        <form name="searchForm" method="get" onSubmit={checkSearch}>
            <input type="search" ref={inputRef} className="inputFld searchFld vertAlMiddle disInline" placeholder="Search" name="search" onChange={(e)=>setSearchValue(e.target.value)}/>
            <button className="posAbsolute" type="submit">
                <span className="material-symbols-outlined">search</span>
            </button>
        </form>
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddUser'> Add a User 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>
    </div>
        </>
    );
}
if(CurntPage==='AddUser'){
    return(
        <>
    <div className="left">
        <h2> 
            Add a Admin User
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
         {/* <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddClient'> Add a User
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>  */}
         <span className="butBlock disInline vertAlMiddle">
            <Link to='/Users'>  
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Users
            </Link>
        </span>
         {/* <span className="butBlock disInline vertAlMiddle">
            <Link to={'/ClientUser?id='+curntID}> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Client User
            </Link>
        </span>   */}
    </div>
        </>
    );
}
if(CurntPage==='EditUser'){
    return(
        <>
    <div className="left">
        <h2> 
            Edit {clientName}
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to={'/AddUser'}> Add a User 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span> 
         <span className="butBlock disInline vertAlMiddle">
            <Link to='/Users'>  
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Admin Users
            </Link>
        </span>
         {/* <span className="butBlock disInline vertAlMiddle">
            <Link to={'/ClientUser?id='+clientIdSet}> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Client User
            </Link>
        </span>   */}
    </div>
        </>
    );
}
if(CurntPage==='UserProfile'){
    return(
        <>
    <div className="left">
        <h2> 
            User Profile
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to={'/EditProfile'}>Edit Profile 
            </Link>
        </span> 
         
         {/* <span className="butBlock disInline vertAlMiddle">
            <Link to={'/ClientUser?id='+clientIdSet}> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Client User
            </Link>
        </span>   */}
    </div>
        </>
    );
}
//my test
if(CurntPage==='MyTest'){
    const checkSearch=(e)=> {
        if(searchValue.length == 0 ){
            e.preventDefault();
            inputRef.current.focus();
        }else{
        }
    }
    return(
        <>
    <div className="left">
        <h2> 
            Test
        </h2>  
    </div>
    <div className="search posRelative">
        <form name="searchForm" method="get" onSubmit={checkSearch}>
            <input type="search" ref={inputRef} className="inputFld searchFld vertAlMiddle disInline" placeholder="Search" name="search" onChange={(e)=>setSearchValue(e.target.value)}/>
            <button className="posAbsolute" type="submit">
                <span className="material-symbols-outlined">search</span>
            </button>
        </form>
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddMyTest'> Add Test 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>
        {/* <span className="butBlock disInline vertAlMiddle">
            <Link to='/Region'> Region 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span>
        <span className="butBlock disInline vertAlMiddle">
            <Link to='/Locations'> Locations
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_forward_ios</span>
            </Link>
        </span> */}
    </div>
        </>
    );
}
if(CurntPage==='AddMyTest'){
    return(
        <>
    <div className="left">
        <h2> 
            Add Test
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
        {/* <span className="butBlock disInline vertAlMiddle add">
            <Link to={'/AddUser'}> Add a User 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span>  */}
         <span className="butBlock disInline vertAlMiddle">
            <Link to='/MyTest'>  
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Test
            </Link>
        </span>
         {/* <span className="butBlock disInline vertAlMiddle">
            <Link to={'/ClientUser?id='+clientIdSet}> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Client User
            </Link>
        </span>   */}
    </div>
        </>
    );
}

if(CurntPage==='EditMyTest'){
    return(
        <>
    <div className="left">
        <h2> 
            Edit Test
        </h2>  
    </div>
    <div className="search posRelative">
        
    </div>
    <div className="butons textRight">
        <span className="butBlock disInline vertAlMiddle add">
            <Link to={'/AddMyTest'}> Add a Test 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span> 
         <span className="butBlock disInline vertAlMiddle">
            <Link to='/MyTest'>  
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Test
            </Link>
        </span>
         {/* <span className="butBlock disInline vertAlMiddle">
            <Link to={'/ClientUser?id='+clientIdSet}> 
                <span className="material-symbols-outlined vertAlMiddle disInline">arrow_back_ios</span>
                Client User
            </Link>
        </span>   */}
    </div>
        </>
    );
}

if(CurntPage==='Requests'){
    const checkSearch=(e)=> {
        if(searchValue.length == 0 ){
            e.preventDefault();
            inputRef.current.focus();
        }else{
        }
    }
    return(
        <>
    <div className="left">
        <h2> 
            Test Requests
        </h2>  
    </div>
    <div className="search posRelative">
        <form name="searchForm" method="get" onSubmit={checkSearch}>
            <input type="search" ref={inputRef} className="inputFld searchFld vertAlMiddle disInline" placeholder="Search" name="search" onChange={(e)=>setSearchValue(e.target.value)}/>
            <button className="posAbsolute" type="submit">
                <span className="material-symbols-outlined">search</span>
            </button>
        </form>
    </div>
    <div className="butons textRight">
        {/* <span className="butBlock disInline vertAlMiddle add">
            <Link to='/AddUser'> Add a User 
                <span className="material-symbols-outlined vertAlMiddle disInline">add</span>
            </Link>
        </span> */}
    </div>
        </>
    );
}



}
