import axios from '../api/axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ClientLoginform() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [profileName, setUserName] = useState('');
    const handleEmail =(e) =>{
        setEmail(e.target.value);
    }
    const handlePassword =(e) =>{
       setPassword(e.target.value); 
    }
    const handleLogin = () => {
        setErrorMessage();
        axios.post('client_user_login', {
            email:email, 
            password:password, 
            grant_type: 'MY_GRANT_TYPE'
        }, {
                headers: {
                   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
        })
        .then(response => {
            setErrorMessage(response.data.message); 
            if(response.status==200){
                if(response.data.status==1){
                    setToken(response.data.token);
                    setUserName(response.data.data[0].first_name)
                    sessionStorage.setItem('UserProfileName', response.data.data[0].first_name)
                    sessionStorage.setItem('UserProfileLocation', response.data.data[0].location_name)
                    sessionStorage.setItem('UserProfileImage', response.data.data[0].photos_image)
                    sessionStorage.setItem('UserRoleName', response.data.data[0].role_name)
                    navigate('/MyDashboard');
                }
            }
        })
        .catch(error => {
            setErrorMessage(error.response.data.message);  
        });
      };
      function setToken(userToken) {sessionStorage.setItem('token', (userToken))}
    
      
      

  return (
    <div className="disInline mt15 widthFull">
         {errorMessage && <div className="error"> {errorMessage} </div>}
        <label className="disInline widthFull posRelative">  
            <input type="text"  value={email} onChange={handleEmail} className="textBox" placeholder="User Name"/>
            <span className="material-symbols-outlined icons posAbsolute">alternate_email</span>
        </label>
        <label className="disInline widthFull posRelative">  
            <input type="password"  value={password} onChange={handlePassword}  className="textBox" placeholder="Password"/>
            <span className="material-symbols-outlined icons posAbsolute">lock</span>
        </label>
        <span className="forgotPass widthFull disInline textRight">
            <a href="dashbord.php" className="disInline vertAlMiddle">
                <span className="material-symbols-outlined disInline vertAlMiddle">help_center</span>
                Forgot Password 
            </a>
        </span>
        <input onClick={handleLogin} type="submit" className="login widthFull submitButton textUppercase" value="Login"/>
        <span className="disInline dontHaveAc widthFull">
            Don't have an account? <br/>
            <b className='disBlock mt05'> Call: <a href="tel:+919000000000" className="disInline">+91 9000 0000 00</a> </b>
        </span>
    </div>
  )
}
