import UserAdd from './components/UserAdd';
import Header from './components/Header';
import PageHeader from './components/PageHeader';
export default function AddUser(){
    return(
        <>
         <Header/>
         <section className="disFlex flexWrap widthFull dashboardBlock centered">
            <div className="disFlex flexWrap pageHeader widthFull aic">
                <PageHeader/>
            </div>
            <div className="pageContainer scrollBarCustom location centered disBlock">
                <UserAdd/>
            </div>
        </section>
        </>
    )
}