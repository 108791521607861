import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

export default function BranchEdit() {
  const tokenString = sessionStorage.getItem('token');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const curntID = params.get("id");
  const navigate = useNavigate();

    const[editPost, setEditPost]= useState([]);
    const[countryList, setCountryList]= useState([]);
    const[regionList, setRegionList]= useState([]);
    const[locationList, setLocationList]= useState([]);

    // const [country, setCountry] = useState('');
    const [countryId, setCountryId] = useState('');
    // const [region, seRegion] = useState('');
    const [regionId, setRegionId] = useState('');
    // const [locationName, setLocation] = useState('');
    const [locationId, setLocationId] = useState('');
    const [name, setName] = useState('');
    const [placeName, setPlace] = useState('');
    const [phone, setPhone] = useState('');
    const [emailId, setEmailId] = useState('');
    const [whatsApp, setWhatsapp] = useState('');
    const [mapUrl, setMapUrl] = useState('');
    const [address, setAddress] = useState('');
    const [status, setStatus] = useState('');

    const [errorMessage, setErrorMessage] = useState('');

  const handleUpdate = (e) =>{
    axios.post('branch_update', {
        token: tokenString,
        branch_id: curntID,
        country_id: countryId,
        region_id: regionId,
        location_id: locationId,
        place: placeName,
        name: name,
        branch_phone: phone,
        branch_email: emailId,
        branch_whatsapp_phone: whatsApp,
        goole_map_location: mapUrl,
        address: address,
        branch_status: status,

    }, {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
    })
    .then(response => {
        setErrorMessage(response.message); 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                sessionStorage.setItem('SuccMsg', (response.data.message))
                navigate('/Branches');
            }
            if(response.data.status==2){
                setErrorMessage(response.data.message);
            }
        }
    })//
    }// 
  useEffect(() => {
    //Branch View
    axios.post('branch_view', {
        token: tokenString,
        branch_id: curntID,
    }, { headers: {
           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => { 
        if(response.status==200){
            if(response.data.status==1){
                setEditPost(response.data.data); 
                setName(response.data.data[0].name);
                setPlace(response.data.data[0].place);
                setPhone(response.data.data[0].branch_phone);
                setEmailId(response.data.data[0].branch_email);
                setWhatsapp(response.data.data[0].branch_whatsapp_phone);
                setMapUrl(response.data.data[0].goole_map_location);
                setAddress(response.data.data[0].address);
                setCountryId(response.data.data[0].branch_country);
                setRegionId(response.data.data[0].branch_region );
                setLocationId(response.data.data[0].branch_location );
                setStatus(response.data.data[0].branch_status );
            }else{ navigate('/'); } }  }) //

 //Country List
axios.post('country_list', {
        token: tokenString,
    }, { headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }}).then(response => { 
            if(response.status==200){
                if(response.data.status==1){
                    setCountryList(response.data.data);
            }else{ navigate('/'); } }  
        })//
//Region List
    axios.post('region_list', {
        token: tokenString,
    }, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
.then(response => { 
    if(response.status==200){
        if(response.data.status==1){
            setRegionList(response.data.data);
        }else{ navigate('/'); } }  })//
//Location List
axios.post('location_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200){
        if(response.data.status==1){
            setLocationList(response.data.data);
        }else{ navigate('/'); } }  })//

  }, []);//

  return (
    <>    
{editPost.map((data) => { return   (  
<div className="addLocationBlock text centered disBlock" key={data.id}>
    <h4 className='textCenter textUppercase'> Edit {data.name} </h4>
    <div className="block posRelative centered">
        <label className="widthFull disInline"> Country </label>
        <select value={countryId} onChange={(e) =>{setCountryId(e.target.value);setRegionId('0');}}>
        {countryList.map(data =>{ 
            return(
                <option value={data.id} key={data.id}> {data.country_name}  </option>
                 )
            })}
        </select>
        <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
    </div>
    <div className="block posRelative centered">
        <label className="widthFull disInline"> Region </label>
        <select value={regionId} onChange={(e)=>{setRegionId(e.target.value);setLocationId('0')}}>
            <option> Select a Region </option>
            {regionList.map(data =>{ 
                 if(countryId == data.region_country){
                    return(
                    <option value={data.id} key={data.id}> {data.region_name}  </option>
                        )
                    }
                })}
         </select>
        <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
    </div>
    <div className="block posRelative centered">
        <label className="widthFull disInline"> Location </label>
        <select  value={locationId} onChange={(e)=>{setLocationId(e.target.value);}}>
            <option> Select a Location </option>
            {locationList.map(data =>{
                if(countryId == data.location_country){
                 if(regionId == data.location_region){ 
                return(
                <option value={data.id} key={data.id}> {data.location_name}  </option>
                    )  
                }    }      
                })}
        </select>
        <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
    </div>
    <div className="block centered posRelative">
        <label className="widthFull disInline"> Branch Name </label>
        <input type="text" className="widthFull textForm" placeholder="Enter Name"  value={name}
        onChange={(e)=>setName(e.target.value)} />
    </div>
    <div className="block centered posRelative">
        <label className="widthFull disInline"> Place </label>
        <input type="text" className="widthFull textForm" placeholder="Enter Place"  value={placeName}
        onChange={(e)=>setPlace(e.target.value)} />
    </div>
    <div className="block centered posRelative">
        <label className="widthFull disInline"> Phone </label>
        <input type="text" className="widthFull textForm" placeholder="Enter Number"  value={phone}
        onChange={(e)=>setPhone(e.target.value)} />
    </div>
    <div className="block centered posRelative">
        <label className="widthFull disInline"> Email </label>
        <input type="text" className="widthFull textForm" placeholder="Enter mail id" value={emailId}
        onChange={(e)=>setEmailId(e.target.value)}/>
    </div>
    <div className="block centered posRelative">
        <label className="widthFull disInline"> Whatsapp </label>
        <input type="text" className="widthFull textForm" placeholder="Enter Whatsapp number" value={whatsApp} onChange={(e)=>setWhatsapp(e.target.value)}/>
    </div>
    <div className="block centered posRelative">
        <label className="widthFull disInline"> Google map URL </label>
        <input type="text" className="widthFull textForm" placeholder="Enter map link" value={mapUrl==null ? '' : mapUrl}
        onChange={(e)=>setMapUrl(e.target.value)}/>
    </div>
    <div className="block centered posRelative">
        <label className="widthFull disInline"> Address </label>
        <textarea className='widthFull textFormTa' placeholder='Enter Address' value={address}
        onChange={(e)=>setAddress(e.target.value)}></textarea>
    </div>
    <div className="block centered  posRelative">
        <label className="widthFull disInline"> Status </label>
        <input type="checkbox" className="disInline checkBt textForm" checked={status} onChange={(e)=>setStatus(e.target.checked)} />
        Active
    </div>
    <input onClick={handleUpdate} type="submit" value="Update" className="centered disBlock curpoi submitForm"/>
            </div>
    ) })}
    </>
  )
}
