import Header from './components/Header';
import PageHeader from './components/PageHeader'
import TestList from './components/TestList';

export default function Test() {  
      return (
    <>
    <Header/>
    <section className="disFlex flexWrap widthFull dashboardBlock centered">
        <div className="disFlex flexWrap pageHeader widthFull aic">
            <PageHeader/>
        </div>
        <div className="pageContainer scrollBarCustom location centered disBlock">
            <TestList/>
        </div>
        
    </section>
    </>
  )
}

