import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
export default function AddCategoryPage(){
    const tokenString = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const[post, setPost]= useState([]);
    const params = new URLSearchParams(location.search);
     const serchText = params.get("search");
    const [BlankFormMsg, setBlankFormMsg] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [parentId, setParentId] = useState('');
    
    // category List
useEffect(() => {
    axios.post('category_list', {
        token: tokenString,
        search: serchText,
    }, { headers: {
           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => { 
        if(response.status==200){
            if(response.data.status==1){
                setPost(response.data.data);
            }else{ navigate('/'); } }  })
}, []);

 // category Add
 const handleAdd =(e) =>{ 
 axios.post('category_add', {
    token: tokenString,
    category: categoryName,
    parent_id: parentId,
}, { headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
    })
.then(response => {
    setErrorMessage(response.message); 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            sessionStorage.setItem('SuccMsg', (response.data.message))
            navigate('/Categories'); }
        if(response.data.status==2){
            setErrorMessage(response.data.message); }
        }
      })
     .catch(error => { setErrorMessage(error.response.message);}); };
    return(
        <>
         <div className="addLocationBlock text centered disBlock">
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Category </label>
                    <input type="text" onChange={(e)=>{setCategoryName(e.target.value);}} className="widthFull textForm" placeholder="Enter Category" />
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Parent </label>
                    <select onChange={(e)=>{setParentId(e.target.value);}}>
                        <option> Select a parent  </option>
                        <option value="0"> No parent  </option>
                        {post.map(data =>{ 
                             if(data.parent == null){
                            return(
                                <option value={data.id} key={data.id}> {data.category_name}  </option>
                                )
                                }
                            })
                        }
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <input onClick={handleAdd} type="submit" className="centered disBlock curpoi submitForm" placeholder="Enter Location"/>
            </div>
        </>
    )
}