import  {useState, useEffect} from 'react';
import './theme/theme.css';
import Nointr from './images/nointernet.svg';

const NoInterNet = (props) => {
    const [isOnline, setOnline] = useState(true);
    useEffect(()=>{
        setOnline(navigator.onLine)
    },[])
    window.addEventListener('online', () => {
        setOnline(true)
    });
    
    window.addEventListener('offline', () => {
        setOnline(false)
    });
    if(isOnline){
        return(
            props.children
        )
        } else {
            return(
            <div className='noninterNetMsg flexWrap jcc disFlex aic'>
                <img src={Nointr} className='disBlock' alt='no internet'/> 
               <div className='block'> 
                        <h2> Oh Ho!! </h2>
                        <span className='txtCat disInline'> No internet connection </span>
                        <p> We can wait for good things! </p>
                </div>
            </div>)
    }
}
export default NoInterNet;