import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
export default function RoleEdit(){
    const tokenString = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const curntID = params.get("id");
    const [BlankFormMsg, setBlankFormMsg] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const[post, setPost]= useState([]);
    const[PathPost, setPathPost]= useState([]);
    const[countryList, setCountryList]= useState([]);
    const[regionList, setRegionList]= useState([]);
    const[locationList, setLocationList]= useState([]);
    const[branchList, setBranchList]= useState([]);
    const [RoleConnection, setRoleConnection] = useState([]);

    const [roleName, setRoleName] = useState('');
    const [countryId, setCountryId] = useState('');
    const [regionId, setRegionId] = useState('');
    const [locationId, setLocationId] = useState('');
    const [branchId, setBranchId] = useState('');
    const [status, setStatus] = useState('');
    const[roleId, setRoleId]= useState([]);
    const [checkStatus, SetCheckBoxStatus] = useState('0');
    
    // category List
useEffect(() => {
    axios.post('role_view', {
        token: tokenString,
        role_id: curntID,
    }, { headers: {
           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => { 
        if(response.status==200){
            if(response.data.status==1){
                setPost(response.data.data);
                setRoleName(response.data.data[0].role_name);
                setCountryId(response.data.data[0].branch_country);
                setRegionId(response.data.data[0].branch_region);
                setLocationId(response.data.data[0].branch_location);
                setBranchId(response.data.data[0].branch);
                setStatus(response.data.data[0].status);
                setRoleConnection(response.data.data[0].role_connection);
                response.data.data[0].role_connection.forEach(setRoleCon=>{
                    var index = roleId.indexOf(setRoleCon.path_id);
                    if (index == -1) {
                    setRoleId(current => [...current, setRoleCon.path_id]);
                    }
                })  
            }else{ navigate('/'); } }  })
     //Country List
     axios.post('country_list', {
        token: tokenString,
    }, { headers: {
           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => { 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                setCountryList(response.data.data);
            }else{ navigate('/'); } }  })
//Region List
axios.post('region_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setRegionList(response.data.data);
        }else{ navigate('/'); } }  })

//Location List
axios.post('location_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setLocationList(response.data.data);
        }else{ navigate('/'); } }  })

//Branch List
axios.post('branch_list', {
    token: tokenString,
}, { headers: {
       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }})
.then(response => { 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            setBranchList(response.data.data);
        }else{ navigate('/'); } }  })
    //path
    axios.post('path_list', {
        token: tokenString,
    }, { headers: {
           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => { 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                setPathPost(response.data.data);
            }else{ navigate('/'); } }  })
}, []);
setTimeout(() => {roleConnect();}, 500);
const roleConnect=()=>{
    for(var i=0;i<RoleConnection.length;i++){
        document.getElementById(RoleConnection[i].path_id).checked = true;   
        }
}
const  haddleRoleID =(e, roleIdInsrt)=>{
    var index = roleId.indexOf(roleIdInsrt);
    if (index !== -1) {
        roleId.splice(index, 1);
      }else{
        setRoleId(current => [...current, roleIdInsrt]);
      }
      //alert(roleId)
};
 // category Add
 const handleUpdate =(e) =>{ 
    const formData = new FormData();
    formData.append("token", tokenString);
    formData.append("role_id", curntID);
    formData.append("region_id", regionId);
    formData.append("country_id", countryId);
    formData.append("location_id", locationId); 
    formData.append("branch_id", branchId);
    formData.append("path_array", roleId);
    formData.append("role_name", roleName);
    formData.append("status", status);
 axios.post('role_edit', formData)
.then(response => {
    setErrorMessage(response.message); 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            sessionStorage.setItem('SuccMsg', (response.data.message))
            navigate('/Roles'); }
        if(response.data.status==2){
            setErrorMessage(response.data.message); }
        }
      })
      .catch(error => { setErrorMessage(error.response.message);}); 
    };
    return(
        <>          
         <div className="addLocationBlock jcsb RoleAdd disFlex flexWrap text centered disBlock">  
                <div className='CarryBlock left'>
                    <div className='blockofform'>
                        <div className="block posRelative centered">
                            <label className="widthFull disInline"> Country </label>
                            <select value={countryId} onChange={(e)=>{setCountryId(e.target.value);}} >
                                {countryList.map(CouData =>{ 
                                return(
                                <option value={CouData.id} key={CouData.id}> {CouData.country_name} </option>
                                    )
                                })}
                            </select>
                            <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                        </div>
                        <div className="block posRelative centered">
                            <label className="widthFull disInline"> Region </label>
                            <select value={regionId} onChange={(e)=>{setRegionId(e.target.value);}}>
                                {regionList.map(RegiData =>{
                                    if(countryId == RegiData.region_country){
                                    return(
                                        <option value={RegiData.id} key={RegiData.id}> {RegiData.region_name}  </option>
                                    )
                                  }
                                })}
                            </select>
                            <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                        </div>
                        <div className="block posRelative centered">
                            <label className="widthFull disInline"> Location </label>
                            <select value={locationId} onChange={(e)=>{setLocationId(e.target.value);}} >
                                {locationList.map(LocData=> {
                                     if(regionId == LocData.location_region){
                                        return(
                                            <option value={LocData.id} key={LocData.id}> {LocData.location_name} </option>
                                        )
                                     }
                                })}
                            </select>
                        </div>
                        <div className="block posRelative centered">
                            <label className="widthFull disInline"> Branch </label>
                            <select value={branchId} onChange={(e)=>{setBranchId(e.target.value);}}>
                                {branchList.map(BraData=>{
                                    if(locationId == BraData.branch_location){
                                        return(
                                            <option value={BraData.id} key={BraData.id}> {BraData.name}  </option>
                                        )
                                    }
                                })} 
                            </select>
                            <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                        </div>
                        <div className="block centered posRelative">
                            <label className="widthFull disInline"> Role Name </label>
                            <input type="text" value={roleName} onChange={(e)=>{setRoleName(e.target.value);}} className="widthFull textForm" placeholder="Enter Name" />
                        </div>
                        <div className="block posRelative centered">
                            <label className="widthFull disInline"> Status </label>
                            <select value={status} onChange={(e)=>{setStatus(e.target.value);}}>
                                <option value='0' key='1001'> Active </option>
                                <option value='1' key='1002'> Inactive </option>
                                <option value='2' key='1003'> Hidden </option>
                            </select>
                            <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                        </div>
                    </div>
                </div>
                <div className='CarryBlock right'>
                    <h3> Menu to Show  </h3> 
                    <div className='blockofform widthFull scrollBarCustom'>
                        {PathPost.map((PathData)=> {     
                            if(PathData.super_parent==null)   
                                 {                                                    
                                return   ( 
                                    <> 
                                   {PathData.parent==null ? 
                        <div className="block disFlex jcsb widthFull flexWrap posRelative centered" key={PathData.id}>
                            <div className='uderNameDiv noPadd blk vertAlMiddle' key={PathData.id}>
                                    <input type='checkbox' onChange={e=>haddleRoleID(e, PathData.id)} name='pathRoleCheckBox[]' className='disInline vertAlMiddle checkRole' id={PathData.id}></input> 
                                         {PathData.path_name}
                            </div>
                            <div className='RoleSelector noPadd blk'>
                                {PathPost.map((parentData)=> {
                                    if(PathData.id==parentData.parent){  
                                       return   ( 
                                <span className='disInline vertAlMiddle' key={parentData.id}> 
                                    <input type='checkbox' value={parentData.id} name='pathRoleCheckBox[]' className='disInline vertAlMiddle checkRole' id={parentData.id} onChange={(e)=>haddleRoleID(e, parentData.id)}></input>
                                    {parentData.path_name}
                                </span>
                                       )
                                    }
                                })}
                            </div>
                            {PathPost.map((superParentData)=> {
                                if(PathData.id==superParentData.super_parent ){
                                       return   ( 
                                        <>
                                     {superParentData.parent==null ? 
                            <div className='subBlock noPadd widthFull jcsb disFlex flexWrap' key={superParentData.id}>
                                <div className='left blkSub'> 
                                    <input type='checkbox' name='pathRoleCheckBox[]' className='disInline vertAlMiddle checkRole' id={superParentData.id}  value={superParentData.id} onChange={(e)=>haddleRoleID(e, superParentData.id)} ></input> {superParentData.path_name }
                                </div>
                                <div className='right blkSub vertAlMiddle' key={superParentData.id}>
                                {PathPost.map((superData)=> {
                                    if(PathData.id==superData.super_parent){ 
                                     return   ( 
                                        <>
                                        {superData.parent && superParentData.id==superData.parent ? 
                                    <span className='disInline vertAlMiddle' key={superData.id}> 
                                        <input type='checkbox' name='pathRoleCheckBox[]'  className='disInline vertAlMiddle checkRole' id={superData.id} value={superData.id} key={superData.id} onChange={(e)=>haddleRoleID(e, superData.id)}></input>
                                        {superData.path_name}
                                    </span> : ''}
                                        </>
                                       )
                                     }
                                    })}
                                </div>
                                
                            </div>
                            : ''}  
                            </>
                              )
                            }
                          })}
                        </div> : ''}
                        </> 
                            )
                          } 
                        })}                          
                        </div>
                </div>
                <input type='submit' className='widthFull centered disBlock curpoi submitForm' value="Update" onClick={handleUpdate}></input>
            </div> 
         </>
    )
}