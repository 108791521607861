
import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
export default function EditPage(){
    const location = useLocation();
    const params = new URLSearchParams(location.search)
    const CurntPage = location.pathname.slice(1);
    const curntID = params.get("id");
    const[posts, setPost]= useState([]);
    const[countryList, SetCountryList]= useState([]);
    const[regionList, SetRegionList]= useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [countryView, setCountryView]= useState('');
    const [shortCodeView, setShortCodeView]=useState();
    const[countryCode, SetCountryCode]= useState('');
    const [teleView, setTeleView]= useState('');
    const [Status, SetStatus]= useState();
    const [RegionView, setRegionView]= useState();
    const [regionCode, SetRegionCode]= useState();
    const [locationName , setLocation]= useState();
    const [editData, setEditData]= useState([]);
    const navigate = useNavigate();
    const SuccMsgGet = sessionStorage.getItem('SuccMsg');
    const tokenString = sessionStorage.getItem('token');
   // const [BlankFormMsg, setBlankFormMsg] = useState('');
    //const [isChecked, setIsChecked] = useState(false)
    const handleUpdate =(e) =>{
if(CurntPage=="EditCountry"){
        axios.post('country_update', {
            token: tokenString,
            country_id: curntID,
            country_name: countryView,
            country_sort_code: shortCodeView,
            country_telephone_code: teleView,
            country_status: Status,
        }, {
                headers: {
                   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
        })
        .then(response => {
            setErrorMessage(response.message); 
            if(response.status==200 || response.status==201){
                if(response.data.status==1){
                    sessionStorage.setItem('SuccMsg', (response.data.message))
                    navigate('/Country');
                }
                if(response.data.status==2){
                    setErrorMessage(response.data.message);
                }
            }
        })//
}//Country Update

if(CurntPage=="EditRegion"){
    axios.post('region_update', {
        token: tokenString,
        region_id: curntID,
        country_id: countryCode,
        region_name: RegionView,
        region_sort_code: shortCodeView,
        region_status: Status,
    }, {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
    })
    .then(response => {
        setErrorMessage(response.message); 
        if(response.status==200 || response.status==201){
            if(response.data.status==1){
                sessionStorage.setItem('SuccMsg', (response.data.message))
                navigate('/Region');
            }
            if(response.data.status==2){
                setErrorMessage(response.data.message);
            }
        }
    })//
}//Country Update
       
    }//handleUpdate
    useEffect(() => {
        const tokenString = sessionStorage.getItem('token');
         //country
        if(CurntPage=='EditCountry'){  
            axios.post('country_view', {
                token: tokenString,
                country_id : curntID,
            }, { headers: {
                   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => { 
                if(response.status==200){
                    if(response.data.status==1){
                        setEditData(response.data.data);  
                        setCountryView(response.data.data[0].country_name);  
                        setShortCodeView(response.data.data[0].country_sort_code); 
                        setTeleView(response.data.data[0].country_telephone_code); 
                        SetStatus(response.data.data[0].country_status);    
                    }else{ navigate('/'); } }  }) 
        }//End of edit country

        if(CurntPage=='EditRegion'){  
            axios.post('region_view', {
                token: tokenString,
                region_id : curntID,
            }, { headers: {
                   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => { 
                if(response.status==200){
                    if(response.data.status==1){
                        setEditData(response.data.data);  
                        setCountryView(response.data.data[0].country_name);
                        SetCountryCode(response.data.data[0].region_country);  
                        setShortCodeView(response.data.data[0].region_sort_code); 
                        setRegionView(response.data.data[0].region_name); 
                        SetStatus(response.data.data[0].region_status);  

                    }else{ navigate('/'); } }  }) 

        }//End of edit Region

        if(CurntPage=='EditLocation'){  
            axios.post('location_view', {
                token: tokenString,
                location_id : curntID,
            }, { headers: {
                   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => { 
                if(response.status==200){
                    if(response.data.status==1){
                        setEditData(response.data.data);  
                        SetCountryCode(response.data.data[0].location_country);   
                        setShortCodeView(response.data.data[0].location_sort_code);
                        SetStatus(response.data.data[0].location_status);    
                    }else{ navigate('/'); } }  }) 

                    axios.post('region_list', {
                        token: tokenString
                    }, { headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                    .then(response => { 
                        if(response.status==200){
                            if(response.data.status==1){
                                SetRegionList(response.data.data);
                                
                            }else{ navigate('/'); } }  }) 


        }//End of edit Location

        if(CurntPage=='EditLocation' || CurntPage=='EditRegion'){  
            axios.post('country_list', {
                token: tokenString
            }, { headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => { 
                if(response.status==200){
                    if(response.data.status==1){
                        SetCountryList(response.data.data);
                        
                    }else{ navigate('/'); } }  }) 
        }//List
        

     }, []);
    // Country
    if (sessionStorage.getItem("token") === null) {
        const tokenString = sessionStorage.getItem('token');
          if(!tokenString){
              navigate('/');
           }
        }else{  
    if(CurntPage=='EditCountry'){
      return(
        <>
        <p> {errorMessage}</p>
        {editData.map((data) => { return   (   
        <div className="addLocationBlock disBlock centered vertAltop" key={data.id}>
            <h4 className='textCenter textUppercase'> Edit {data.country_name} </h4> 
            <div className="block centered posRelative">
                <label className="widthFull disInline"> Country </label>
                <input type="text" value={countryView} onChange={(e)=>setCountryView(e.target.value)}   className="widthFull textForm" />
            </div>
            <div className="block centered posRelative">
                <label className="widthFull disInline"> Short Code </label>
                <input type="text" value={shortCodeView} onChange={(e)=>setShortCodeView(e.target.value)}  className="widthFull textForm"/>
            </div>
            <div className="block centered posRelative">
                <label className="widthFull disInline"> Telephone Code </label>
                <input type="text" className="widthFull textForm" value={teleView} onChange={(e)=>setTeleView(e.target.value)}/>
            </div>
            <div className="block centered  posRelative">
                <label className="widthFull disInline"> Status {Status} </label>
                <input type="checkbox" checked={Status} onChange={(e)=>SetStatus(e.target.checked)} className="disInline checkBt textForm" />
                Active
            </div>
            <input onClick={handleUpdate} type="submit" value="Update" className="centered disBlock curpoi submitForm"/> <br></br>
            <div className='widthFull textCenter'>
            <span onClick={(e) =>{navigate('/Country');}} className='disInline cancelEditBut curpoi textCenter'> 
                <span className="material-symbols-outlined disInline vertAlMiddle">close</span>
                Cancel Edit </span>
            </div>
        </div>
           ) })}
        </>
      )
    }//end of edit Country

    if(CurntPage=='EditRegion'){
        return(
          <>
          <p> {errorMessage}</p>
          {editData.map((data) => { return   (   
          <div className="addLocationBlock disBlock centered vertAltop" key={data.id}>
              <h4 className='textCenter textUppercase'> Edit {data.region_name} </h4> 
              <div className="block centered posRelative">
                  <label className="widthFull disInline"> Country </label>
                  <select value={countryCode} onChange={(e) =>{SetCountryCode(e.target.value);}}>
                    {countryList.map((CountryList) =>{ 
                        return(
                        <option key={CountryList.id} value={CountryList.id}> {CountryList.country_name} </option>
                        )
                    })}
                    </select>
              </div>
              <div className="block centered posRelative">
                  <label className="widthFull disInline"> Region </label>
                  <input type="text" value={RegionView} onChange={(e)=>setRegionView(e.target.value)}  className="widthFull textForm"/>
              </div>
              <div className="block centered posRelative">
                  <label className="widthFull disInline"> Short Code </label>
                  <input type="text" value={shortCodeView} onChange={(e)=>setShortCodeView(e.target.value)}  className="widthFull textForm"/>
              </div>
              <div className="block centered  posRelative">
                  <label className="widthFull disInline"> Status {Status} </label>
                  <input type="checkbox" checked={Status} onChange={(e)=>SetStatus(e.target.checked)} className="disInline checkBt textForm" />
                  Active
              </div>
              <input onClick={handleUpdate} type="submit" value="Update" className="centered disBlock curpoi submitForm"/> <br></br>
              <div className='widthFull textCenter'>
              <span onClick={(e) =>{navigate('/Region');}} className='disInline cancelEditBut curpoi textCenter'> 
                  <span className="material-symbols-outlined disInline vertAlMiddle">close</span>
                  Cancel Edit </span>
              </div>
          </div>
             ) })}
          </>
        )
      }//end of edit Region

      if(CurntPage=='EditLocation'){
        return(
          <>
          <p> {errorMessage}</p> 
          {editData.map((data) => { return   ( 
          <div className="addLocationBlock disBlock centered vertAltop" key={data.id}>
              <h4 className='textCenter textUppercase'> Edit {data.location_name}  </h4> 
              <div className="block centered posRelative">
                  <label className="widthFull disInline"> Country </label>
                  <select value={countryCode} onChange={(e) =>{SetCountryCode(e.target.value);}}>
                    {countryList.map((CountryList) =>{ 
                        return(
                        <option key={CountryList.id} value={CountryList.id}> {CountryList.country_name} </option>
                        )
                    })}
                    </select>
              </div>
              <div className="block centered posRelative">
                  <label className="widthFull disInline"> Region </label>
                  <select value={countryCode} onChange={(e) =>{SetCountryCode(e.target.value);}}>
                    {regionList.map((regionList) =>{ 
                        return(
                        <option key={regionList.id} value={regionList.id}> {regionList.region_name} </option>
                        )
                    })}
                    </select>
              </div>
              <div className="block centered posRelative">
                  <label className="widthFull disInline"> Short Code </label>
                  <input type="text" value={shortCodeView} onChange={(e)=>setShortCodeView(e.target.value)}  className="widthFull textForm"/>
              </div>
              <div className="block centered  posRelative">
                  <label className="widthFull disInline"> Status {Status} </label>
                  <input type="checkbox" checked={Status} onChange={(e)=>SetStatus(e.target.checked)} className="disInline checkBt textForm" />
                  Active
              </div>
              <input onClick={handleUpdate} type="submit" value="Update" className="centered disBlock curpoi submitForm"/> <br></br>
              <div className='widthFull textCenter'>
              <span onClick={(e) =>{navigate('/locations');}} className='disInline cancelEditBut curpoi textCenter'> 
                  <span className="material-symbols-outlined disInline vertAlMiddle">close</span>
                  Cancel Edit </span>
              </div>
          </div>
             ) })}
          </>
        )
      }//end of edit Location

} }