import axios from '../api/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
export default function AddClientUser(){
    const tokenString = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const curntID = params.get("id");
     const serchText = params.get("search");
    const [BlankFormMsg, setBlankFormMsg] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);
    const [passwordShowIcon, setPasswordShowIcon] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [genderID, setGenderId] = useState('');
    const [phone, setPhone] = useState('');
    const [altPhone, setAltPhone] = useState('');
    const [emailId, setEmailId] = useState('');   
    const [password, setPassword] = useState('');  
    const [ClientId, setClientId] = useState(''); 
    const [userImage, setUserImage] = useState(''); 
   
    
useEffect(() => {
    setClientId(curntID)
}, []);

 // category Add
 const handleAdd =(e) =>{ 
    const formData = new FormData();
    formData.append("token", tokenString);
    formData.append("client_id", ClientId);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("phone", phone);
    formData.append("gender", genderID);
    formData.append("alternative_phone", altPhone);
    formData.append("photos_image", userImage);
    formData.append("email", emailId);
    formData.append("password", password)
 axios.post('client_user_add', formData)
.then(response => {
    setErrorMessage(response.message); 
    if(response.status==200 || response.status==201){
        if(response.data.status==1){
            sessionStorage.setItem('SuccMsg', (response.data.message))
            navigate('/ClientUser?id='+ClientId); }
        if(response.data.status==2){
            setErrorMessage(response.data.message); }
        }
      })
     .catch(error => { setErrorMessage(error.response.message);}); 
    };
    const togglePasswordShow = () => {
        setPasswordShow(!passwordShow);
        setPasswordShowIcon(!passwordShowIcon)
      };
    return(
        <>
         <div className="addLocationBlock text centered disBlock">
            <div className="block centered posRelative">
                    <label className="widthFull disInline"> User Photo </label>
                    <input type="file" onChange={(e)=>{setUserImage(e.target.files[0]);}} className="widthFull textForm" placeholder="User Image" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> First Name </label>
                    <input type="text" onChange={(e)=>{setFirstName(e.target.value);}} className="widthFull textForm" placeholder="Enter Name" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Last Name </label>
                    <input type="text" onChange={(e)=>{setLastName(e.target.value);}} className="widthFull textForm" placeholder="Enter Name" />
                </div>
                <div className="block posRelative centered">
                    <label className="widthFull disInline"> Gender </label>
                    <select onChange={(e)=>{setGenderId(e.target.value);}} key={100000000}>
                        <option> Select a Gender  </option>
                        <option value='0'> Male  </option>
                        <option value='1'> Female  </option>
                        <option value='2'> Others  </option>
                    </select>
                    <span className="material-symbols-outlined arrow posAbsolute"> expand_more </span>
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Phone </label>
                    <input type="text" onChange={(e)=>{setPhone(e.target.value);}} className="widthFull textForm" placeholder="Enter Number" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Alternative Number </label>
                    <input type="text" onChange={(e)=>{setAltPhone(e.target.value);}} className="widthFull textForm" placeholder="Enter Number" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Email ID(UserName) </label>
                    <input type="email" onChange={(e)=>{setEmailId(e.target.value);}} className="widthFull textForm" placeholder="Enter ID" />
                </div>
                <div className="block centered posRelative">
                    <label className="widthFull disInline"> Password </label>
                    <input type={passwordShow ? "text" : 'password'} onChange={(e)=>{setPassword(e.target.value);}} className="widthFull textForm" placeholder="Enter Password" />
                    <span className="material-symbols-outlined posAbsolute showPasswordBut" onClick={togglePasswordShow}>{passwordShowIcon ? 'visibility' : 'visibility_off'}</span>
                </div>
                <input type="hidden" value={ClientId} className="widthFull textForm"/>
                <input onClick={handleAdd} type="submit" value="Add User" className="centered disBlock curpoi submitForm"/>
            </div>
        </>
    )
}